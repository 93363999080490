import { hot } from 'react-hot-loader';

import React from "react";

import { initSelectNone } from '../functions/inits';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../interfaces/redux';
import { Entity_PhotoPersonne } from '../interfaces/entities';
import { randColor } from '../functions/tools';
import { selectOne } from '../functions/selectors';
import Link from '../Link';
import { urls } from '../const';

function MySpacePhotos(__datas:MySpacePhotos = {}) {

    // TODO - Au clic sur une photo, elle s'aggrandit
    // TODO - Pendant que la photo s'aggrandit une image en plus haute résolution est chargée
    // TODO - Lorsque l'image en haute résolution est chargée elle remplace la miniature aggrandie
    // TODO - L'image en haute résolution est gardée en mémoire (voir le cache)

    const loginActivity = useSelector((state:ReduxStore) => state.page.loginActivity);
    const { t, i18n } = useTranslation();

    const resolveScrollSpeed = function(last_move:number, time:number) {
        return last_move + (20 - time/last_move * 100);
    };

    const display_photo = function(_e:any, _index:number) {
        _e.preventDefault();
        const _photo = selectOne(`#myspace-photos-carousel .photo:nth-child(${_index})`);
        if(!_photo) {return false;}

    };

    React.useEffect(() => {
        initSelectNone();
    }, [loginActivity, __datas.photos]);

    React.useEffect(() => {
        selectOne('#myspace-photos-carousel')?.addEventListener('wheel', function(_e?: any) {
            if(_e && resolveScrollSpeed(parseInt(_e.currentTarget.getAttribute('data-last_move') ?? 0, 10), _e.timeStamp) < _e.timeStamp) {
                _e.preventDefault();
                _e.currentTarget.setAttribute('data-last_move', _e.timeStamp);
                _e.currentTarget.scrollLeft += _e.deltaY/2;
            }
        }, {passive: false});
    }, [i18n.language]);

    return (
        <div id="myspace-photos" className="w-full relative px-12">
            <div
                id="myspace-photos-carousel"
                className="no-scrollbar w-full whitespace-nowrap py-6 box-content"
                data-last_move={0}
            >
                {/* {(__datas?.photos ?? []).map(photo => {
                    
                })} */}
                {[
                    randColor(), randColor(), randColor(), randColor(), randColor(),
                    randColor(), randColor(), randColor(), randColor(), randColor()
                ].map((photo, key_photo, photos) => 
                    <div
                        key={key_photo}
                        className={[
                            "h-36", "cursor-pointer", "inline-block", "align-middle", "relative",
                            "drop-shadow-md", "transition-transform", "z-0", "hover:scale-110", "hover:z-10",
                            "photo",
                            key_photo > 0 ? "ml-4" : "",
                        ].join(' ')}
                        onClick={(_e:any) => display_photo(_e, key_photo + 1)}
                        style={{
                            backgroundColor: photo,
                            width: (Math.ceil(Math.random() * 100) - 50) + 200
                        }}
                    >
                        <Link {...{
                            ...urls.image_view,
                            empty: true,
                            title: "forms->image_view->open_view",
                            classes: ["text-pink-800", "hover:text-pink-900", "hover:font-bold", "absolute-link"]}}
                            modaleSpecificDatas={{set: photos, index: key_photo + 1}}
                        ></Link>
                    </div>
                )}
            </div>
        </div>
    );
};

interface MySpacePhotos {
    key?: any;
    photos?: Entity_PhotoPersonne[];
};

export default hot(module)(MySpacePhotos);
