import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';

import { initSelectNone } from '../components/functions/inits';

const _404Page = (__datas:_404Page = {}) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <main data-page="404" className="custom-scrollbar">
            <h2>{t('pages->404->not_exist_or_removed')}</h2>
        </main>
    );
};

interface _404Page {
    key?: any;
};

export default hot(module)(_404Page);