import { SelectOption } from "../interfaces/elements";

export const __signup_etapes = [
    "generals",
    "administrative",
    "physical"
];

export const __signup_elements = [
    ["prenom", "nom_famille", "birthdate", "email", "password", "confirm_password", "as_figurant", "as_silhouette", "as_comedien_amateur", "as_comedien_professionnel", "as_mannequin_amateur", "as_mannequin_professionnel", "as_vehicule", "as_animal", "terms"],
    ["address_street_number", "address_street_name", "address_building_number", "address_building_name", "address_zip_code", "address_city", "address_country", "nationality", "mobile", "has_vehicle", "has_license"],
    ["height", "weight", "type_physic", "hair_color", "eyes_color"]
];

export const __gender_options:SelectOption[] = [
    {
        "label": "gender->male->label",
        "value": 0
    },
    {
        "label": "gender->female->label",
        "value": 1
    }
]

export const formsBreakpoints = {
    "signup": {
        "sm": "sm:my-0 sm:max-w-lg sm:w-full sm:p-2",
        "md": "md:my-2 md:max-w-2xl md:w-full md:p-4",
        "lg": "lg:my-4 lg:max-w-4xl lg:w-full rounded-lg lg:p-6",
        "xl": "xl:my-6 xl:max-w-5xl xl:w-full shadow-xl xl:px-8 xl:py-6",
        "2xl": "2xl:my-10 2xl:max-w-6xl 2xl:w-full 2xl:p-10",
    },
    "signin": {
        "sm": "sm:my-6 sm:max-w-md sm:w-full sm:p-4 max-h-1/3 translate-y-2/6",
        "md": "md:my-13 md:max-w-lg md:w-full md:px-8 py-6",
        "lg": "lg:my-20",
    },
    "forgot_pwd": {
        "sm": "sm:my-6 sm:max-w-md sm:w-full sm:p-4 max-h-2/3 translate-y-1/6",
        "md": "md:my-13 md:max-w-lg md:w-full md:px-8 py-6",
        "lg": "lg:my-20",
    },
    "image_view": {
        "sm": "sm:my-6 sm:max-w-md sm:w-full sm:p-4 max-h-2/3 translate-y-1/6",
        "md": "md:my-13 md:max-w-lg md:w-full md:px-8 py-6",
        "lg": "lg:my-20",
    }
};

export const countriesOptions = {
    "belgique": {
        "value": "BE",
        "label": "country_BE",
        "icon": {
            "src": new URL("../../assets/icons/countries/1x1/be.svg", import.meta.url),
            "alt": "countries->belgique->logo->alt",
            "title": "countries->belgique->logl->title"
        },
        "authorized": true,
    },
    "france": {
        "value": "FR",
        "label": "country_FR",
        "icon": {
            "src": new URL("../../assets/icons/countries/1x1/fr.svg", import.meta.url),
            "alt": "countries->france->logo->alt",
            "title": "countries->france->logl->title"
        },
        "authorized": true,
    },
    "paysbas": {
        "value": "NL",
        "label": "country_NL",
        "icon": {
            "src": new URL("../../assets/icons/countries/1x1/nl.svg", import.meta.url),
            "alt": "countries->paysbas->logo->alt",
            "title": "countries->paysbas->logl->title"
        },
        "authorized": false,
    },
    "angleterre": {
        "value": "UK",
        "label": "country_UK",
        "icon": {
            "src": new URL("../../assets/icons/countries/1x1/gb.svg", import.meta.url),
            "alt": "countries->angleterre->logo->alt",
            "title": "countries->angleterre->logl->title"
        },
        "authorized": false,
    },
    "luxembourg": {
        "value": "LU",
        "label": "country_LU",
        "icon": {
            "src": new URL("../../assets/icons/countries/1x1/lu.svg", import.meta.url),
            "alt": "countries->luxembourg->logo->alt",
            "title": "countries->luxembourg->logl->title"
        },
        "authorized": true,
    },
    "suisse": {
        "value": "CH",
        "label": "country_CH",
        "icon": {
            "src": new URL("../../assets/icons/countries/1x1/ch.svg", import.meta.url),
            "alt": "countries->suisse->logo->alt",
            "title": "countries->suisse->logl->title"
        },
        "authorized": true,
    },
};
