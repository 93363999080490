import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';

import { initSelectNone } from '../components/functions/inits';

const C7PlusPage = (__datas:C7PlusPage = {}) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <main data-page="c7plus" className="custom-scrollbar">
            
        </main>
    );
};

interface C7PlusPage {
    key?: any;
};

export default hot(module)(C7PlusPage);