/**
 * @file All needed functions to get any DOM element or set with null and [] fallback
*/

import { isElement } from "./is-type";

// HTML GETTER

// SELECTORS FROM DOCUMENT

export const getById = function(id) {
    return typeof document == "undefined" ? null : document.getElementById(id);
};

export const getAllByTag = function(tag, asArray = false) {
    return typeof document == "undefined" ? [] : asArray == false ? document.getElementsByTagName(tag) : Array.from(document.getElementsByTagName(tag));
};
export const getOneByTag = function(tag, index = null) {
    return typeof document == "undefined" ? null : document.getElementsByTagName(tag)[index == null ? 0 : index];
};

export const getAllByClass = function(classname, asArray = false) {
    return typeof document == "undefined" ? [] : asArray == false ? document.getElementsByClassName(classname) : Array.from(document.getElementsByClassName(classname));
};
export const getOneByClass = function(classname, index = null) {
    return typeof document == "undefined" ? null : document.getElementsByClassName(classname)[index == null ? 0 : index];
};

export const getAllByName = function(name, asArray = false) {
    return typeof document == "undefined" ? [] : asArray == false ? document.getElementsByName(name) : Array.from(document.getElementsByName(name));
};
export const getOneByName = function(name, index = null) {
    return typeof document == "undefined" ? null : document.getElementsByName(name)[index == null ? 0 : index];
};

export const selectOne = function(selector, index = null) {
    return typeof document == "undefined" ? null : index == null ? document.querySelector(selector) : document.querySelectorAll(selector)[index == null ? 0 : index];
};
export const selectAll = function(selector, asArray = false) {
    return typeof document == "undefined" ? [] : asArray == false ? document.querySelectorAll(selector) : Array.from(document.querySelectorAll(selector));
};

// SELECTORS FROM HTML ELEMENT

// UP Créer fonction qui génère le DOM path d'un élément
// Utiliser la structure de indexElement en remontant
// tagName + (id ?? classlist.join('.') + (:nth(x) ?? '')) + >
// ajouter le paramètre id à la fin
// export const getByIdIn = function(id, item) {
//     return typeof document == "undefined" || isElement(item) == false ? null : item.getElementById(id);
// };

export const getAllByTagIn = function(tag, item, asArray = false) {
    return typeof document == "undefined" || isElement(item) == false ? [] : asArray == false ? item.getElementsByTagName(tag) : Array.from(item.getElementsByTagName(tag));
};
export const getOneByTagIn = function(tag, item, index = null) {
    return typeof document == "undefined" || isElement(item) == false ? null : item.getElementsByTagName(tag)[index == null ? 0 : index];
};

export const getAllByClassIn = function(classname, item, asArray = false) {
    return typeof document == "undefined" || isElement(item) == false ? [] : asArray == false ? item.getElementsByClassName(classname) : Array.from(item.getElementsByClassName(classname));
};
export const getOneByClassIn = function(classname, item, index = null) {
    return typeof document == "undefined" || isElement(item) == false ? null : item.getElementsByClassName(classname)[index == null ? 0 : index];
};

export const getAllByNameIn = function(name, item, asArray = false) {
    return typeof document == "undefined" || isElement(item) == false ? [] : asArray == false ? item.getElementsByTagName(name) : Array.from(item.getElementsByTagName(name));
};
export const getOneByNameIn = function(name, item, index = null) {
    return typeof document == "undefined" || isElement(item) == false ? null : item.getElementsByTagName(name)[index == null ? 0 : index];
};

export const selectOneIn = function(selector, item, index = null) {
    return typeof document == "undefined" || isElement(item) == false ? null : index == null ? item.querySelector(selector) : item.querySelectorAll(selector)[index == null ? 0 : index];
};
export const selectAllIn = function(selector, item, asArray = false) {
    return typeof document == "undefined" || isElement(item) == false ? [] : asArray == false ? item.querySelectorAll(selector) : Array.from(item.querySelectorAll(selector));
};

