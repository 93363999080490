import React from 'react';
import { __backURLs } from '../functions/const';

import "./annonces-projects.css";
import { hot } from 'react-hot-loader';
import LoadingGIF from '../Basics/LoadingGIF';
import { Entity_Projet } from '../interfaces/entities';
import { useTranslation } from 'react-i18next';

const AnnoncesProjects = ({key, loading, projets}:AnnoncesProjects) => {

    const { t, i18n } = useTranslation();
    
    return (
        <div id="annonces-projects" className="custom-scrollbar">
            {loading && <div className="loading-mask"><LoadingGIF/></div>}
            {!loading && (projets ?? []).length == 0 && <div className="annonces-projects-elem">{t('pages->annonces->projects-zone->no-projects')}</div>}
            {!loading && (projets ?? []).length > 0 && (projets ?? []).map(projet => (
                <div className="annonces-projects-elem">
                    <h3>{projet.title}</h3>
                    {projet.poster && <img className="annonces-projects-elem-poster background-image" src={`${process.env.PROJET_AFFICHE_URL}${projet.poster}`}/>}
                    {!projet.poster && <img src={new URL('../../assets/icons/no-img.svg', import.meta.url)?.href}/>}
                </div>
            ))}
        </div>
    );
};

interface AnnoncesProjects {
    key?: any;
    loading?: boolean;
    projets: Entity_Projet[];
};

export default hot(module)(AnnoncesProjects);