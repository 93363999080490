import { hot } from 'react-hot-loader';

import React from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { initSelectNone } from '../components/functions/inits';
import { __backURLs } from '../components/functions/const';

import AnnoncesFilter from '../components/Annonces/annonces-filter';
import AnnoncesList from '../components/Annonces/annonces-list';
import AnnoncesProjects from '../components/Annonces/annonces-projects';
import { ReduxStore } from '../components/interfaces/redux';
import { Entity_Annonce, Entity_Projet } from '../components/interfaces/entities';

const AnnoncesPage = (__datas:AnnoncesPage = {}) => {

    const { t, i18n } = useTranslation();
    
    const filters = useSelector((state:ReduxStore) => state.page.filters?.annonces);

    const [annonces, setAnnonces] = React.useState([]);
    const [loading_params, setLoadingParams] = React.useState(true);
    const [loading_annonces, setLoadingAnnonces] = React.useState(true);
    const [params, setParams] = React.useState({} as any);

    React.useEffect(() => {
        initSelectNone();

        fetch(
            process.env.BACK_URL + __backURLs.api_get_page_settings.url.replace('{page}', 'annonces')
        )
        .then(res => res.json())
        .then((result) => {
            setParams(result.datas);
            setLoadingParams(false);
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    React.useEffect(() => {
        setLoadingAnnonces(true);
        fetch(
            process.env.BACK_URL + __backURLs.api_get_annonces.url,
            {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    lang: i18n.language,
                    filters: filters ? {
                        ...filters,
                        byMale: filters.bySexe ? filters.byMale ? filters.byMale : false : false,
                        byFemale: filters.bySexe ? filters.byFemale ? filters.byFemale : false : false,
                    } : null
                })
            }
        )
        .then(res => res.json())
        .then((result) => {
            setAnnonces(result.datas);
            setLoadingAnnonces(false);
        })
        .catch((error) => {
            console.log(error);
        });
    }, [filters]);

    React.useEffect(() => {

    }, [i18n.language, loading_annonces, loading_params]);

    return (
        <main data-page="annoncement" className="custom-scrollbar">
            <AnnoncesFilter params={params.filter ?? {}} loading={loading_params == true && loading_annonces}/>
            <AnnoncesList annonces={annonces} loading={loading_annonces}/>
            <AnnoncesProjects projets={annonces.map((ann:Entity_Annonce) => ann.project).filter((p:Entity_Projet, p_index, arr) => arr.findIndex(el => el.db_id == p.db_id) == p_index)} loading={loading_annonces}/>
        </main>
    );
};

// IMPORTANT - Appeller propriétaire
// IMPORTANT - Appeller Generali

interface AnnoncesPage {
    key?: any;
};

export default hot(module)(AnnoncesPage);