import React from "react";
import { hot } from "react-hot-loader";
import { Entity_Annonce } from "../interfaces/entities";
import { resolve_annonce_detail } from "../functions/annonce";
import { useTranslation } from "react-i18next";

import "./annonce-general.css";

const AnnonceGeneral = ({ annonce }:AnnonceGeneral) => {

    if(!annonce) {
        return <></>;
    }

    const { t, i18n } = useTranslation();

    return (
        <div id="annonce-infos-general">
            <img id="annonce-infos-poster" className="background-image" src={annonce.poster ? `${process.env.PROJET_AFFICHE_URL}${annonce.poster}` : new URL('../../assets/icons/no-img.svg', import.meta.url)?.href}/>
            <div id="annonce-infos-infos">
                <h3 className="ellipsis">{t('pages->annonce->infos->details->title')}</h3>
                <div id="annonce-infos-infos-details">
                    {
                        [
                            {type: "project", img: new URL('../../assets/icons/annonce/project.svg', import.meta.url)?.href},
                            {type: "type", img: new URL('../../assets/icons/annonce/type.svg', import.meta.url)?.href},
                            {type: "money", img: new URL('../../assets/icons/annonce/money.svg', import.meta.url)?.href},
                            {type: "gender", img: new URL('../../assets/icons/annonce/gender.svg', import.meta.url)?.href},
                            {type: "age", img: new URL('../../assets/icons/annonce/age.svg', import.meta.url)?.href},
                            {type: "statut", img: new URL('../../assets/icons/annonce/statut.svg', import.meta.url)?.href},
                        ]
                        .map((detail, detail_key) => (
                            <div className={`annonce-elem-details-descr-short-elem annonce-elem-short-${detail.type}`} key={detail_key}>
                                <img src={detail.img} title={t(`pages->annonces->infos->details->icon->${detail}`)}/>
                                <span className="ellipsis">{resolve_annonce_detail(detail.type, annonce, "annonce")}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div id="annonce-infos-practicals">
                <h3 className="ellipsis">{t('pages->annonce->infos->practicals->title')}</h3>
                    <div id="annonce-infos-practicals-details">
                        <h3 className="ellipsis">{t('pages->annonce->infos->practicals->time->title')}</h3>
                        <div className="annonce-infos-practicals-details-detail ellipsis">
                            {annonce.dates.map(date => <div className="annonce-infos-practicals-details-detail date">- {date}</div>)}
                        </div>
                        <h3 className="ellipsis">{t('pages->annonce->infos->practicals->location->title')}</h3>
                        <div className="annonce-infos-practicals-details-detail ellipsis">
                            {
                                (annonce.lieux ?? []).length == 0 ?
                                <div className="annonce-infos-practicals-details-detail no-lieu">{t('pages->annonce->infos->practicals->location->no-lieu')}</div> :
                                annonce.lieux.map(lieu => <div className="annonce-infos-practicals-details-detail lieu">{lieu}</div>)
                            }
                        </div>
                        {/* <h3 className="ellipsis">{t('pages->annonce->infos->practicals->liaison-title')}</h3> */}
                        {/* <div className="annonce-infos-practicals-details-detail ellipsis">
                            
                        </div> */}
                    </div>
            </div>
            <div id="annonce-infos-project">
                <h3 className="ellipsis">{annonce.project.title}</h3>
                <img id="annonce-infos-project_poster" className="background-image" src={annonce.project.poster ? `${process.env.PROJET_AFFICHE_URL}${annonce.project.poster}` : new URL('../../assets/icons/no-img.svg', import.meta.url)?.href}/>
            </div>
        </div>
    );
};

interface AnnonceGeneral {
    annonce?: Entity_Annonce;
};

export default hot(module)(AnnonceGeneral);