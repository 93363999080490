/**
 * @file Manage all the pages generic initializations unless for forms
*/

import { _error, _log, _trace } from "./const";
import { creerCouple } from "./dynamic-id";
import { isArray, isElement, isFunction } from "./is-type";
import { getById, selectAll, selectOne, selectOneIn } from "./selectors";
import { getFullDate } from "./tools";
import { HTMLFileInputElement } from '../interfaces/elements';

export const initUsedIDs = () => {
    selectAll('*[id]', true).forEach(elem => creerCouple(elem.id, "_"));
};

// UP IMPORTANT
export const initInputPasswords = () => {
    _log("initInputPasswords");
    // TODO Bloquer le changement de type, sauf interne
    // selectAll('input[type="password"]', true).forEach(_input => {
    //     _input.addEventListener('change', (e) => {
    //         _log(e);
    //     });
    // });
};

export const initPopins = () => {
    // _log("initPopins");
    // _log(get_url_search(true));
};

export const initInputMails = () => {
    // _log("initInputMails");
    let _input_mails_inits = getById('#input_inits-mails');
    selectAll('input[type="email"]', true)
    .forEach(input => {
        if(!isElement(input)) {return false;}

        if(input.placeholder == '') {
            input.placeholder = _input_mails_inits?.getAttribute('data-def_placeholder') ?? "Mail";
        }
    });
};

export const initInputDates = () => {
    // _log("initInputDates");

    selectAll('input[type="date"]', true)
    .forEach(date => {
        if(!isElement(date)) {return false;}
        // Initier la valeur si non définie
        date.defaultValue = date.value || getFullDate();
    });
};

export const not_selectables = [
    '.nav-item',
    '.dropdown-menu',
    '.dropdown-item',
    '.zone-title',
    '.accordion-title',
    'label',
    '.crumble-fragment',
];

export const initSelectNone = () => {
    not_selectables.forEach(select => {
        selectAll(select+':not(.user-select-none)', true).forEach(elem => elem?.classList.add('user-select-none'))
    });
};

export const initSelect = () => {
    selectAll('select', true).forEach(select => {
        select?.classList.add('custom-scrollbar');
    });
};

export const initAccordions = () => {
    let temp:Element|null = null;
    selectAll('.accordion', true).forEach((accordion) => {
        temp = selectOneIn('.accordion-title', accordion);
        if(temp instanceof Element) {
            temp.addEventListener('click', (e) => {
                accordion?.classList.toggle('opened');
            })
            temp = null;
        }
    });
};

export const initExpands = function(groups = Array<any>) {
    console.log(groups);
    if(!isArray(groups)) {return false;}
    return Array.isArray(groups) ? groups.map(group => {
        try {
            if(isElement(group.elem) && isElement(group.open) && isElement(group.close)) {
                (group.open)?.addEventListener('click', function(e) {
                    (group.open)?.classList.replace('shown', 'hidden');
                    (group.close)?.classList.replace('hidden', 'shown');
                    (group.elem)?.classList.remove('block-closed');
    
                    if(isFunction(group.callback)) {group.callback();}
                    
                    // current_page == "booking_manager" && isFunction(__booking_manager__setToggleClose) && __booking_manager__setToggleClose(true);
                    // current_page == "attribution_manager" && isFunction(__attribution_manager__setToggleClose) && __attribution_manager__setToggleClose(true);
                });
                (group.close)?.addEventListener('click', function(e) {
                    (group.close)?.classList.replace('shown', 'hidden');
                    (group.open)?.classList.replace('hidden', 'shown');
                    (group.elem)?.classList.add('block-closed');
                    
                    if(isFunction(group.callback)) {group.callback();}
    
                    // current_page == "booking_manager" && isFunction(__booking_manager__setToggleClose) && __booking_manager__setToggleClose();
                    // current_page == "attribution_manager" && isFunction(__attribution_manager__setToggleClose) && __attribution_manager__setToggleClose();
                });
            }
            return true;
        }
        catch(err) {
            _trace(err);
            _error(err);
            return false;
        }
    }).filter(result => result == false).length == 0 : true;
}

export const _input_files = {
    m_inputs: {},
    // INPUTS
    addInput(_id) {
        _log("addInput(" + _id + ")");
        let _input = getById(_id);
        if(typeof _id == "string" && _input instanceof HTMLInputElement && this.m_inputs[_id] == undefined) {
            this.m_inputs[_id] = {
                m_files: [], //getById(_id).files,
                m_index: 0,
                m_multiple: _input.multiple == true ? true : false,
                m_initial: _input.files,
            }
        }
    },
    removeId(_id) {
        _log("removeId(" + _id + ")");
        if(typeof _id == "string") {
            this.deleteFiles(_id);
            delete this.m_inputs[_id];
        }
    },
    // FILES
    filterCleanFiles(_id) {
        _log("filterCleanFiles(" + _id + ")");
        if(typeof _id == "string" && getById(_id) && this.m_inputs[_id] && this.m_inputs[_id].m_files.length > 0) {
            this.m_inputs[_id].m_files = isFunction(this.m_inputs[_id].m_files.filter) ? this.m_inputs[_id].m_files.filter(file => file) : this.m_inputs[_id].m_files;
        }
    },
    addFiles(_id) {
        _log("addFiles(" + _id + ")");
        let _input:HTMLFileInputElement|any = getById(_id);
        if(typeof _id == "string" && _input != null && this.m_inputs[_id]) {
            Object.values(_input?.files).forEach((file) => {
                this.m_inputs[_id].m_files[this.m_inputs[_id].m_files.length] = file;
            });
            _input.files = this.m_inputs[_id].m_initial;
            this.filterCleanFiles(_id);
            this.flipView(_id);
        }
    },
    editFiles(_id) {
        _log("editFiles(" + _id + ")");
        let _input:HTMLFileInputElement|any = getById(_id);
        if(typeof _id == "string" && _input != null) {
            // _id == 'projet_affiche' && getById(_id).setAttribute('required', 'required');
            this.m_inputs[_id].m_files = [];
            this.m_inputs[_id].m_files.push(_input?.files[0]);
            // getById(_id).files = this.m_inputs[_id].m_initial;
            this.filterCleanFiles(_id);
            this.flipView(_id);
        }
    },
    removeFile(_id) {
        _log("removeFile(" + _id + ")");
        let _input:HTMLFileInputElement|any = getById(_id);
        if(typeof _id == "string" && _input != null && this.m_inputs[_id].m_files.length > 0) {
            this.m_inputs[_id].m_files.splice(this.m_inputs[_id].m_index, 1);
            _input.files = this.m_inputs[_id].m_initial;
            this.filterCleanFiles(_id);
            this.flipView(_id);
        }
    },
    deleteFiles(_id) {
        _log("deleteFiles(" + _id + ")");
        let _input:HTMLFileInputElement|any = getById(_id);
        if(typeof _id == "string" && _input != null && this.m_inputs[_id]) {
            // _id == 'projet_affiche' && getById(_id).setAttribute('required', 'required');
            this.m_inputs[_id].m_files = [];
            this.m_inputs[_id].m_index = 0;
            _input.files = this.m_inputs[_id].m_initial;
            this.filterCleanFiles(_id);
            this.flipView(_id);
        }
    },
    // MANAGE
    flipView(_id) {
        _log("flipView(" + _id + ")");
        if(typeof _id == "string" && getById(_id) && this.m_inputs[_id] && this.m_inputs[_id].m_files.length > 0) {
            if(selectOneIn('.img-preview', getById(_id + '_label'))) {
                selectOneIn('.img-preview', getById(_id + '_label')).src = URL.createObjectURL(this.m_inputs[_id].m_files[this.m_inputs[_id].m_index]);
                selectOneIn('.img-preview', getById(_id + '_label')).alt = this.m_inputs[_id].m_files[this.m_inputs[_id].m_index].name.split('.')[0];
                selectOneIn('.img-preview', getById(_id + '_label')).title = this.m_inputs[_id].m_files[this.m_inputs[_id].m_index].name.split('.')[0];
                selectOneIn('.image-default', getById(_id + '_label'))?.classList.add('hidden');
                selectOneIn('.image-preview', getById(_id + '_label'))?.classList.remove('hidden');
            }
        }
        else {
            if(selectOneIn('.img-preview', getById(_id + '_label'))) {
                selectOneIn('.image-preview', getById(_id + '_label'))?.classList.add('hidden');
                selectOneIn('.image-default', getById(_id + '_label'))?.classList.remove('hidden');
                selectOneIn('.img-preview', getById(_id + '_label')).src = "";
                selectOneIn('.img-preview', getById(_id + '_label')).alt = "";
                selectOneIn('.img-preview', getById(_id + '_label')).title = "";
            }
        }
    },
    // CAROUSEL
    getInputIndex(_id) {
        _log("getInputIndex(" + _id + ")");
        if(typeof _id == "string" && getById(_id)) {
            return this.m_inputs[_id].m_index;
        }
    },
    carouselRight(_id) {
        _log("carouselRight(" + _id + ")");
        if(typeof _id == "string" && getById(_id) && this.m_inputs[_id].m_multiple == true) {
            this.m_inputs[_id].m_index = this.m_inputs[_id].m_index >= this.m_inputs[_id].m_files.length - 1 ? 0 : this.m_inputs[_id].m_index + 1;
            this.flipView(_id);
        }
    },
    carouselLeft(_id) {
        _log("carouselLeft(" + _id + ")");
        if(typeof _id == "string" && getById(_id) && this.m_inputs[_id].m_multiple == true) {
            this.m_inputs[_id].m_index = this.m_inputs[_id].m_index == 0 ? this.m_inputs[_id].m_files.length - 1 : this.m_inputs[_id].m_index - 1;
            this.flipView(_id);
        }
    },
};

export const initInputImage = () => {
    selectAll('.input-image', true).forEach((image) => {
        let _container = selectOne('#' + image.id + '_label');
        let _preview = selectOneIn('.image-preview', _container);
        let _default = selectOneIn('.image-default', _container);
        let _img = selectOneIn('.img-preview', _preview);
        let _del = selectOneIn('.img-preview-delete', _preview);
        let _del_all = selectOneIn('.img-preview-delete-all', _preview);

        if(_container == null || _preview == null || _default == null || _img == null || _del_all == null || (image.multiple == true && _del == null)) {
            return false;
        }

        _input_files.addInput(image.id);

        _del_all.addEventListener('click', (del_e) => {
            del_e.preventDefault();
            _log("Click _del_all");
            _input_files.deleteFiles(image.id);
        })
        _del && _del.addEventListener('click', (del_e) => {
            _log("Click _del");
            del_e.preventDefault();
            _input_files.removeFile(image.id);
        })
        image.addEventListener('change', (e) => {
            e.preventDefault();
            if(e.target.value == "") {
                if(_img.src.length > 0 && _img.alt.length > 0) {
                    _log("Ne pas changer");
                    return true;
                }
                _log("Je sais pas");
                _img.src = "";
                _img.alt = "";
                _img.title = "";
                _preview?.classList.add('hidden');
                _default?.classList.remove('hidden');
                return true;
            }
            else {
                if(e.target.multiple) {
                    _log("Ajouter une image au carousel");
                    _input_files.addFiles(image.id);
                }
                else {
                    _log("Remplacer l'image par une autre");
                    _input_files.editFiles(image.id);
                }
            }
            return true;
        });
        // image.addEventListener('input', (e) => {
        //     e.preventDefault();
        //     _log(e);
        //     // URL.createObjectURL(getById('projet_affiche').files[0])
        // });
    });
};