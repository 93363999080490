import { setUser } from "../../features/user/userSlice";
import { Entity_User } from '../interfaces/entities';
import { getById, selectOne } from './selectors';
import { _fetch } from "./fetch";
import { _error, _log, _trace, __backURLs } from './const';
import store from '../../app/store';
import { resolveXHRBody } from '../functions';
import { setLoginActivity, setModale } from '../../features/page/pageSlice';
import { delete_cookie } from './tools';
import { resolveModaleError, resolveModaleSuccess } from "../Modale/functions";

// LOGOUT

export function __logout__(e:Event) {
    e.preventDefault();
    if(typeof window != undefined) {
        _log("Remove c7ur from localStorage");
        window?.localStorage.removeItem("c7ur");
    }
    if(typeof document != undefined) {
        _log("Remove c7ur from cookies");
        delete_cookie("c7ur", "/");
    }
    _log("Reset user from Redux");
    store.dispatch(setUser(store.getState().user.reset));
}

// SIGNIN

export default function __signin__(__form:string|null = null, __type:string|null = null) {
    if(__type != "refresh" && (__form == null || !document?.forms[__form])) {return false;}
    if(__form != null && document?.forms[__form].reportValidity() == false) {
        return false;
    }
    
    spinAppear(__form);
    
    selectOne(`#${__form} button[type="submit"] .animate-spin`)?.setAttribute('visibility', 'visible');
    
    if(__form != null && __type == "google") {
        return signinGoogle(__form);
    }
    if(__form != null && __type == "facebook") {
        return signinFacebook(__form);
    }
    if(__form != null && __type == "twitter") {
        return signinTwitter(__form);
    }
    if(__form != null && __type == "instagram") {
        return signinInstagram(__form);
    }
    if(findRefresh() != null && (__type == "refresh" || __type != "standard")) {
        return signinRefresh(__form, findRefresh());
    }
    if(__form != null && (__type == null || __type == "standard" || __type != "refresh")) {
        return signinStandard(__form);
    }

    store.dispatch(setLoginActivity(false));

    return false;
}

function signinGoogle(__form:string|null = null) {
    if(__form == null) {return false;}
    return true;
}

function signinFacebook(__form:string|null = null) {
    if(__form == null) {return false;}
    return true;
}

function signinTwitter(__form:string|null = null) {
    if(__form == null) {return false;}
    return true;
}

function signinInstagram(__form:string|null = null) {
    if(__form == null) {return false;}
    return true;
}

function signinRefresh(__form:string|null = null, __refresh:string|null = null) {
    if(__refresh == null) {return false;}
    const __formData = new FormData();
    __formData.append("refresh", findRefresh() || "");
    return signinWithBack(__form, __formData, "refresh");
}

function signinStandard(__form:string|null = null) {
    if(__form == null) {return false;}
    const __formData = new FormData();
    __formData.append("email", selectOne(`#${__form} [type="email"]`)?.value || "");
    __formData.append("password", selectOne(`#${__form} [type="password"]`)?.value || "");
    return signinWithBack(__form, __formData, "standard");
}

function signinWithBack(__form:string|null = null, __body:FormData|Object|string|null = null, __type:string|null = null) {
    if((__form == null && __type != "refresh") || __body == null) {return false;}
    try {
        _fetch(
            `${process.env.BACK_URL}${__backURLs.api_login.url}`,
            "POST",
            resolveXHRBody(__body),
            (_form:HTMLFormElement|null = null, _res:{status: string, message:Entity_User|string}|null = null) => {
                if(_res == null) {return false;}
                spinDisappeppear(__form);
                if(["success"].indexOf(_res.status) > -1 && typeof _res.message != "string") {
                    updateUser(_res.message, __type || "standard");
                    resolveModaleSuccess(...['activeModale', 'modaleGlobalEnv', 'modaleSpecificEnv'].map(attr => store.getState().page[attr]));
                }
                if(["error"].indexOf(_res.status) > -1 && typeof _res.message == "string") {
                    resolveModaleError(...['activeModale', 'modaleGlobalEnv', 'modaleSpecificEnv'].map(attr => store.getState().page[attr]));
                    let _elem = getById("form-error");
                    if(!(_elem instanceof Element)) {return false;}
                    _elem.classList.add('hidden');
                    _elem = getById("signin-error");
                    if(!(_elem instanceof Element)) {return false;}
                    _elem.classList.remove('hidden');
                    _elem.innerText = _res.message;
                }
            },
            (_form:HTMLFormElement|null = null, _err:Object|null = null) => {
                if(_err == null) {return false;}
                spinDisappeppear(__form);
                resolveModaleError(...['activeModale', 'modaleGlobalEnv', 'modaleSpecificEnv'].map(attr => store.getState().page[attr]));
                _error(_err);
            },
            selectOne(`#${__form}`),
            "json"
        );
    }
    catch(_err) {
        _trace(_err);
        _error(_err);
    }
}

function updateUser(__datas:Entity_User|null = null, __type:string|null = null) {
    if(__datas == null || __type == null) {return false;}
    if(typeof document != "undefined") {
        document.cookie = __datas.refresh ? `c7ur=${__datas.refresh}; max-age=${60 * 60 * 24 * 7}; path=/; secure=; SameSite=strict` : "";
    }
    store.dispatch(setUser({...store.getState().user, ...__datas, logged: true}));
}

// TOOLS

function spinAppear(__form:string|null = null) {
    if(__form == null) {
        store.dispatch(setLoginActivity(true));
        return void(0);
    }
    selectOne(`#${__form} button[type="submit"] .animate-spin`)?.setAttribute('visibility', 'visible')
}

function spinDisappeppear(__form:string|null = null) {
    if(__form == null) {
        store.dispatch(setLoginActivity(false));
        return void(0);
    }
    selectOne(`#${__form} button[type="submit"] .animate-spin`)?.setAttribute('visibility', 'hidden')
}

function findRefresh():string|null {
    let _refresh:string|null = null;

    // Redux
    _refresh = store.getState().user.refresh;
    if(_refresh) {return _refresh;}
    
    // cookies
    _refresh = Object.fromEntries(document.cookie?.split("; ").map(el => [el.split("=")[0], el.split("=")[1] ?? ""]))["c7ur"] ?? null;
    if(_refresh) {return _refresh;}

    // localStorage
    _refresh = window?.localStorage.getItem("c7ur");
    if(_refresh) {return _refresh;}
    
    return null;
}