import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';

import { initSelectNone } from '../../functions/inits';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../../interfaces/redux';
import { Entity_User } from '../../interfaces/entities';
import { animals_scheme } from './const';
import { isArray, isObject } from '../../functions/is-type';
import { resolveEntityPath } from '../../functions/tools';

const MySpaceDisplayAnimals = (__datas:MySpaceDisplayAnimals = Object()) => {

    const loginActivity = useSelector((state:ReduxStore) => state.page.loginActivity);
    const { t, i18n } = useTranslation();

    console.log(__datas.profile);

    const displayInformation = function(info, params) {
        // console.group("displayInformation");
        // console.log(info);
        if(isArray(info)) {

        }
        if(isObject(info)) {

        }
        return <span className="ml-1 information editable">{info}</span>
    };

    const displayToggle = function(info, params) {
        // console.group("displayToggle");
        // console.log(info);
    };

    const displayButton = function(info, params) {
        // console.group("displayButton");
        // console.log(info);
    };

    const classes = {
        "default": "text-black block",
        "titre": "text-pink-600 font-bold text-base block empty:h-6",
        "categorie": "text-gray-800 uppercase text-xs block",
        "information": "text-gray-600 text-xs block",
    };

    const elem_type_classes = function(_type:string|null = null) {
        if(_type == null) {return "";}
        return classes[_type] ?? classes.default;
    }

    React.useEffect(() => {
        initSelectNone();
    }, [loginActivity, __datas.profile]);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <>
            {isArray(animals_scheme) ? animals_scheme.map((box, key_box) =>
                <div key={key_box} className={["sm:rounded-md lg:rounded-lg p-2 bg-white inline-flex flex-row w-full shadow-lg shadow-gray-300", key_box > 0 ? 'mt-4' : ''].join(' ')}>
                    {isArray(box) ? box.map((part, key_part) =>
                        <div key={[key_box, key_part].join('-')} className={["flex align-top flex-col flex-1", key_part > 0 ? "pl-2 border-l-2 border-red-400 empty:border-none" : ""].join(' ')}>
                            {isArray(part) ? part.map((elem, key_elem) => {
                                if(elem.type == 'titre') {
                                    return <div key={[key_box, key_part, key_elem].join('-')} className={elem_type_classes(elem.type)}>{t(elem.label)}</div>;
                                }
                                if(elem.type == 'information') {
                                    return (
                                        <>
                                            <div key={[key_box, key_part, key_elem].join('-')} className={elem_type_classes(elem.type)}>
                                                {[t(elem.label), ':'].join(' ')}
                                                {elem.resolve && displayInformation(resolveEntityPath(__datas.profile, elem.resolve), elem)}
                                            </div>
                                        </>
                                    );
                                }
                                if(elem.type == 'toggle') {
                                    return <></>;
                                }
                                if(elem.type == 'button') {
                                    return <></>;
                                }
                                return <></>;
                            }) : <></>}
                        </div>
                    ) : <></>}
                </div>
            ) : <></>}
        </>
    );
};

interface MySpaceDisplayAnimals {
    key?: any;
    profile?: Entity_User;
};

export default hot(module)(MySpaceDisplayAnimals);