import { hot } from 'react-hot-loader';

import React from "react";

import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';
import { SelectWithIconOption } from '../../interfaces/elements';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const SelectWithIcons = (__datas:SelectWithIcons = Object()) => {

    const [selected, setSelected]:[number, React.Dispatch<number>] = React.useState(__datas.defaultSelected || 0);
    const [curr, setCurr]:[SelectWithIconOption, React.Dispatch<SelectWithIconOption>] = React.useState(__datas.options[selected]);
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
        console.log(selected);
        console.log(curr);
        console.log(__datas.options);
        console.log(__datas.options[selected]);
        setCurr(__datas.options[selected]);
    }, [selected, __datas]);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <Listbox value={selected} onChange={(e) => {
            console.log(e);
            setSelected(e);
        }}>
            {({ open }) => (
                <div className={[...__datas.classes || []].join(' ')}>
                    <Listbox.Label className="block text-sm font-medium text-gray-700">{t(__datas.label || "components->select->label->default")}</Listbox.Label>
                    <div className="mt-1 relative">
                    <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-pink-500 focus:border-pink-500 sm:text-sm">
                        <span className="flex items-center">
                        <img
                            src={curr.icon?.src instanceof URL ? curr.icon?.src?.href : curr.icon?.src}
                            alt={t(curr.icon?.alt || "")}
                            title={t(curr.icon?.title || "")}
                            className="flex-shrink-0 h-6 w-6 rounded-full"
                        />
                        <input readOnly={true} id={__datas.fieldName} className={["sr-only", __datas.formFor ? "form-elem" : ""].join(' ')} name={__datas.fieldName} value={__datas.options[selected].value} required={__datas.required} form={__datas.formFor}/>
                        <span className="ml-3 block truncate">{t(curr.label || "components->select->option->label->default", {index: __datas.options[selected]?.label == undefined ? selected + 1 : ""})}</span>
                        </span>
                        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </Listbox.Button>
        
                    <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm thin-scrollbar max-h-44">
                            {__datas.options.map((option:SelectWithIconOption, key:number) => (
                                <Listbox.Option
                                    key={key}
                                    className={({ active }) =>
                                        classNames(
                                        active ? 'text-white bg-pink-600' : 'text-gray-900',
                                        'cursor-default select-none relative py-2 pl-3 pr-9'
                                        )
                                    }
                                    value={key}
                                >
                                    {({ selected, active }) => (
                                        <>
                                        <div className="flex items-center">
                                            <img src={option.icon?.src instanceof URL ? option.icon?.src?.href : option.icon?.src} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                                            <span
                                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                            >
                                            {t(option.label || "components->select->option->label->default", {index: key + 1})}
                                            </span>
                                        </div>
                
                                        {selected ? (
                                            <span
                                            className={classNames(
                                                active ? 'text-white' : 'text-pink-600',
                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                            )}
                                            >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                    </div>
                </div>
            )}
        </Listbox>
    );
};

interface SelectWithIcons {
    key?: any;
    label?: string;
    classes?: string|Array<string>;
    fieldName?: string;
    formFor?: string;
    required?: boolean;
    multiple?: boolean;
    defaultSelected?: number;
    options: SelectWithIconOption[];
};

export default hot(module)(SelectWithIcons);