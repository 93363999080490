/**
 * @file Attach a unique key + id pair to each elem passed to. Permit to dynamically manage website
*/

import { _error, _trace } from "./const";
import { isEmptyString } from "./is-type";

// CONSTANTES

export const _ids = {};
export const _clefs = {};

// CHAÎNE DE CARACTÈRES ALÉATOIRE

export const chaineAleatoire = function(length = 8, prefixe = '_') {
    // _log("chaineAleatoire");
    return Math.random().toString(16).substr(2, length);
};

// IDENTIFIANTS

export const creerId = function(prefixe = '') {
    // _log("creerId");
    if(typeof prefixe != 'string') {
        return prefixe = '';
    }
    let _temp = prefixe + chaineAleatoire(8);
    while(_ids[_temp]) {
        _temp = prefixe + chaineAleatoire(8);
    }
    _ids[_temp] = true;
    return _temp;
}

export const ajouterId = function(_id:string|null = null, _prefixe = '') {
    if(isEmptyString(_id) || typeof _id != "string") {
        return creerId(_prefixe);
    }
    _ids[_id] = true;
    return _id;
}

export const detruireId = function(_id) {
    // _log("detruireId");
    delete _ids[_id];
}

// CLEFS

export const ajouterClef = function(_id, prefixe = '') {
    // _log("ajouterClef");
    if(_clefs[_id]) {
        // _log("Exists on keys");
        return _id;
    }
    // _log("Does not exists on keys");
    let _clef = prefixe + chaineAleatoire(8);
    while(_clefs[_id]) {
        _clef = prefixe + chaineAleatoire(8);
    }
    _clefs[_id] = _clef;
    return _clef;
}

export const avoirClef = function(_id) {
    // _log("avoirClef");
    return _clefs[_id] || null;
}

export const detruireClef = function(_clef) {
    // _log("detruireClef");
    delete _clefs[_clef];
}

// COUPLE

/**
 * 
 * @param {string} _id Identifiant si existant
 * @param {string} _prefix Préfixe à mettre devant l'id final
 * @returns {string} Identifiant créé
*/
export const creerCouple = function(_id, _prefix = '') {
    // _log("creerCouple");
    try {
        if(isEmptyString(_id)) {
            _id = creerId(_prefix);
        }
        else if(idExists(_id)) {

        }
        else {
            ajouterId(_id, _prefix);
        }
        ajouterClef(_id, _prefix);
        return _id;
    }
    catch(err) {
        _trace(err);
        _error(err);
        return false;
    }
}

/**
 * 
 * @param {string} _id Identifiant à vérifier
 * @returns {boolean} _id existe ou pas dans _ids
*/
export const idExists = function(_id = null) {
    if(typeof _id != "string") {return false;}
    return _id in _ids;
}

/**
 * 
 * @param {string} _id Identifiant à supprimer
 * @returns {boolean} Résultat de l'opération de suppression
*/
export const detruireCouple = function(_id) {
    // _log("detruireCouple");
    try {
        detruireId(_id);
        // _log(_id);
        detruireClef(_id);
        // _log(_id);
        return true;
    }
    catch(err) {
        _trace(err);
        _error(err);
        return false;
    }
}