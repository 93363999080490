import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';
import { chaineAleatoire } from '../../functions/dynamic-id';
import { resolveClasses } from '../../functions';

const CheckboxInput = (__datas:CheckboxInput = Object()) => {

    const { t, i18n } = useTranslation();

    __datas.fieldName = __datas.fieldName || `_${chaineAleatoire(16)}`;

    React.useEffect(() => {
        initSelectNone();
    }, [__datas]);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <div className={resolveClasses("relative flex items-start font-normal hover:font-medium whitespace-pre-line", (__datas.classes || []).join(' '))}>
            <div className="flex items-center h-5">
                <input
                    id={__datas.fieldName}
                    aria-describedby={`${__datas.fieldName}-description`}
                    name={__datas.fieldName}
                    type="checkbox"
                    className={`focus:ring-pink-700 h-4 w-4 text-pink-900 border-gray-300 rounded cursor-pointer${__datas.formFor ? " form-elem" : ""}`}
                    form={__datas.formFor}
                    required={__datas.required || false}
                    defaultChecked={__datas.checked || false}
                    disabled={__datas.disabled || false}
                    value={__datas.value}
                />
            </div>
            <div className="ml-3 text-sm cursor-pointer">
                <label htmlFor={__datas.fieldName} className=" text-gray-700 cursor-pointer">
                    {t(__datas.label || "components->checkbox->label->default")}
                </label>
                <span id={`${__datas.fieldName}-description`} className="text-gray-500 cursor-pointer">
                    <span className="sr-only">{t(__datas.label || "components->checkbox->label->default")}</span>{t(__datas.comments || "")}{__datas.children}
                </span>
            </div>
            <span className={`${__datas.fieldName}_status inline-block py-0.5 whitespace-pre`}></span>
        </div>
    );
};

interface CheckboxInput {
    key?: any;
    children?: any;
    checked?: boolean;
    disabled?: boolean;
    formFor?: string;
    required?: boolean;
    classes?: Array<string>;
    fieldName?: string;
    label?: string;
    comments?: string;
    value?: any;
};

export default hot(module)(CheckboxInput);