import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';

const InputRange = (__datas:InputRange = Object()) => {

    const { t, i18n } = useTranslation();

    const [value, setValue]:[number, React.Dispatch<number>] = React.useState(__datas.defaultValue || 0);

    React.useEffect(() => {
        initSelectNone();
    }, [__datas]);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <div className={`relative pt-1 ${__datas.classes?.join('')}`}>
            <label
                htmlFor={__datas.fieldName}
                className={`form-label ${__datas.labelClasses?.join(' ')}`}
            >
                {t(__datas.label || "components->range->label->default")}
                {__datas.displayValue && <span className="inline-block text-xs font-bold text-pink-500 ml-1">({value})</span>}
            </label>
            <input
                type="range"
                className={`form-range w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none ${__datas.inputClasses?.join(' ')}${__datas.formFor ? " form-elem" : ""}`}
                id={__datas.fieldName}
                name={__datas.fieldName}
                defaultValue={__datas.defaultValue}
                form={__datas.formFor}
                required={__datas.required || false}
                min={__datas.min}
                max={__datas.max}
                step={__datas.step}
                list={__datas.listId}
                onChange={(e) => {
                    setValue(parseInt(e.target.value));
                }}
                onMouseUp={(e) => {
                    __datas.onChange && __datas.onChange(e);
                }}
            />
            {__datas.listId && __datas.list && 
                <datalist id={__datas.listId}>
                    {__datas.list.map((option, key) => 
                        <option value={option.value} label={option.label}></option>
                    )}
                </datalist>
            }
        </div>
    );
};

interface InputRange {
    key?: any;
    defaultValue?: number;
    disabled?: boolean;
    formFor?: string;
    classes?: Array<string>;
    labelClasses?: Array<string>;
    inputClasses?: Array<string>;
    required?: boolean;
    fieldName?: string;
    label?: string;
    autoComplete?: string;
    onChange?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
    min?: number;
    max?: number;
    step?: number;
    listId?: string;
    list?: {
        value?: number;
        label?: string;
    }[];
    displayValue?: boolean;
};

export default hot(module)(InputRange);