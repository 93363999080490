import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'page',
    initialState: {
        "db_id": null,
        "logged": false,
        "email": null,
        "highest_role": null,
        "highest_role_label": null,
        "full_name": null,
        "prenom": null,
        "nom_jeune_fille": null,
        "nom_famille": null,
        "nom_artiste": null,
        "sexe": null,
        "nationalite": null,
        "roles_site": null,
        "has_animals": null,
        "has_vehicles": null,
        "has_tutor_agreements": null,
        "age": null,
        "has_licence": null,
        "is_verified": null,
        "is_active": null,
        "lang": null,
        "hpsd": null,
        "birthdate": null,
        "tuteur": null,
        "langues": null,
        "phone": null,
        "mobile": null,
        "refresh": null,
        "roles": null,
        "password": null,
        "vehicules": null,
        "site_internet": null,
        "evaluation_comedien": null,
        "approbation_comedien": null,
        "main_photos": null,
        "photos": null,
        "main_address": null,
        "main_address_str": null,
        "adresses": null,
        "situation_professionnelle": null,
        "permis": null,
        "animaux": null,
        "hobbies": null,
        "profession": null,
        "danses": null,
        "sports": null,
        "mensurations": null,
        "description_physique": null,
        "old_uid": null,
        "carte_artiste": null,
        "numeros": null,
        "cv_artiste": null,
        "identite": null,
        "candidatures": null,
        "comptes_bancaires": null,
        "musiques": null,
        "contrats": null,
        "userAttributions": null,
        "affiliates": null,
        "relative": null,
        "can_be_managed": null,
        "friends": null,
        "reset": {"db_id": null, "logged": false, "email": null, "highest_role": null, "highest_role_label": null, "full_name": null, "prenom": null, "nom_jeune_fille": null, "nom_famille": null, "nom_artiste": null, "sexe": null, "nationalite": null, "roles_site": null, "has_animals": null, "has_vehicles": null, "has_tutor_agreements": null, "age": null, "has_licence": null, "is_verified": null, "is_active": null, "lang": null, "hpsd": null, "birthdate": null, "tuteur": null, "langues": null, "phone": null, "mobile": null, "refresh": null, "roles": null, "password": null, "vehicules": null, "site_internet": null, "evaluation_comedien": null, "approbation_comedien": null, "main_photos": null, "photos": null, "main_address": null, "main_address_str": null, "adresses": null, "situation_professionnelle": null, "permis": null, "animaux": null, "hobbies": null, "profession": null, "danses": null, "sports": null, "mensurations": null, "description_physique": null, "old_uid": null, "carte_artiste": null, "numeros": null, "cv_artiste": null, "identite": null, "candidatures": null, "comptes_bancaires": null, "musiques": null, "contrats": null, "userAttributions": null, "affiliates": null, "relative": null, "can_be_managed": null, "friends": null}
    },
    reducers: {
        setUser: (state, value) => void(Object.keys(value.payload).forEach(key => state[key] = value.payload[key])),

        setDbId: (state, value) => void(state.db_id = value.payload),
        setLogged: (state, value) => void(state.logged = value.payload),
        setEmail: (state, value) => void(state.email = value.payload),
        setHighestRole: (state, value) => void(state.highest_role = value.payload),
        setHighestRoleLabel: (state, value) => void(state.highest_role_label = value.payload),
        setFullName: (state, value) => void(state.full_name = value.payload),
        setPrenom: (state, value) => void(state.prenom = value.payload),
        setNomJeuneFille: (state, value) => void(state.nom_jeune_fille = value.payload),
        setNomFamille: (state, value) => void(state.nom_famille = value.payload),
        setNomArtiste: (state, value) => void(state.nom_artiste = value.payload),
        setSexe: (state, value) => void(state.sexe = value.payload),
        setNationalite: (state, value) => void(state.nationalite = value.payload),
        setRolesSite: (state, value) => void(state.roles_site = value.payload),
        setHasAnimals: (state, value) => void(state.has_animals = value.payload),
        setHasVehicles: (state, value) => void(state.has_vehicles = value.payload),
        setHasTutorAgreements: (state, value) => void(state.has_tutor_agreements = value.payload),
        setAge: (state, value) => void(state.age = value.payload),
        setHasLicence: (state, value) => void(state.has_licence = value.payload),
        setIsVerified: (state, value) => void(state.is_verified = value.payload),
        setIsActive: (state, value) => void(state.is_active = value.payload),
        setLang: (state, value) => void(state.lang = value.payload),
        setHpsd: (state, value) => void(state.hpsd = value.payload),
        setDateNaissance: (state, value) => void(state.birthdate = value.payload),
        setTuteur: (state, value) => void(state.tuteur = value.payload),

        addLangue: (state, value) => void(state.langues[value.payload.slug] = value.payload.datas),
        setLangues: (state, value) => void(state.langues = value.payload),

        setPhone: (state, value) => void(state.phone = value.payload),
        setMobile: (state, value) => void(state.mobile = value.payload),
        setRefresh: (state, value) => void(state.refresh = value.payload),
        setRoles: (state, value) => void(state.roles = value.payload),
        setPassword: (state, value) => void(state.password = value.payload),
        
        setVehicules: (state, value) => void(state.vehicules = value.payload),
        setSiteInternet: (state, value) => void(state.site_internet = value.payload),
        setEvaluationComedien: (state, value) => void(state.evaluation_comedien = value.payload),
        setApprobationComedien: (state, value) => void(state.approbation_comedien = value.payload),
        
        addPhotos: (state, value) => void(state.photos[value.payload.slug] = value.payload.datas),
        setPhotos: (state, value) => void(state.photos = value.payload),
        setMainPhotos: (state, value) => void(state.main_photos = value.payload),

        setMainAddress: (state, value) => void(state.main_address = value.payload),
        setMainAddressStr: (state, value) => void(state.main_address_str = value.payload),
        
        addAdresses: (state, value) => void(state.adresses[value.payload.slug] = value.payload.datas),
        setAdresses: (state, value) => void(state.adresses = value.payload),
        
        setSituationProfessionnelle: (state, value) => void(state.situation_professionnelle = value.payload),
        
        addPermis: (state, value) => void(state.permis[value.payload.slug] = value.payload.datas),
        setPermis: (state, value) => void(state.permis = value.payload),
        
        addAnimaux: (state, value) => void(state.animaux[value.payload.slug] = value.payload.datas),
        setAnimaux: (state, value) => void(state.animaux = value.payload),
        
        addHobbies: (state, value) => void(state.hobbies[value.payload.slug] = value.payload.datas),
        setHobbies: (state, value) => void(state.hobbies = value.payload),
        
        setProfession: (state, value) => void(state.profession = value.payload),
        
        addDanses: (state, value) => void(state.danses[value.payload.slug] = value.payload.datas),
        setDanses: (state, value) => void(state.danses = value.payload),
        
        addSports: (state, value) => void(state.sports[value.payload.slug] = value.payload.datas),
        setSports: (state, value) => void(state.sports = value.payload),
        
        setMensurations: (state, value) => void(state.mensurations = value.payload),
        setDescriptionPhysique: (state, value) => void(state.description_physique = value.payload),
        setOldUid: (state, value) => void(state.old_uid = value.payload),
        setCarteArtiste: (state, value) => void(state.carte_artiste = value.payload),
        
        setNumeros: (state, value) => void(state.numeros = value.payload),
        
        setCvArtiste: (state, value) => void(state.cv_artiste = value.payload),
        setIdentite: (state, value) => void(state.identite = value.payload),
        
        setCandidatures: (state, value) => void(state.candidatures = value.payload),
        setComptesBancaires: (state, value) => void(state.comptes_bancaires = value.payload),
        
        addMusiques: (state, value) => void(state.musiques[value.payload.slug] = value.payload.datas),
        setMusiques: (state, value) => void(state.musiques = value.payload),
        
        addContrats: (state, value) => void(state.contrats[value.payload.slug] = value.payload.datas),
        setContrats: (state, value) => void(state.contrats = value.payload),

        setUserAttributions: (state, value) => void(state.userAttributions = value.payload),
        
        addAffiliates: (state, value) => void(state.affiliates[value.payload.slug] = value.payload.datas),
        setAffiliates: (state, value) => void(state.affiliates = value.payload),

        setRelative: (state, value) => void(state.relative = value.payload),
        setCanBeManaged: (state, value) => void(state.can_be_managed = value.payload),
        
        addFriends: (state, value) => void(state.friends[value.payload.slug] = value.payload.datas),
        setFriends: (state, value) => void(state.friends = value.payload),
    }
});

// Action creators are generated for each case reducer function
export const { setUser, setDbId, setLogged, setEmail, setHighestRole, setHighestRoleLabel, setFullName, setPrenom, setNomJeuneFille, setNomFamille, setNomArtiste, setSexe, setNationalite, setRolesSite, setHasAnimals, setHasVehicles, setHasTutorAgreements, setAge, setHasLicence, setIsVerified, setIsActive, setLang, setHpsd, setDateNaissance, setTuteur, addLangue, setLangues, setPhone, setMobile, setRefresh, setRoles, setPassword, setVehicules, setSiteInternet, setEvaluationComedien, setApprobationComedien, addPhotos, setPhotos, setMainPhotos, setMainAddress, setMainAddressStr, addAdresses, setAdresses, setSituationProfessionnelle, addPermis, setPermis, addAnimaux, setAnimaux, addHobbies, setHobbies, setProfession, addDanses, setDanses, addSports, setSports, setMensurations, setDescriptionPhysique, setOldUid, setCarteArtiste, setNumeros, setCvArtiste, setIdentite, setCandidatures, setComptesBancaires, addMusiques, setMusiques, addContrats, setContrats, setUserAttributions, addAffiliates, setAffiliates, setRelative, setCanBeManaged, addFriends, setFriends } = userSlice.actions;

export default userSlice.reducer;