import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';

import { initSelectNone } from '../components/functions/inits';

const TermsPage = (__datas:TermsPage = {}) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <main data-page="terms" className="custom-scrollbar">
            Termes et conditions
        </main>
    );
};

interface TermsPage {
    key?: any;
};

export default hot(module)(TermsPage);