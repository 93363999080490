import React from "react";
import { hot } from "react-hot-loader";
import { Entity_Annonce } from "../interfaces/entities";
import { useTranslation } from "react-i18next";

import "./annonce-details.css";
import { missing_informations, resolve_annonce_detail, resolve_gender_icon } from "../functions/annonce";
import { ReduxStore } from "../interfaces/redux";
import { initSelectNone } from "../functions/inits";
import { useSelector } from 'react-redux';
import Link from "../Link";
import { urls } from "../const";

const AnnonceDetails = ({ annonce }:AnnonceDetails) => {

    if(!annonce) {
        return <></>;
    }

    const user = useSelector((state:ReduxStore) => state.user);
    const [missings, setMissings] = React.useState(missing_informations(user, annonce));
    const [linked, setLinked] = React.useState([]);
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        setMissings(missing_informations(user, annonce));
    }, [user]);

    React.useEffect(() => {
        initSelectNone();
    }, [annonce]);

    return (
        <div id="annonce-infos-details">
            <h3>{t('pages->annonce->details->annonce_descr')}</h3>
            <div id="annonce-description">{annonce?.descr && annonce.descr.length ? annonce.descr : t('pages->annonce->details->no_descr')}</div>
            {missings && user.logged && <div id="missings">
                {missings.length > 0 && <h3>{t('pages->annonce->details->missing_informations')}</h3>}
                {missings.length == 0 && <div className="annonce-missing_data">{t('pages->annonce->details->no_missing')}</div>}
                {missings.length > 0 && missings.map(missing => (
                    <div className="annonce-missing_data">{missing}</div>
                ))}
            </div>}
            {(annonce.linked ?? []).length > 0 && <div id="linked-annonces">
                <h3>{t('pages->annonce->details->annonce_liees')}</h3>
                <div id="linked-annonces-list" className="custom-scrollbar">
                    {annonce.linked.map((link, link_i) => (
                        <div className="annonce-infos-details-linked" key={link_i}>
                            <Link
                                classes={["absolute-link"]}
                                url={urls.annonce.url.replace('{hashid}', link.db_id)}
                                page={urls.annonce.page}
                                title={t('pages->annonces->annonce->link->title', {title: link.titre})}
                                style={{zIndex: 10}}
                            ></Link>
                            <h4 className="ellipsis" title={link.titre}>{link.titre}</h4>
                            <div className="annonce-linked-details-pic">
                                {link.poster && <img src={process.env.BACK_URL + '/' + link.poster} alt={link.titre}/>}
                                {!link.poster && <img src={new URL('../../assets/icons/no-img.svg', import.meta.url)?.href} alt={link.titre}/>}
                            </div>
                            <div className="annonce-linked-details-general">
                                {
                                    [
                                        {type: "project", img: new URL('../../assets/icons/annonce/project.svg', import.meta.url)?.href},
                                        {type: "type", img: new URL('../../assets/icons/annonce/type.svg', import.meta.url)?.href},
                                        {type: "money", img: new URL('../../assets/icons/annonce/money.svg', import.meta.url)?.href},
                                        {type: "gender", img: new URL('../../assets/icons/annonce/gender.svg', import.meta.url)?.href},
                                        {type: "age", img: new URL('../../assets/icons/annonce/age.svg', import.meta.url)?.href},
                                        {type: "statut", img: new URL('../../assets/icons/annonce/statut.svg', import.meta.url)?.href},
                                        {type: "location", img: new URL('../../assets/icons/annonce/location.svg', import.meta.url)?.href},
                                        {type: "calendar", img: new URL('../../assets/icons/annonce/calendar.svg', import.meta.url)?.href},
                                    ]
                                    .map((detail, detail_key) => (
                                        detail.type == "gender" ?
                                        <div className={`annonce-linked-details-general-elem annonce-linked-short-${detail.type}`} key={detail_key}>
                                            {(resolve_gender_icon(link, "annonces") ?? []).map((icon, icon_i, icons) => (
                                                <><img src={icon.src} title={icon.title}/>{icon_i + 1 < icons.length ? ' | ' : ''}</>
                                            ))}
                                            <span className="ellipsis">{resolve_annonce_detail(detail.type, link, "annonces")}</span>
                                        </div> : 
                                        <div className={`annonce-linked-details-general-elem annonce-linked-short-${detail.type}`} key={detail_key}>
                                            <img src={detail.img} title={t(`pages->annonces->infos->details->icon->${detail}`)}/>
                                            <span className="ellipsis">{resolve_annonce_detail(detail.type, link, "annonces")}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))}
                </div>
                </div>
            }
            
        </div>
    );
};

interface AnnonceDetails {
    annonce?: Entity_Annonce;
};

export default hot(module)(AnnonceDetails);