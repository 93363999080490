{
    "lng_de": "Deutsch",
    "lng_en": "English",
    "lng_fr": "Français",
    "lng_lu": "Luxemburgisch",
    "lng_nl": "Nederlands",
    "lng_de_LU": "Deutsch",
    "lng_en_EN": "English",
    "lng_fr_BE": "Français",
    "lng_fr_CH": "Français",
    "lng_fr_FR": "Français",
    "lng_fr_LU": "Français",
    "lng_lu_LU": "Luxemburgisch",
    "lng_nl_BE": "Hollandsk",
    "lng_nl_NL": "Hollandsk",
    "country_BE": "Belgique",
    "country_CH": "Suisse",
    "country_EN": "Angleterre",
    "country_FR": "France",
    "country_LU": "Luxembourg",
    "country_NL": "Pays-Bas"

}