import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../functions/inits';

import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
    ArchiveIcon,
    ArrowCircleRightIcon,
    ChevronDownIcon,
    DuplicateIcon,
    HeartIcon,
    PencilAltIcon,
    PlusIcon,
    TrashIcon,
    UserAddIcon,
} from '@heroicons/react/solid'
import { MenuOption } from '../interfaces/elements';

const __MenuIcons = {
    "archiveIcon": <ArchiveIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true"/>,
    "arrowCircleRightIcon": <ArrowCircleRightIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true"/>,
    "chevronDownIcon": <ChevronDownIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true"/>,
    "duplicateIcon": <DuplicateIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true"/>,
    "heartIcon": <HeartIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true"/>,
    "pencilAltIcon": <PencilAltIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true"/>,
    "trashIcon": <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true"/>,
    "userAddIcon": <UserAddIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true"/>,
    "plusIcon": <PlusIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true"/>,
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function resolveIcon(__name:string|null = null) {
    if(typeof __name != "string") {return <></>;}
    return __MenuIcons[__name] ?? <></>;
}

const DropdownIcons = (__datas:DropdownIcons = Object()) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-pink-700">
                    {t(__datas.titre || "components->dropdown->titre->default")}
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    {
                        __datas.groups ? __datas.groups.map((group, group_key) => {
                            return (
                                <div className="py-1" key={`group_${group_key}`}>
                                    {group.menus.map((menu, menu_key) => {
                                        return (
                                            <Menu.Item>
                                                {({ active }) => (
                                                <a key={`menu_${menu_key}`} href={menu.url} className={classNames( active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                    , 'group flex items-center px-4 py-2 text-sm' )}>
                                                    {resolveIcon(menu.icon)}
                                                    {t(menu.libelle || "components->dropdown->menu->label->default")}
                                                </a>
                                                )}
                                            </Menu.Item>
                                        );
                                    })}
                                </div>
                            );
                        }) : <></>
                    }
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

interface DropdownIcons {
    key?: any;
    titre?: string;
    groups?: {
        menus: MenuOption[];
    }[]
};

export default hot(module)(DropdownIcons);