import React from 'react';
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../functions/inits';
import { __backURLs, _log } from '../functions/const';

import store from '../../app/store';

import DateInput from "../Basics/Input/date";
import InputRange from "../Basics/Input/range";
import TextualInput from "../Basics/Input/text-input";
import SelectCheck from "../Basics/Select/check";

import Toggle from '../Basics/Toggle/index';
import ShortToggle from '../Basics/Toggle/short';
import { hot } from 'react-hot-loader';

import "./annonces-filter.css";
import { setFilters } from '../../features/page/pageSlice';
import LoadingGIF from '../Basics/LoadingGIF';

const AnnoncesFilter = ({ key, params, loading }:AnnoncesFilter = {}) => {

    // DONE - Supprimer bySexe
    // DONE - Ajouter byAnimals
    // DONE - Ajouter byVehicles
    // TODO - Si connecté mettre le toggle sexe concerné en true
    // TODO - Si connecté et animaux mettre le toggle animaux en true
    // TODO - Si connecté et véhicules mettre le toggle véhicules en true
    // TODO - Si connecté, mettre en false les toggle selon les paramètres personnels (sexe, animaux, véhicules, interessé par silhouettes|figuration, etc...)
    const [byMale, setByMale]:[boolean, React.Dispatch<boolean>] = React.useState(false);
    const [byFemale, setByFemale]:[boolean, React.Dispatch<boolean>] = React.useState(false);
    const [byAnimals, setByAnimals]:[boolean, React.Dispatch<boolean>] = React.useState(false);
    const [byVehicles, setByVehicles]:[boolean, React.Dispatch<boolean>] = React.useState(false);
    const [maxAge, setMaxAge]:[any, React.Dispatch<any>] = React.useState(null);
    const [oldage, setOldage]:[any, React.Dispatch<any>] = React.useState(false);
    const [current, setCurrent]:[any, React.Dispatch<any>] = React.useState(false);
    const [ethnie, setEthnie]:[string, React.Dispatch<string>] = React.useState('');
    const [genre, setGenre]:[string, React.Dispatch<string>] = React.useState('');
    const [zip, setZip]:[string, React.Dispatch<string>] = React.useState('');
    const [distance, setDistance]:[any, React.Dispatch<any>] = React.useState(null);
    const [date, setDate]:[string, React.Dispatch<string>] = React.useState('');

    const { t, i18n } = useTranslation();

    const ageFilter = (__params:{min?:number, max?:number, default?:number}|null = null) => {
        return (
            <InputRange
                label={t('pages->annonces->filter-zone->filter-by-age->label')}
                fieldName="filter-age-input"
                min={__params && __params.min || 18}
                max={__params && __params.max || 99}
                step={1}
                defaultValue={__params && __params.default || 18}
                // listId="filter-age-list"
                // list={[
                //     {value: 18, label: '18'},
                //     {value: 25, label: '25'},
                //     {value: 35, label: '35'},
                //     {value: 45, label: '45'},
                //     {value: 55, label: '55'},
                //     {value: 65, label: '65'},
                //     {value: 75, label: '75'},
                //     {value: 85, label: '85'},
                //     {value: 95, label: '95'},    
                // ]}
                displayValue={true}
                onChange={(e) => setMaxAge(parseInt(e.currentTarget.value))}
            />
        );
    }

    const ethnieFilter = (__params:{label:string, value:string}[]|null = null) => {
        if(__params == null || Array.isArray(__params) == false || __params.length == 0) {
            return <></>;
        }
        return (
            <>
                <SelectCheck onChange={setEthnie} label={'pages->annonces->filter-zone->filter-by-ethnie->label'} options={__params} fieldName="filter-ethnie"/>
            </>
        );
    }

    const genreFilter = (__params:{label:string, value:string}[]|null = null) => {
        if(__params == null || Array.isArray(__params) == false || __params.length == 0) {
            return <></>;
        }
        return (
            <>
                <SelectCheck onChange={setGenre} label={'pages->annonces->filter-zone->filter-by-genre->label'} options={__params} fieldName="filter-genre"/>
            </>
        );
    }

    React.useEffect(() => {
        store.dispatch(setFilters({
            key: "annonces",
            value: {
                bySexe: byMale || byFemale,
                byMale: byMale,
                byFemale: byFemale,
                byAnimals: byAnimals,
                byVehicles: byVehicles,
                maxAge: maxAge,
                oldage: oldage,
                current: current,
                ethnie: ethnie,
                genre: genre,
                zip: zip,
                distance: distance,
                date: date,
            }
        }));
    }, [byMale, byFemale, byAnimals, byVehicles, maxAge, oldage, current, ethnie, genre, zip, distance, date]);

    React.useEffect(() => {
        
    }, [params, loading]);

    React.useEffect(() => {
        initSelectNone();
    }, []);

    return (
        <div id="annonces-filter" className="thin-scrollbar" style={{direction: "rtl"}}>
            {loading && <div className="loading-mask"><LoadingGIF/></div>}
            <h3 style={{direction: "ltr"}}>{t('pages->annonces->filter-zone->title')}</h3>
            <div className="filter-zone" style={{direction: "ltr"}}>
                <div id="filter-sexe-male">
                    <Toggle
                        label={t('pages->annonces->filter-zone->filter-by-sexe-male->label')}
                        enabled={byMale}
                        onChange={(e) => setByMale(e)}
                        fieldName="filter-sexe-m"
                    />
                </div>
                <div id="filter-sexe-female">
                    <Toggle
                        label={t('pages->annonces->filter-zone->filter-by-sexe-female->label')}
                        enabled={byFemale}
                        onChange={(e) => setByFemale(e)}
                        fieldName="filter-sexe-f"
                    />
                </div>
                <div id="filter-animals">
                    <Toggle
                        label={t('pages->annonces->filter-zone->filter-by-animals->label')}
                        enabled={byAnimals}
                        onChange={(e) => setByAnimals(e)}
                        fieldName="filter-sexe-m"
                    />
                </div>
                <div id="filter-vehicles">
                    <Toggle
                        label={t('pages->annonces->filter-zone->filter-by-vehicles->label')}
                        enabled={byVehicles}
                        onChange={(e) => setByVehicles(e)}
                        fieldName="filter-sexe-f"
                    />
                </div>
                <div id="filter-age">
                    {ageFilter(params.age_options)}
                </div>
                <div id="filter-ethnie">
                    {ethnieFilter(params.ethnie_options)}
                </div>
                <div id="filter-oldage">
                    <Toggle
                        label={t('pages->annonces->filter-zone->filter-by-oldage-toggle->label')}
                        enabled={oldage}
                        onChange={(e) => setOldage(e)}
                        fieldName="filter-oldage"
                    />
                </div>
                <div id="filter-current">
                    <Toggle
                        label={t('pages->annonces->filter-zone->filter-by-current-toggle->label')}
                        enabled={current}
                        onChange={(e) => setCurrent(e)}
                        fieldName="filter-current"
                    />
                </div>
                <div id="filter-type_film">
                    {genreFilter(params.genre_options)}
                </div>
                <div id="filter-zip">
                    <TextualInput label={'pages->annonces->filter-zone->filter-by-zip->label'} formFor="annonces-filter" fieldName="filter-zip-input" onChange={(e) => setZip(e.target.value)} />
                </div>
                <div id="filter-distance">
                    <InputRange label={'pages->annonces->filter-zone->filter-by-distance->label'} formFor="annonces-filter" fieldName="filter-distance-range" min={0} max={1000} step={1} defaultValue={0} displayValue={true} onChange={(e) => setDistance(parseInt(e.currentTarget.value))} />
                </div>
                <div id="filter-date">
                    <DateInput label={'pages->annonces->filter-zone->filter-by-date->label'} formFor="annonces-filter" fieldName="filter-date-picker" onChange={(e) => setDate(e.target.value)} />
                </div>
            </div>
        </div>
    );
};

interface AnnoncesFilter {
    key?: any;
    params?: any;
    loading?: boolean;
};

export default hot(module)(AnnoncesFilter);