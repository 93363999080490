import { hot } from 'react-hot-loader';

import React from "react";

import Layout from './components/Layout';

const App = (_datas = {}) => {

    return (
        <Layout/>
    );
}

export default hot(module)(App);