import { hot } from 'react-hot-loader';

import React from "react";

import { useSelector, useDispatch } from 'react-redux';

import TextualInput from '../../Basics/Input/text-input';
import Divider from '../../Basics/Divider';
import CheckboxInput from '../../Basics/Input/checkbox-input';
import Button from '../../Basics/Button/index';
import AnimationSpinner from '../../Basics/Animations/spinner';

import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';
import { _log, _trace } from '../../functions/const';
import Link from '../../Link';
import { urls } from '../../const';
import { resolveFormElementValidity } from '../../functions';
import { FormElement } from '../../interfaces/elements';
import { ReduxStore } from '../../interfaces/redux';
import { getById, selectOne } from '../../functions/selectors';
import { __modales } from '../index';
import __signin__ from '../../functions/signin';

const ModaleSigninContent = (__datas:ModaleSigninContent = {}) => {

    const modale = useSelector((state:ReduxStore) => state.page.activeModale);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const checkSignin = function(e:React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if(modale == null) {return false;}
        Array.from(document?.forms[__modales[modale].formId]?.elements || []).map((elem:FormElement|any) => resolveFormElementValidity(__modales[modale].formId, elem.name || null));
        selectOne(`#${__modales[modale].formId} .invalid`) != null && selectOne(`#${__modales[modale].formId}`).reportValidity();
        if(document?.forms[__modales[modale].formId].checkValidity()) {
            console.log("Form valid");
            __signin__(__modales[modale].formId, "standard");
            return true;
            // TODO - Créer une fonction signin générale qui prenne en compte Facebook, Twitter, Google, le refresh token et la connexion usuelle mail + mot de passe
        }
        _log(("Form unvalid"));
        return false;
    };

    React.useEffect(() => {
        if(modale == __modales.signin.modaleId) {
            initSelectNone();
            selectOne(`#${__modales.signin.formId} input[name="email"]`)?.focus();
        }
    }, []);

    React.useEffect(() => {
        if(modale == __modales.signin.modaleId) {

        }
    }, [i18n.language, modale]);

    if(modale == null) {
        return <></>
    }

    return (
        <>
            <form id={__modales[modale].formId} name="signin-modale" className="space-y-8" key={__datas.key}
                onSubmit={(e) => {
                    if(!checkSignin(e)) {
                        let _elem = getById("signin-error");
                        if(!(_elem instanceof Element)) {return false;}
                        _elem.classList.add('hidden');
                        _elem = getById("form-error");
                        if(!(_elem instanceof Element)) {return false;}
                        _elem.classList.remove('hidden');
                        _elem.innerText = t("forms->signin->form_error");
                    }
                }}
            >
                <h2 className="mt-2 mb-4 text-center w-full block text-lg font-bold">{t("forms->signin->h2->label")}</h2>
                <TextualInput formFor={__modales[modale].formId} autoComplete="email" fieldName="email" type="email" required={true} label="forms->signin->email->label" labelClasses={["block text-sm font-medium text-gray-700"]} placeholder="forms->signin->email->placeholder"/>
                <TextualInput formFor={__modales[modale].formId} autoComplete="password" fieldName="password" type="password" required={true} label="forms->signin->password->label" labelClasses={["block text-sm font-medium text-gray-700"]} placeholder="forms->signin->password->placeholder"/>
                <div className="flex items-center justify-between">
                    <CheckboxInput label="forms->signin->remember_me->label" fieldName="remember_me"/>
                    <Link {...{...urls.forgot_pwd, libelle: "forms->signin->forgot_pwd->label", classes: [...urls.forgot_pwd.classes, "text-pink-700", "hover:text-pink-900", "font-medium"]}} style={{transition: "font-size 0.2s ease-in-out 0s"}}/>
                </div>
                <Button version="primary" size="B4" classes={["w-full", "my-3", "justify-center"]} type="submit" onClick={checkSignin}>
                    <AnimationSpinner hidden={true}/>
                    {t("forms->signin->signin->label")}
                </Button>
                <div id="form-error" className="text-red-600 font-light text-sm text-center pt-2 hidden" style={{margin: "0"}}></div>
                <div id="signin-error" className="text-red-600 font-light text-sm text-center pt-2 hidden" style={{margin: "0"}}></div>
            </form>
            <Divider type="with-label" label="forms->signin->or_signin_with->label" classes={["mt-4"]}/>
            <div className="flex justify-evenly my-2">
                <span className="cursor-pointer hover:bg-gray-300 py-0.5 px-2 rounded-md transition-colors">Google</span>
                <span className="cursor-pointer hover:bg-gray-300 py-0.5 px-2 rounded-md transition-colors">Facebook</span>
                <span className="cursor-pointer hover:bg-gray-300 py-0.5 px-2 rounded-md transition-colors">Twitter</span>
            </div>
            <Divider classes={["mt-4"]}/>
            <div className="w-full pt-3 text-center">
                <span>{t("forms->signin->create_account")}</span>
                <Link {...{...urls.signup, title: "forms->signin->signup", libelle: "forms->signin->signup", classes: ["text-pink-700", "hover:text-pink-900", "font-medium"]}}>{t("forms->signin->signup")}</Link>
            </div>
        </>
    );
};

interface ModaleSigninContent {
    key?: any;
};

export default hot(module)(ModaleSigninContent);