import { createSlice } from "@reduxjs/toolkit";
import { resolveURL } from "../../components/functions";
import { get_url_hash, get_url_search } from "../../components/functions/tools";

export const pageSlice = createSlice({
    name: 'page',
    initialState: {
        pathname: resolveURL(document?.location.pathname),
        search: get_url_search(),
        hash: get_url_hash(),
        activeModale: null,
        modaleGlobalEnv: null,
        modaleSpecificEnv: null,
        loginActivity: false,
        signupActivity: false,
        filters: {},
    },
    reducers: {
        setPage: (state, action) => void(state.pathname = action.payload),
        setSearch: (state, action) => void(state.search = action.payload),
        setHash: (state, action) => void(state.hash = action.payload),
        setModale: (state, action) => void(state.activeModale = action.payload),
        setModaleGlobalEnv: (state, action) => void(state.modaleGlobalEnv = action.payload),
        setModaleSpecificEnv: (state, action) => void(state.modaleSpecificEnv = action.payload),
        setLoginActivity: (state, action) => void(state.loginActivity = action.payload),
        setSignupActivity: (state, action) => void(state.signupActivity = action.payload),
        setFilters: (state, action) => {
            if(action.payload.value === null) {delete state.filters[action.payload.key]; return;}
            if(!action.payload.key) {return console.error("No key provided for setFilters action" + "\n\naction.payload.key: " + action.payload.key + "\naction.payload.value: " + action.payload.value);}
            if(!action.payload.value) {return console.error("No value provided for setFilters action" + "\n\naction.payload.key: " + action.payload.key + "\naction.payload.value: " + action.payload.value + "\n\n");}
            if(!action.payload.key in state.filters) {state.filters[action.payload.key] = action.payload.value; return;}
            state.filters = {...state.filters, [action.payload.key]: action.payload.value};
            return;
        },
    }
});

// Action creators are generated for each case reducer function
export const { setPage, setSearch, setHash, setModale, setModaleGlobalEnv, setModaleSpecificEnv, setLoginActivity, setSignupActivity, setFilters } = pageSlice.actions;

export default pageSlice.reducer;