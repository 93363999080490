{
    "lng_de": "Deutsch",
    "lng_en": "English",
    "lng_f": "Français",
    "lng_lu": "Luxemburgisch",
    "lng_nl": "Nederlands",
    "lng_de_LU": "Deutsch",
    "lng_en_EN": "English",
    "lng_fr_BE": "Français",
    "lng_fr_CH": "Français",
    "lng_fr_FR": "Français",
    "lng_fr_LU": "Français",
    "lng_lu_LU": "Luxemburgisch",
    "lng_nl_BE": "Hollandsk",
    "lng_nl_NL": "Hollandsk",
    "country_BE": "Belgique",
    "country_CH": "Suisse",
    "country_EN": "Angleterre",
    "country_FR": "France",
    "country_LU": "Luxembourg",
    "country_NL": "Pays-Bas",

    "errors": {
        "input_text": {
            "badInput": "Bad value",
            "patternMismatch": "Value does not match pattern",
            "rangeOverflow": "Range overflow",
            "rangeUnderflow": "Range underflow",
            "stepMismatch": "Value mismatch with step",
            "tooLong": "Value is too long",
            "tooShort": "Value is too short",
            "typeMismatch": "Value type mismatch",
            "valid": "Error",
            "valueMissing": "Missing value"
        },
        "input_email": {
            "badInput": "Bad value",
            "patternMismatch": "Value does not match pattern",
            "rangeOverflow": "Range overflow",
            "rangeUnderflow": "Range underflow",
            "stepMismatch": "Value mismatch with step",
            "tooLong": "Value is too long",
            "tooShort": "Value is too short",
            "typeMismatch": "Value type mismatch",
            "valid": "Error",
            "valueMissing": "Missing value"
        },
        "input_date": {
            "badInput": "Bad value",
            "patternMismatch": "Value does not match pattern",
            "rangeOverflow": "Range overflow",
            "rangeUnderflow": "Range underflow",
            "stepMismatch": "Value mismatch with step",
            "tooLong": "Value is too long",
            "tooShort": "Value is too short",
            "typeMismatch": "Value type mismatch",
            "valid": "Error",
            "valueMissing": "Missing value"
        },
        "input_checkbox": {
            "badInput": "Bad value",
            "patternMismatch": "Value does not match pattern",
            "rangeOverflow": "Range overflow",
            "rangeUnderflow": "Range underflow",
            "stepMismatch": "Value mismatch with step",
            "tooLong": "Value is too long",
            "tooShort": "Value is too short",
            "typeMismatch": "Value type mismatch",
            "valid": "Error",
            "valueMissing": "Vous devez cocher cette case pour continuer"
        },
        "input_radio": {
            "badInput": "Bad value",
            "patternMismatch": "Value does not match pattern",
            "rangeOverflow": "Range overflow",
            "rangeUnderflow": "Range underflow",
            "stepMismatch": "Value mismatch with step",
            "tooLong": "Value is too long",
            "tooShort": "Value is too short",
            "typeMismatch": "Value type mismatch",
            "valid": "Error",
            "valueMissing": "Missing value"
        },
        "input_number": {
            "badInput": "Bad value",
            "patternMismatch": "Value does not match pattern",
            "rangeOverflow": "Range overflow",
            "rangeUnderflow": "Range underflow",
            "stepMismatch": "Value mismatch with step",
            "tooLong": "Value is too long",
            "tooShort": "Value is too short",
            "typeMismatch": "Value type mismatch",
            "valid": "Error",
            "valueMissing": "Missing value"
        },
        "input_password": {
            "badInput": "Bad value",
            "patternMismatch": "Value does not match pattern",
            "rangeOverflow": "Range overflow",
            "rangeUnderflow": "Range underflow",
            "stepMismatch": "Value mismatch with step",
            "tooLong": "Value is too long",
            "tooShort": "Value is too short",
            "typeMismatch": "Value type mismatch",
            "valid": "Error",
            "valueMissing": "Missing value"
        },
        "select": {
            "fill": "Choose an option",
            "inconsistent": "Inconsistent choice"
        },
        "forms": {
            "signup": {
                "asGroup": {
                    "none_selected": "Please select an option"
                }
            }
        }
    },

    "forms": {
        "signup": {
            "prenom": {"label": "Firstname","placeholder": "Enter your firstname"},
            "nom_famille": {"label": "Lastname","placeholder": "Enter your lastname"},
            "birthdate": {"label": "Birthdate"},
            "email": {"label": "Mail","placeholder": "Enter your mail"},
            "password": {"label": "Password","placeholder": "Enter a password"},
            "confirm_password": {"label": "Confirm","placeholder": "Confirm the password"},
            "h_signup_as": "I register on Casting7 as :",
            "figurant": {"label": "Movie extra"},
            "silhouette": {"label": "Silhouette"},
            "comedien_amateur": {"label": "Amateur actor(ress)"},
            "comedien_professionnel": {"label": "Professionnal actor(ress)"},
            "mannequin_amateur": {"label": "Model - amateur photo model"},
            "mannequin_professionnel": {"label": "Model - professional photo model"},
            "vehicule": {"label": "Owner of one or more vehicles that I wish to make available for audiovisual productions"},
            "animal": {"label": "Owner of one or more animals that I wish to make available for audiovisual productions"},
            "terms": {
                "text": "I accept ",
                "link": "T&Cs"
            },
            
            "address": {"title": "Your address"},
            "street_number": {"label": "Street number"},
            "street_name": {"label": "Lane"},
            "building_number": {"label": "Building n°"},
            "building_name": {"label": "Building"},
            "zip_code": {"label": "ZIP code"},
            "city": {"label": "City"},
            "country": {"label": "Country"},
            "others": {"title": "Other informations"},
            "nationality": {"label": "Nationality"},
            "mobile": {"label": "Mobile phone"},
            "sexe": {"label": "Sexe"},
            "has_license": {"label": "Do you have one or more vehicle licences ?"},
            "has_vehicle": {"label": "Do you have one or more vehicles ?"},

            "height": {"label": "Your height (cm)"},
            "weight": {"label": "Your weight (kg)"},
            "type_physic": {"label": "Body type"},
            "hair_color": {"label": "Hair color"},
            "eyes_color": {"label": "Eyes color"},

            "langues": "Languages",
            "choose_lang": {"label": "Choose a language"},
            "choose_lang_lvl": {"label": "What is your current level ?"},
            "remove_lang": {"label": "Remove the language"},
            "add_lang": {"label": "Add a language"},

            "cancel_button": {"label": "Cancel"},
            "continuer_button": {"label": "Continue"},
            "retour_button": {"label": "Go back"},
            "creer_button": {"label": "Create my account"},
            "have_an_account": "Do you already ",
            "signin": "have an account ?"
        },
        "signin": {
            "h2": {"label": "Signin"},
            "email": {"label": "Mail address", "placeholder": ""},
            "password": {"label": "Password", "placeholder": ""},
            "remember_me": {"label": "Remember me"},
            "forgot_pwd": {"label": "Forgot password ?"},
            "form_error": "Incomplete form",
            "signin": {"label": "Signin"},
            "or_signin_with": {"label": "Or continue with"},
            "create_account": "You wanna ",
            "signup": "create an account ?"
        },
        "forgot_pwd": {
            "have_an_account": "Do you already ",
            "signin": "have an account ?"
        },
        "image_view": {
            "open_view": "Enlarge picture",
            "title": "Enlarge picture",
            "libelle": "Enlarge picture"
        }
    },

    "nav_links": {
        "home": "Home",
        "annonces": "Annoucements",
        "portfolio": "Portfolio",
        "help": "Help",
        "myspace": "My space",
        "terms": "T&Cs",
        "logout": "Logout",
        "signup": "Signup",
        "signin": "Signin",
        "forgot_pwd": "Forgot password ?"
    },

    "hero-header": {
        "catch-black": "Go behind screen,",
        "catch-pink": "feel the cinema !",
        "main-text": "Casting7 offers you the opportunity to become movie extra, silhouette, stuntman for a large panel of movies. Go behind the scenes and experience cinema from the inside.",
        "cta-signup-text": "Register now !",
        "cta-signup-button": "Register",
        "cta-movies-text": "See the movies"
    },

    "sign-in": {
        "with_google": "Signin with Google",
        "with_facebook": "Signin with Facebook",
        "with_twitter": "Signin with Twitter"
    },

    "components": {
        "input": {
            "label": {
                "default": "Field"
            },
            "placeholder": {
                "default": "Enter your text here"
            }
        },
        "checkbox": {
            "label": {
                "default": "Tick the box"
            }
        },
        "range": {
            "label": {
                "default": "Choose a value"
            }
        },
        "select": {
            "label": {
                "default": "Choose an option"
            },
            "option": {
                "label": {
                    "default": "Option {{index}}"
                }
            }
        },
        "dropdown": {
            "titre": {
                "default": "Choose an option"
            },
            "menu": {
                "label": {
                    "default": "Menu {{index}}"
                }
            }
        }
    },

    "pages": {
        "home": {
            "meta": {
                "title": "Free database of actors, silhouettes, movie extras, vehicles and animals - Belgium, Luxembourg, France, Netherlands"
            }
        },
        "annonces": {
            "meta": {
                "title": "Casting annoucements - Casting7"
            },
            "no-annonces": "No announce",
            "filter-zone": {
                "title": "Filters",
                "filter-by-sexe-toggle": {
                    "label": "Filter by sexe"
                },
                "filter-by-sexe-male": {
                    "label": "Male"
                },
                "filter-by-sexe-female": {
                    "label": "Female"
                }
            }
        },
        "myspace": {
            "general": {
                "actually-on": "Actually on",
                "actualy-on-my-profile": "My profile",
                "can-manage": "Other profiles I manage",
                "return_to_my_profile": "My profile"
            },
            "photos": {},
            "tabs": {
                "generals": "Generals",
                "activities": "Work / hobbies / sport / art",
                "artist_cv": "Artistic CV",
                "animals": "My pets",
                "vehicles": "My vehicles",
                "dressing": "Closet"
            },
            "display": {
                "generals": {
                    "generals": {
                        "label": "Generals",
                        "surname": "Lastname",
                        "firstname": "Firstname",
                        "artist_name": "Artist name",
                        "nationality": "Nationality",
                        "birthdate": "Birthdate",
                        "mobile": "Mobile phone",
                        "address": "Address",
                        "driving_licenses": "Licenses",
                        "professionnal_status": "Professionnal status"
                    },
                    "physic": {
                        "label": "Body description",
                        "physic_type": "Body type",
                        "hair_color": "Hair color",
                        "eyes_color": "Eyes color",
                        "facial_hair": "Beard or mustache",
                        "distinctive_signs": "Particular sign(s)"
                    },
                    "inscribed_as": {
                        "label": "Registered as",
                        "figuration": "Movie extra",
                        "pet owner": "Pet owner",
                        "silhouette": "Silhouette",
                        "vehicle_owner": "Vehicle owner",
                        "actor": "Comedian",
                        "create_c7_relationship": "Create a C7 relation ?"
                    },
                    "measurements": {
                        "label": "Measurements",
                        "height": "Height",
                        "weight": "Weight",
                        "pull": "Sweater or t-shirt size",
                        "pantalon": "Suit pant size",
                        "jean": "Jeans size",
                        "shoes": "Shoe size",
                        "gloves": "Gloves size",
                        "sizes": {
                            "label": "SIZES",
                            "chest": "A. Chest size (at breasts height)",
                            "waist": "B. Waist size (at navel height)",
                            "hips": "C. Hips circumference",
                            "head": "F. Head measurement",
                            "neck": "G. Collar size"
                        },
                        "lengths": {
                            "label": "LENGTHS",
                            "back_width": "Back width",
                            "shoulder_width": "4. Back build / shoulder width (back shoulder to shoulder)",
                            "arm_length": "6. + 7. Arm length (from shoulder to wrist)",
                            "arm_to_wrist": "8. Arm outstretched, shoulder to wrist",
                            "waist_to_floor": "9. From waist to floor (leg length)",
                            "crotch_to_floor": "10. From crotch to floor"
                        }
                    },
                    "languages": {
                        "label": "Languages",
                        "mother_tongue": "Native language"
                    }
                },
                "activities": {
                    "profession": {
                        "label": "Profession",
                        "actual": "Actual profession",
                        "past": "Past professionnal experiences"
                    },
                    "hobbies": {
                        "label": "Hobbies"
                    },
                    "sports": {
                        "label": "Sports"
                    },
                    "arts": {
                        "label": "Artistic activities"
                    }
                }
            }
        }
    },

    "signup_modale": {
        "steps": {
            "generals": {
                "label": "Generals",
                "descr": "",
                "short": "General"
            },
            "administrative": {
                "label": "Administrative informations",
                "descr": "",
                "short": "Administrative"
            },
            "physical": {
                "label": "Personnal characteristics",
                "descr": "",
                "short": "Personnam"
            }
        }
    },

    "gender": {
        "male": {"label": "Male"},
        "female": {"label": "Female"}
    }

}