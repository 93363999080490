if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
    const runtime = require('react-refresh/runtime');
    runtime.injectIntoGlobalHook(window);
    window.$RefreshReg$ = () => {};
    window.$RefreshSig$ = () => type => type;
}

import React from "react";
import { createRoot } from "react-dom/client";

import store from './app/store';
import { Provider } from 'react-redux';

import App from "./App.js";
import './i18n';
import __signin__ from "./components/functions/signin";

__signin__(null, "refresh");

const app = document.getElementById("app");
const root = createRoot(app);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);
