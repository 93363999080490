import React from 'react';
import { useTranslation } from 'react-i18next';
import { __backURLs } from '../functions/const';
import AnnoncesElem from './annonces-elem';
import { Entity_Annonce } from '../interfaces/entities';

import "./annonces-list.css";
import { hot } from 'react-hot-loader';
import LoadingGIF from '../Basics/LoadingGIF';

const AnnoncesList = ({key, annonces, loading}:AnnoncesList = {}) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {

    }, [annonces]);
    
    return (
        <div id="annonces-list" className='custom-scrollbar'>
            {loading && <div className="loading-mask"><LoadingGIF/></div>}
            {!loading && annonces && annonces.map((annonce:any, index:number) => <AnnoncesElem key={index} annonce_key={key} annonce={annonce}/>)}
            {!loading && annonces && !annonces.length && <div className="no-annonces">{t('pages->annonces->no-annonces')}</div>}
        </div>
    );
};

interface AnnoncesList {
    key?: any;
    annonces?: Entity_Annonce[];
    loading?: boolean;
};

export default hot(module)(AnnoncesList);