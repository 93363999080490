import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';

import TextualInput from '../../Basics/Input/text-input';
import CheckboxInput from '../../Basics/Input/checkbox-input';
import Link from '../../Link';
import Button from '../../Basics/Button/index';
import { __modales } from '..';
import { urls } from '../../const';
import { useDispatch } from 'react-redux';
import { setModale } from '../../../features/page/pageSlice';
import Divider from '../../Basics/Divider';
import { __signup_elements, __signup_etapes } from '../const';
import { signupCheckGeneral } from '../../functions/signup';

const SignupGeneral = (__datas:SignupGeneral = Object()) => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <>
            {/* Prénom */}
            <TextualInput required={true} formFor={__modales[__datas.modale].formId} classes={["sm:col-span-4"]} type="text" fieldName="prenom" label="forms->signup->prenom->label" placeholder="forms->signup->prenom->placeholder" autoComplete="prenom" labelClasses={["block text-sm font-medium text-gray-700"]} inputClasses={["mt-1"]}/>
            {/* Nom */}
            <TextualInput required={true} formFor={__modales[__datas.modale].formId} classes={["sm:col-span-4"]} type="text" fieldName="nom_famille" label="forms->signup->nom_famille->label" placeholder="forms->signup->nom_famille->placeholder" autoComplete="nom_famille" labelClasses={["block text-sm font-medium text-gray-700"]} inputClasses={["mt-1"]}/>
            {/* Date de naissance */}
            <TextualInput required={true} formFor={__modales[__datas.modale].formId} classes={["sm:col-span-4"]} type="date" fieldName="birthdate" label="forms->signup->birthdate->label" autoComplete="birthdate" labelClasses={["block text-sm font-medium text-gray-700"]} inputClasses={["mt-1"]}/>
            {/* Mail */}
            <TextualInput required={true} formFor={__modales[__datas.modale].formId} classes={["sm:col-span-4 my-3"]} type="email" fieldName="email" label="forms->signup->email->label" placeholder="forms->signup->email->placeholder" autoComplete="email" labelClasses={["block text-sm font-medium text-gray-700"]} inputClasses={["mt-1"]}/>
            {/* Mot de passe */}
            <TextualInput required={true} formFor={__modales[__datas.modale].formId} classes={["sm:col-span-4 my-3"]} type="password" fieldName="password" label="forms->signup->password->label" placeholder="forms->signup->password->placeholder" autoComplete="password" labelClasses={["block text-sm font-medium text-gray-700"]} inputClasses={["mt-1"]}/>
            {/* Confirmer mot de passe */}
            <TextualInput required={true} formFor={__modales[__datas.modale].formId} classes={["sm:col-span-4 my-3"]} type="password" fieldName="confirm_password" label="forms->signup->confirm_password->label" placeholder="forms->signup->confirm_password->placeholder" autoComplete="confirm_password" labelClasses={["block text-sm font-medium text-gray-700"]} inputClasses={["mt-1"]}/>
            {/* Rôles */}
            <h3 className="sm:col-span-6 text-pink-900 font-bold mt-4">{t("forms->signup->h_signup_as")}</h3>
            <span className={`sm:col-span-6 sign_as_checkboxGroup_status inline-block whitespace-pre mt-4 not-focusable`}></span>
            <CheckboxInput classes={["sm:col-span-6 mt-0 sign_as_checkboxGroup"]} formFor={__modales[__datas.modale].formId} fieldName="as_figurant" label="forms->signup->figurant->label"/>
            <CheckboxInput classes={["sm:col-span-6 mt-0 sign_as_checkboxGroup"]} formFor={__modales[__datas.modale].formId} fieldName="as_silhouette" label="forms->signup->silhouette->label"/>
            <CheckboxInput classes={["sm:col-span-6 mt-0 sign_as_checkboxGroup"]} formFor={__modales[__datas.modale].formId} fieldName="as_comedien_amateur" label="forms->signup->comedien_amateur->label"/>
            <CheckboxInput classes={["sm:col-span-6 mt-0 sign_as_checkboxGroup"]} formFor={__modales[__datas.modale].formId} fieldName="as_comedien_professionnel" label="forms->signup->comedien_professionnel->label"/>
            <CheckboxInput classes={["sm:col-span-6 mt-0 sign_as_checkboxGroup"]} formFor={__modales[__datas.modale].formId} fieldName="as_mannequin_amateur" label="forms->signup->mannequin_amateur->label"/>
            <CheckboxInput classes={["sm:col-span-6 mt-0 sign_as_checkboxGroup"]} formFor={__modales[__datas.modale].formId} fieldName="as_mannequin_professionnel" label="forms->signup->mannequin_professionnel->label"/>
            <CheckboxInput classes={["sm:col-span-6 mt-0 sign_as_checkboxGroup"]} formFor={__modales[__datas.modale].formId} fieldName="as_vehicule" label="forms->signup->vehicule->label"/>
            <CheckboxInput classes={["sm:col-span-6 mt-0 sign_as_checkboxGroup"]} formFor={__modales[__datas.modale].formId} fieldName="as_animal" label="forms->signup->animal->label"/>
            <Divider type="simple" classes={["sm:col-span-12 mt-2 w-1/2 left-1/4"]}/>
            {/* Conditions générales */}
            <CheckboxInput
                checked={false}
                formFor={__modales[__datas.modale].formId}
                required={true}
                classes={["mt-2 sm:col-span-12"]}
                fieldName="terms"
                label="forms->signup->terms->text"
                >
                <Link {...{...urls.terms, title: "forms->signup->terms->link", target:"_blank", libelle: "forms->signup->terms->link", classes: ["text-pink-800", "hover:text-pink-900", "hover:font-bold"]}}/>
            </CheckboxInput>
            <span className="sm:col-span-2 mt-2"></span>
            <Button classes={["sm:col-span-4 mt-2 block text-center"]} onClick={() => dispatch(setModale(null))}>{t("forms->signup->cancel_button->label")}</Button>
            <Button classes={["sm:col-span-4 mt-2 block text-center"]} onClick={() => signupCheckGeneral(__datas.modale, true) && __datas.checkStepValidity(__datas.step, __datas.modale, true) && __datas.setCurrentStep((__datas.step || 0) + 1)}>{t("forms->signup->continuer_button->label")}</Button>
            {/* <span className="sm:col-span-2 mt-2"></span> */}
        </>
    );
};

interface SignupGeneral {
    key?: any;
    checkStepValidity: Function;
    setCurrentStep: Function;
    currentStep?: number;
    step: number;
    modale: string;
};

export default hot(module)(SignupGeneral);