import { hot } from 'react-hot-loader';

import React from "react";

import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';
import { _log, _trace } from '../../functions/const';
import { ReduxStore } from '../../interfaces/redux';
import { __modales } from '../index';

const ModaleImageViewContent = (__datas:ModaleImageViewContent = {}) => {

    const modale = useSelector((store:ReduxStore) => store.page.activeModale);
    const specific_env = useSelector((state:ReduxStore) => state.page.modaleSpecificEnv);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
        console.log(specific_env);
    }, []);

    React.useEffect(() => {
        
    }, [i18n.language]);

    if(modale == null) {
        return <></>;
    }

    return (
        <>
            <form id={__modales[modale].formId} name="image_view-modale" className="space-y-8" key={__datas.key}
                onSubmit={(e) => {
                    e.preventDefault();
                    console.log(e);
                }}
            >
                Image
            </form>
        </>
    );
};

interface ModaleImageViewContent {
    key?: any;
};

export default hot(module)(ModaleImageViewContent);