{
    "lng_de": "Deutsch",
    "lng_en": "English",
    "lng_f": "Français",
    "lng_lu": "Luxemburgisch",
    "lng_nl": "Nederlands",
    "lng_de_LU": "Deutsch",
    "lng_en_EN": "English",
    "lng_fr_BE": "Français",
    "lng_fr_CH": "Français",
    "lng_fr_FR": "Français",
    "lng_fr_LU": "Français",
    "lng_lu_LU": "Luxemburgisch",
    "lng_nl_BE": "Hollandsk",
    "lng_nl_NL": "Hollandsk",
    "country_BE": "Belgique",
    "country_CH": "Suisse",
    "country_EN": "Angleterre",
    "country_FR": "France",
    "country_LU": "Luxembourg",
    "country_NL": "Pays-Bas",

    "errors": {
        "input_text": {
            "badInput": "Valeur incorrecte",
            "patternMismatch": "La valeur ne correspond pas au format",
            "rangeOverflow": "Valeur trop élevée",
            "rangeUnderflow": "Valeur trop basse",
            "stepMismatch": "Valeur non cohérente avec le pas",
            "tooLong": "Valeur trop longue",
            "tooShort": "Valeur trop courte",
            "typeMismatch": "Valeur incohérente avec le type attendu",
            "valid": "Erreur",
            "valueMissing": "Valeur manquante"
        },
        "input_email": {
            "badInput": "Valeur incorrecte",
            "patternMismatch": "La valeur ne correspond pas au format",
            "rangeOverflow": "Valeur trop élevée",
            "rangeUnderflow": "Valeur trop basse",
            "stepMismatch": "Valeur non cohérente avec le pas",
            "tooLong": "Valeur trop longue",
            "tooShort": "Valeur trop courte",
            "typeMismatch": "Valeur incohérente avec le type attendu",
            "valid": "Erreur",
            "valueMissing": "Valeur manquante"
        },
        "input_date": {
            "badInput": "Valeur incorrecte",
            "patternMismatch": "La valeur ne correspond pas au format",
            "rangeOverflow": "Valeur trop élevée",
            "rangeUnderflow": "Valeur trop basse",
            "stepMismatch": "Valeur non cohérente avec le pas",
            "tooLong": "Valeur trop longue",
            "tooShort": "Valeur trop courte",
            "typeMismatch": "Valeur incohérente avec le type attendu",
            "valid": "Erreur",
            "valueMissing": "Valeur manquante"
        },
        "input_checkbox": {
            "badInput": "Valeur incorrecte",
            "patternMismatch": "La valeur ne correspond pas au format",
            "rangeOverflow": "Valeur trop élevée",
            "rangeUnderflow": "Valeur trop basse",
            "stepMismatch": "Valeur non cohérente avec le pas",
            "tooLong": "Valeur trop longue",
            "tooShort": "Valeur trop courte",
            "typeMismatch": "Valeur incohérente avec le type attendu",
            "valid": "Erreur",
            "valueMissing": "Vous devez cocher cette case pour continuer"
        },
        "input_radio": {
            "badInput": "Valeur incorrecte",
            "patternMismatch": "La valeur ne correspond pas au format",
            "rangeOverflow": "Valeur trop élevée",
            "rangeUnderflow": "Valeur trop basse",
            "stepMismatch": "Valeur non cohérente avec le pas",
            "tooLong": "Valeur trop longue",
            "tooShort": "Valeur trop courte",
            "typeMismatch": "Valeur incohérente avec le type attendu",
            "valid": "Erreur",
            "valueMissing": "Valeur manquante"
        },
        "input_number": {
            "badInput": "Valeur incorrecte",
            "patternMismatch": "La valeur ne correspond pas au format",
            "rangeOverflow": "Valeur trop élevée",
            "rangeUnderflow": "Valeur trop basse",
            "stepMismatch": "Valeur non cohérente avec le pas",
            "tooLong": "Valeur trop longue",
            "tooShort": "Valeur trop courte",
            "typeMismatch": "Valeur incohérente avec le type attendu",
            "valid": "Erreur",
            "valueMissing": "Valeur manquante"
        },
        "input_password": {
            "badInput": "Valeur incorrecte",
            "patternMismatch": "La valeur ne correspond pas au format",
            "rangeOverflow": "Valeur trop élevée",
            "rangeUnderflow": "Valeur trop basse",
            "stepMismatch": "Valeur non cohérente avec le pas",
            "tooLong": "Valeur trop longue",
            "tooShort": "Valeur trop courte",
            "typeMismatch": "Valeur incohérente avec le type attendu",
            "valid": "Erreur",
            "valueMissing": "Valeur manquante"
        },
        "select": {
            "fill": "Choisir une option",
            "inconsistent": "Choix incohérent"
        },
        "forms": {
            "signup": {
                "asGroup": {
                    "none_selected": "Veuillez sélectionner au moins une option"
                }
            }
        }
    },

    "forms": {
        "signup": {
            "prenom": {"label": "Prénom","placeholder": "Entrez votre prénom"},
            "nom_famille": {"label": "Nom","placeholder": "Entrez votre nom"},
            "birthdate": {"label": "Date de naissance"},
            "email": {"label": "Adresse mail","placeholder": "Entrez votre mail"},
            "password": {"label": "Mot de passe","placeholder": "Entrez un mot de passe"},
            "confirm_password": {"label": "Confirmation","placeholder": "Confirmez le mot de passe"},
            "h_signup_as": "Je m'inscris sur Casting7 en tant que :",
            "figurant": {"label": "Figurant(e)"},
            "silhouette": {"label": "Silhouette"},
            "comedien_amateur": {"label": "Comédien(ne) amateur(e)"},
            "comedien_professionnel": {"label": "Comédien(ne) professionnel(le)"},
            "mannequin_amateur": {"label": "Mannequin - modèle de photo amateur(e)"},
            "mannequin_professionnel": {"label": "Mannequin - modèle de photo professionnel(le)"},
            "vehicule": {"label": "Propriétaire d'un véhicule ou plus que je désire mettre à disposition de productions audiovisuelles"},
            "animal": {"label": "Propriétaire d'un animal ou plus que je désire mettre à disposition de productions audiovisuelles"},
            "terms": {
                "text": "J'accepte les ",
                "link": "conditions générales"
            },
            
            "address": {"title": "Votre adresse"},
            "street_number": {"label": "N° de voie"},
            "street_name": {"label": "Voie"},
            "building_number": {"label": "N° bâtiment"},
            "building_name": {"label": "Bâtiment"},
            "zip_code": {"label": "Code postal"},
            "city": {"label": "Ville"},
            "country": {"label": "Pays"},
            "others": {"title": "Autres informations"},
            "nationality": {"label": "Nationalité"},
            "mobile": {"label": "Numéro de mobile"},
            "sexe": {"label": "Sexe"},
            "has_license": {"label": "Possédez-vous un permis ou plus ?"},
            "has_vehicle": {"label": "Possédez-vous un véhicule ou plus ?"},

            "height": {"label": "Votre taille (en cm)"},
            "weight": {"label": "Votre poids (en kg)"},
            "type_physic": {"label": "Type de physique"},
            "hair_color": {"label": "Couleur des cheveux"},
            "eyes_color": {"label": "Couleur des yeux"},

            "langues": "Langues",
            "choose_lang": {"label": "Choisir une langue"},
            "choose_lang_lvl": {"label": "Quel est votre niveau ?"},
            "remove_lang": {"label": "Supprimer la langue"},
            "add_lang": {"label": "Ajouter une langue"},

            "cancel_button": {"label": "Annuler"},
            "continuer_button": {"label": "Continuer"},
            "retour_button": {"label": "Retour"},
            "creer_button": {"label": "Créer mon compte"},
            "have_an_account": "Vous avez ",
            "signin": "déjà un compte ?"
        },
        "signin": {
            "h2": {"label": "Connexion"},
            "email": {"label": "Adresse mail", "placeholder": ""},
            "password": {"label": "Mot de passe", "placeholder": ""},
            "remember_me": {"label": "Se souvenir de moi"},
            "forgot_pwd": {"label": "Mot de passe oublié ?"},
            "form_error": "Formulaire incomplet",
            "signin": {"label": "Connexion"},
            "or_signin_with": {"label": "Ou continuer avec"},
            "create_account": "Vous souhaitez ",
            "signup": "créer un compte ?"
        },
        "forgot_pwd": {
            "have_an_account": "Vous avez ",
            "signin": "déjà un compte ?"
        },
        "image_view": {
            "open_view": "Agrandir l'image",
            "title": "Agrandir l'image",
            "libelle": "Agrandir l'image"
        },
        "annonce_resolve_entity": {
            "title-animal": "Sélectionner un ou plusieurs animaux",
            "title-animal-human": "Sélectionner les profils et les animaux qui candidatent",
            "title-vehicle": "Sélectionner un ou plusieurs véhicules",
            "title-vehicle-human": "Sélectionner les profils et les véhicules qui candidatent",
            "title-animal-vehicle": "Sélectionner un ou plusieurs véhicules ou animaux",
            "title-all": "Sélectionner les profils, les animaux et les véhiculent qui candidatent"
        }
    },

    "nav_links": {
        "home": "Accueil",
        "404": "404",
        "annonces": "Annonces",
        "c7plus": "Casting7 +",
        "annonce": "Annonce",
        "portfolio": "Portfolio",
        "help": "Aide",
        "myspace": "Mon Espace",
        "terms": "Termes et conditions",
        "logout": "Déconnexion",
        "signup": "S'inscrire",
        "signin": "Se connecter",
        "forgot_pwd": "Mot de passe oublié ?",
        "annonce_resolve_entity": "Candidatures"
    },

    "hero-header": {
        "catch-black": "Passez derrière l'écran,",
        "catch-pink": "vivez le cinéma !",
        "main-text": "Casting7 vous offre l'opportunité de devenir figurant, silhouette, cascadeur pour un large éventail de films. Découvrez l'envers du décor et vivez le cinéma de l'intérieur.",
        "cta-signup-text": "Inscrivez-vous dès maintenant !",
        "cta-signup-button": "S'inscrire",
        "cta-movies-text": "Voir les films"
    },

    "sign-in": {
        "with_google": "Se connecter avec Google",
        "with_facebook": "Se connecter avec Facebook",
        "with_twitter": "Se connecter avec Twitter"
    },

    "components": {
        "input": {
            "label": {
                "default": "Champ"
            },
            "placeholder": {
                "default": "Entrez votre texte ici"
            }
        },
        "checkbox": {
            "label": {
                "default": "Cocher la case"
            }
        },
        "range": {
            "label": {
                "default": "Choisir une valeur"
            }
        },
        "select": {
            "label": {
                "default": "Choisir une option"
            },
            "option": {
                "label": {
                    "default": "Option {{index}}"
                }
            }
        },
        "dropdown": {
            "titre": {
                "default": "Choisir une option"
            },
            "menu": {
                "label": {
                    "default": "Menu {{index}}"
                }
            }
        }
    },

    "pages": {
        "home": {
            "meta": {
                "title": "Banque de données gratuite de comédiens, silhouettes, figurants, véhicule et animaux - Belgique, Luxembourg, France, Pays-Bas"
            }
        },
        "404": {
            "not_exist_or_removed": "Cette page n'existe pas ou plus"
        },
        "annonces": {
            "meta": {
                "title": "Annonces de casting - Casting7"
            },
            "no-annonces": "Aucune annonce",
            "filter-zone": {
                "title": "Filtres",
                "filter-by-sexe-toggle": {
                    "label": "Filtrer par sexe"
                },
                "filter-by-sexe-male": {
                    "label": "Homme"
                },
                "filter-by-sexe-female": {
                    "label": "Femme"
                },
                "filter-by-animals": {
                    "label": "Animaux"
                },
                "filter-by-vehicles": {
                    "label": "Véhicules"
                },
                "filter-by-age": {
                    "label": "Filtrer par age"
                },
                "filter-by-ethnie": {
                    "label": "Filtrer par ethnie"
                },
                "filter-by-oldage-toggle": {
                    "label": "Film d'époque"
                },
                "filter-by-current-toggle": {
                    "label": "Film contemporain"
                },
                "filter-by-genre": {
                    "label": "Genre de film"
                },
                "filter-by-zip": {
                    "label": "Code postal"
                },
                "filter-by-distance": {
                    "label": "Distance"
                },
                "filter-by-date": {
                    "label": "Date"
                }
            },
            "annonce": {
                "link": {
                    "title": "Voir l'annonce ({{title}})"
                }
            },
            "infos": {
                "details": {
                    "icon": {
                        "type": "Mission",
                        "project": "Projet",
                        "gender": "Genre(s)",
                        "gender-male": "homme",
                        "gender-female": "femme",
                        "animals": "Animaux",
                        "vehicles": "Véhicules",
                        "money": "Rémunération",
                        "age": "Tranche d'âge",
                        "statut": "Statut",
                        "land": "Pays",
                        "location": "Adresse",
                        "calendar": "Date(s)"
                    },
                    "src": {
                        "gender-male": "homme",
                        "gender-female": "femme",
                        "animals": "animaux",
                        "vehicles": "véhicules",
                        "age-min-max": "{{min}} - {{max}} ans",
                        "age-indistinct": "Indifférent",
                        "age-min": "à partir de {{min}} ans",
                        "age-max": "jusqu'à {{max}} ans",
                        "statut-opened": "Ouverte",
                        "statut-reopened": "Ré-ouverte",
                        "statut-last_minute": "Last minute"
                    }
                },
                "abstract": {
                    "no_descr": "Aucune description"
                }
            },
            "projects-zone": {
                "title": "Projets",
                "no-projects": "Aucun projet"
            }
        },
        "annonce": {
            "bandeau": {
                "candidat": "Poser sa candidature",
                "candidat-remove": "Retirer sa candidature",
                "candidat-booked": "Booké(e)",
                "candidat-selected": "Sélectionné(e)",
                "candidat-invited": "Invité(e)",
                "candidat-candidat": "Candidat(e)",
                "candidat-abandonned": "Abandonné(e)",
                "candidat-excluded": "Exclu(e)",
                "candidat-missing_infos": "Infos manquantes",
                "candidat-manage": "Gérer les candidatures",
                "complete": "Compléter mon profil",
                "complete-all": "Gérer les profils"
            },
            "infos": {
                "details": {
                    "title": "Info",
                    "icon": {
                        "type": "Type de casting",
                        "project": "Projet",
                        "gender": "Sexe",
                        "gender-male": "homme",
                        "gender-female": "femme",
                        "animals": "Animaux",
                        "vehicles": "Véhicules",
                        "money": "Montant/défraiement (par jour/prestation)",
                        "age": "Âge",
                        "statut": "Statut des candidatures",
                        "land": "Pays",
                        "location": "Adresse",
                        "calendar": "Date(s)"
                    },
                    "src": {
                        "gender-male": "homme",
                        "gender-female": "femme",
                        "animals": "animaux",
                        "vehicles": "véhicules",
                        "age-min-max": "de {{min}} an(s) à {{max}} ans",
                        "age-indistinct": "Indifférent",
                        "age-min": "à partir de {{min}} ans",
                        "age-max": "jusqu'à {{max}} ans",
                        "statut-opened": "La sélection des candidats à cette annonce est en cours, il est possible de proposer votre candidature.",
                        "statut-reopened": "La sélection des candidats à cette annonce est en cours, il est possible de proposer votre candidature.",
                        "statut-last_minute": "La sélection des candidats à cette annonce est en cours, il est possible de proposer votre candidature."
                    }
                },
                "practicals": {
                    "title": "Date(s) et lieu(s)",
                    "time": {
                        "title": "Date(s) :"
                    },
                    "location": {
                        "title": "Lieu(x) de tournage :",
                        "no-lieu": "Aucune adresse"
                    },
                    "liaison": {
                        "title": "Raccord :"
                    }
                }
            },
            "details": {
                "annonce_descr": "Description de l'annonce",
                "no_descr": "Aucune description",
                "missing_informations": "Cette annonce requiert ces informations :",
                "no_missing": "Vous avez fourni toutes les informations nécessaires pour candidater",
                "annonce_liees": "Annonces similaires susceptibles de vous intéresser aussi"
            }
        },
        "myspace": {
            "general": {
                "actually-on": "Actuellement sur",
                "actualy-on-my-profile": "Mon profil",
                "can-manage": "Autres profils que je gère",
                "return_to_my_profile": "Mon profil"
            },
            "photos": {},
            "tabs": {
                "generals": "Informations Générales",
                "activities": "Profession / hobbies / sport / art",
                "artist_cv": "CV artistique",
                "animals": "Mes animaux",
                "vehicles": "Mes véhicules",
                "dressing": "Vestaire"
            },
            "display": {
                "generals": {
                    "generals": {
                        "label": "Informations générales",
                        "surname": "Nom",
                        "firstname": "Prénom",
                        "artist_name": "Nom d'artiste",
                        "nationality": "Nationalité",
                        "birthdate": "Naissance",
                        "mobile": "Téléphone portable",
                        "address": "Adresse",
                        "driving_licenses": "Permis",
                        "professionnal_status": "Situation professionnelle"
                    },
                    "physic": {
                        "label": "Description Physique",
                        "physic_type": "Physique de type",
                        "hair_color": "Couleur de cheveux",
                        "eyes_color": "Couleur des yeux",
                        "facial_hair": "Barbe ou moustache",
                        "distinctive_signs": "Signe(s) particulier(s)"
                    },
                    "inscribed_as": {
                        "label": "Inscrit comme",
                        "figuration": "Figuration",
                        "pet owner": "Propriétaire animal",
                        "silhouette": "Silhouette",
                        "vehicle_owner": "Propriétaire véhicule",
                        "actor": "Comédien",
                        "create_c7_relationship": "Créer une relation C7 ?"
                    },
                    "measurements": {
                        "label": "Mensurations",
                        "height": "Taille",
                        "weight": "Poids",
                        "pull": "Taille de pull ou de t-shirt",
                        "pantalon": "Taille de pantalon costume",
                        "jean": "Taille de jeans",
                        "shoes": "Pointure de chaussure",
                        "gloves": "Taille de gants",
                        "sizes": {
                            "label": "TOURS",
                            "chest": "A. Tour de poitrine (à la hauteur des seins)",
                            "waist": "B. Tour de taille (à la hauteur du nombril)",
                            "hips": "C. Tour de hanches",
                            "head": "F. Tour de tête",
                            "neck": "G. Tour de cou"
                        },
                        "lengths": {
                            "label": "LONGUEURS",
                            "back_width": "Largeur dos",
                            "shoulder_width": "4. Carrure dos / largeur d'épaules (d'épaule à épaule de dos)",
                            "arm_length": "6. + 7. Longueur bras (de l'épaule au poignet)",
                            "arm_to_wrist": "8. Bras tendu, épaule au poignet",
                            "waist_to_floor": "9. De la taille au sol (longueur des jambes)",
                            "crotch_to_floor": "10. De l'entre-jambes au sol"
                        }
                    },
                    "languages": {
                        "label": "Langues",
                        "mother_tongue": "Langue maternelle"
                    }
                },
                "activities": {
                    "profession": {
                        "label": "Profession",
                        "actual": "Profession actuelle",
                        "past": "Expériences professionnelles passées"
                    },
                    "hobbies": {
                        "label": "Hobbies"
                    },
                    "sports": {
                        "label": "Sport"
                    },
                    "arts": {
                        "label": "Activité artistique"
                    }
                }
            }
        }
    },

    "signup_modale": {
        "steps": {
            "generals": {
                "label": "Informations générales",
                "descr": "",
                "short": "Général"
            },
            "administrative": {
                "label": "Informations administratives",
                "descr": "",
                "short": "Administratif"
            },
            "physical": {
                "label": "Caractéristiques personnelles",
                "descr": "",
                "short": "Personnel"
            }
        }
    },

    "gender": {
        "male": {"label": "Homme"},
        "female": {"label": "Femme"}
    }

}