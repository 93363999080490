import React from "react";

import "./index.css";

const LoadingGIF = (_params:LoadingGIF) => {
    return (
        <div id={_params.id || undefined} className={["loading-gif", ..._params.classes ?? []].join(' ')}>
            <div className="loader-circle"></div>
            <div className="loader-bar"></div>
        </div>
    );
};

interface LoadingGIF {
    id?: string;
    classes?: string[];
};

export default LoadingGIF;