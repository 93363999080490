import { hot } from 'react-hot-loader';

import React from "react";

import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';
import StepperProgressBar from '../../Stepper/progress-bar';
import Link from '../../Link';
import { urls } from '../../const';
import Divider from '../../Basics/Divider';
import { _log, _trace } from '../../functions/const';
import { isPressed } from '../../functions/tools';
import { ReduxStore } from '../../interfaces/redux';
import { __modales } from '../index';
import { __signup_elements, __signup_etapes } from '../const';
import SignupGeneral from './SignupGeneral';
import SignupAdministratif from './SignupAdministratif';
import SignupPersonnal from './SignupPersonnal';
import { checkStepValidity } from '../functions';

const ModaleSignupContent = (__datas:ModaleSignupContent = {}) => {

    const modale = useSelector((state:ReduxStore) => state.page.activeModale);
    const { t, i18n } = useTranslation();

    const [currentStep, setCurrentStep]:[number, React.Dispatch<number>] = React.useState(0);

    const signupStep = function(__step:number|null = null) {
        if(modale == null) {return <></>;}
        let _i = 0;
        return (
            <>
                {(__step == _i++ || __step == null) && <SignupGeneral currentStep={currentStep} checkStepValidity={checkStepValidity} setCurrentStep={setCurrentStep} step={__step || 0} modale={modale}/>}
                {__step == _i++ && <SignupAdministratif currentStep={currentStep} checkStepValidity={checkStepValidity} setCurrentStep={setCurrentStep} step={__step} modale={modale}/>}
                {__step == _i++ && <SignupPersonnal currentStep={currentStep} setCurrentStep={setCurrentStep} step={__step} modale={modale}/>}
            </>
        );
    }

    React.useEffect(() => {
        if(modale == __modales.signup.modaleId) {
            initSelectNone();
            // EXPLAIN - Bloque le 'Tab' si la navigation fait passer au champ suivant
            document?.addEventListener('keydown', (e) => {
                if(isPressed('Tab', e) && document?.activeElement?.nextElementSibling == null && (():any => document?.activeElement)()?.type == "button") {
                    e.preventDefault();
                }
            }, {capture: true});
            document?.forms[__modales[modale].formId]?.addEventListener("submit", (e) => {
                e.preventDefault();
                console.log("%cSubmit useEffect", "background:black;font-size:18px;padding: 3px 7px;font-weight:bold;color:green;");
                console.log(e);
            }, {capture: true});
        }
    }, []);

    React.useEffect(() => {
        // console.log("%cTrigger de 'currentStep' : " + currentStep, "background:black;font-size:18px;padding: 3px 7px;font-weight:bold;color:purple;");
        // const _temp = selectOne(`#${__modales[modale || "signup"].formId} #${__signup_elements[currentStep][0]}`);
        // console.log(_temp);
        // _temp?.focus();
    }, [currentStep]);

    React.useEffect(() => {
        
    }, [i18n.language, modale]);

    if(modale == null) {
        return <></>
    }

    return (
        <>
            <form id={__modales[modale].formId} name="signup-modale" className="space-y-8 overflow-hidden" style={{maxHeight: "calc(100vh - 10rem)"}} key={__datas.key}
                onSubmit={(e) => {
                    e.preventDefault();
                    console.log("%cForm submit", "background:black;font-size:18px;padding: 3px 7px;font-weight:bold;color:blue;");
                    checkStepValidity(currentStep - 1, modale, true);
                }}
            >
                <StepperProgressBar
                    stepperId="signup-stepper"
                    steps={__signup_etapes.map(etape => {return {slug: etape, label: `signup_modale->steps->${etape}->label`, short: `signup_modale->steps->${etape}->short`};})}
                    currentStep={currentStep}
                />
                <div
                    id="steps"
                    className="whitespace-nowrap grid relative"
                    style={{
                        transition: "left 0.3s ease-in-out 0s",
                        // gridAutoRows: "1fr",
                        width: `${__signup_etapes.length * 100}%`,
                        left: `${(0 - currentStep) * 100}%`,
                        gridTemplateColumns: `repeat(${__signup_etapes.length}, 1fr)`,
                        maxHeight: "calc(100vh - (10rem + 130px))"
                    }}
                >
                    {__signup_etapes.map((etape, key) => {
                        return (
                            <div
                                key={key}
                                id={`stepper-part_${etape}`}
                                className="relative top-0 bottom-0 w-full h-full max-h-screen bg-pink custom-scrollbar grid grid-cols-12 gap-y-1 gap-x-3 auto-rows-min p-2 rounded"
                                style={{
                                    maxHeight: currentStep == key ? "calc(100vh - (10rem + 130px))" : "",
                                    transition: currentStep == key ? "max-height 0s ease-in-out 0.3s" : "max-height 0s ease-in-out 0s"
                                }}
                                // style={{maxHeight: "calc(100vh - 200px)", zIndex: currentStep == key ? 10 : 0, order: currentStep == key ? 1 : 2}}
                                //style={{right: `${(currentStep == key ? key : (currentStep > key ? 0 - (key + 1) : key - 1)) * 100}%`}}
                            >
                                {signupStep(key)}
                            </div>
                        );
                    })}
                </div>
            </form>
            <Divider classes={["block", "my-2"]}/>
            <div className="block w-full text-center pt-4">
                <span>{t("forms->signup->have_an_account")}</span>
                <Link {...{...urls.signin, title: "forms->signup->signin", libelle: "forms->signup->signin", classes: ["text-pink-800", "hover:text-pink-900", "hover:font-bold"]}}>{t("forms->signup->signin")}</Link>
            </div>
        </>
    );
};

interface ModaleSignupContent {
    key?: any;
};

export default hot(module)(ModaleSignupContent);