/**
 * @file Describe the basic tools and values needed further
*/

export const DEV_MODE = window.location.hostname == "localhost" || window.location.hostname == "127.0.0.1" ? true : false;
export const STAGE_MODE = DEV_MODE == false && window.location.hostname == "c7front.emeka.fr" ? true : false;
export const PROD_MODE = DEV_MODE == false && STAGE_MODE == false ? true : false;
export const current_page = typeof document != "undefined" ? document?.querySelector('body')?.getAttribute('data-page') ?? document?.location?.pathname : undefined;
export const locale = document?.querySelector('html')?.getAttribute('locale');

export const _log = function(string, force:string|boolean|null = false) {
    (DEV_MODE || force || _mode_force(force)) && console.log(string);
}

export const _warn = function(string, force:string|boolean|null = false) {
    (DEV_MODE || force || _mode_force(force)) && console.warn(string);
}

export const _trace = function(string, force:string|boolean|null = false) {
    (DEV_MODE || force || _mode_force(force)) && console.trace(string);
}

export const _error = function(string, force:string|boolean|null = false) {
    (DEV_MODE || force || _mode_force(force)) && console.error(string);
}

export const _alert = function(string, force:string|boolean|null = false) {
    (DEV_MODE || force || _mode_force(force)) && window.alert(string);
}

export const _slog = function(string, style = '', force:string|boolean|null = false) {
    (DEV_MODE || force || _mode_force(force)) && console.log("%c" + string, style);
}

export const _swarn = function(string, style = '', force:string|boolean|null = false) {
    (DEV_MODE || force || _mode_force(force)) && console.warn("%c" + string, style);
}

export const _strace = function(string, style = '', force:string|boolean|null = false) {
    (DEV_MODE || force || _mode_force(force)) && console.trace("%c" + string, style);
}

export const _serror = function(string, style = '', force:string|boolean|null = false) {
    (DEV_MODE || force || _mode_force(force)) && console.error("%c" + string, style);
}

export const _mode_force = function(_mode:string|boolean|null = null) {
    if(typeof _mode == "string" && _mode.length > 0) {
        switch(_mode) {
            case "dev": return DEV_MODE == true;  
            case "stage": return STAGE_MODE == true;  
            case "prod": return PROD_MODE == true;  
            default: return false;
        }
    }
    return false;
}

export const __backURLs = {
    // SOCKET
    sio_base: {url: '/sio'},
    sio_booking: {url: '/sio/booking_manager'},
    sio_attribution: {url: '/sio/attribution_manager'},
    sio_jaj: {url: '/sio/jaj_manager'},
    sio_get_jaj: {url: '/sio/get-jaj'},
    sio_baj: {url: '/sio/baj_manager'},
    sio_room: {url: '/sio/room'},
    sio_rooms: {url: '/sio/rooms'},
    sio_join_room: {url: '/sio/rooms/join'},
    sio_leave_room: {url: '/sio/rooms/leave'},
    // API
    // Connexion
    api_login: {url: '/api/login', methods: ['POST']},
    // Inscription
    api_signup: {url: '/api/signup', methods: ['POST']},
    // Chargement des annonces, filtrées ou pas
    api_get_annonces: {url: '/api/get-annonces', methods: ['POST']},
    // Chargement d'une annonce
    api_get_annonce: {url: '/api/get-annonce/{hashid}', methods: ['GET']},
    // Chargement des annonces, filtrées ou pas
    api_get_page_settings: {url: '/api/page-settings/{page}', methods: ['GET']},
    // Candidature à une annonce
    api_annonce_apply: {url: '/api/annonce/apply', methods: ['OPTION', 'POST']},
    // XHR
};

_log("DEV_MODE");
STAGE_MODE && _log("STAGE_MODE");
PROD_MODE && _log("PROD_MODE");