import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';

import { chaineAleatoire } from '../../functions/dynamic-id';

const __paterns = {
    "email": "^([a-zA-Z0-9_\\-\.]+)@([a-zA-Z0-9_\\-\.]+)\.([a-zA-Z]{2,8})$"
};

const TextualInput = (__datas:TextualInput = {}) => {

    const { t, i18n } = useTranslation();

    const [time, settime] = React.useState(Date.now());

    if(__datas.fieldName == undefined) {
        __datas.fieldName = chaineAleatoire(16);
    }

    React.useEffect(() => {
        initSelectNone();
    }, [__datas]);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <div className={(__datas.classes || []).join(' ')}>
            <label htmlFor={__datas.fieldName} className={`${__datas.formFor ? "" : "sr-only"} ${(__datas.labelClasses || []).join(' ')}`}>
                {t(__datas.label || "components->input->label->default")}
            </label>
            <input
                onChange={__datas.onChange ? (e) => {
                    if(time + 500 < Date.now()) {
                        __datas.onChange(e);
                        settime(Date.now());
                    }
                } : undefined}
                onBlur={__datas.onChange || undefined}
                defaultValue={__datas.defaultValue || undefined}
                disabled={__datas.disabled || false}
                type={__datas.type || "text"}
                name={__datas.fieldName}
                id={__datas.fieldName}
                autoComplete={__datas.autoComplete || __datas.fieldName}
                placeholder={t(__datas.placeholder || "components->input->placeholder->default")}
                required={__datas.required || false}
                form={__datas.formFor}
                pattern={__datas.pattern || ((__datas.type || "") in __paterns ? __paterns[__datas.type || ""] : undefined)}
                className={`block w-full shadow-sm focus:border-pink-700 sm:text-sm border-gray-300 rounded-md ${__datas.formFor ? "focus:ring-transparent" : ""} ${(__datas.inputClasses || []).join(' ')}${__datas.formFor ? " form-elem" : ""}`}
            />
            <span className={`${__datas.fieldName}_status`}></span>
        </div>
    );
};

interface TextualInput {
    key?: any;
    defaultValue?: string;
    disabled?: boolean;
    formFor?: string;
    onChange?: any;
    classes?: Array<string>;
    labelClasses?: Array<string>;
    inputClasses?: Array<string>;
    required?: boolean;
    type?: string;
    fieldName?: string;
    label?: string;
    placeholder?: string;
    autoComplete?: string;
    pattern?: string;
};

export default hot(module)(TextualInput);