{
    "lng_de": "Deutsch",
    "lng_en": "English",
    "lng_fr": "Français",
    "lng_lu": "Luxemburgisch",
    "lng_nl": "Nederlands",
    "lng_de_LU": "Deutsch",
    "lng_en_EN": "English",
    "lng_fr_BE": "Français",
    "lng_fr_CH": "Français",
    "lng_fr_FR": "Français",
    "lng_fr_LU": "Français",
    "lng_lu_LU": "Luxemburgisch",
    "lng_nl_BE": "Hollandsk",
    "lng_nl_NL": "Hollandsk",
    "country_BE": "België",
    "country_CH": "Zwitserland",
    "country_EN": "Engeland",
    "country_FR": "Frankrijk",
    "country_LU": "Luxemburg",
    "country_NL": "Nederland"

}