import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';

import TextualInput from '../../Basics/Input/text-input';
import SelectCheck from '../../Basics/Select/check';
import SelectWithIcons from '../../Basics/Select/withIcons';
import Button from '../../Basics/Button/index';
import { __modales } from '..';
import { countriesOptions, __gender_options, __signup_elements, __signup_etapes } from '../const';
import Divider from '../../Basics/Divider';
import CheckboxInput from '../../Basics/Input/checkbox-input';
import { signupCheckAdministratif } from '../../functions/signup';

const SignupAdministratif = (__datas:SignupAdministratif = Object()) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <>
            {/* ADRESSE COMPLÈTE */}
            {/* numero_boite_au_lettre */}
            <TextualInput required={true} formFor={__modales[__datas.modale].formId} fieldName="address_street_number" label="forms->signup->street_number->label" autoComplete="street_number" classes={["sm:col-span-4"]} type="number" labelClasses={["block text-sm font-medium text-gray-700"]} inputClasses={["mt-1"]}/>
            {/* nom_voie */}
            <TextualInput required={true} formFor={__modales[__datas.modale].formId} fieldName="address_street_name" label="forms->signup->street_name->label" autoComplete="street_name" classes={["sm:col-span-8"]} type="text" labelClasses={["block text-sm font-medium text-gray-700"]} inputClasses={["mt-1"]}/>
            {/* numero_batiment */}
            <TextualInput required={false} formFor={__modales[__datas.modale].formId} fieldName="address_building_number" label="forms->signup->building_number->label" autoComplete="building_number" classes={["sm:col-span-4"]} type="number" labelClasses={["block text-sm font-medium text-gray-700"]} inputClasses={["mt-1"]}/>
            {/* nom_batiment */}
            <TextualInput required={false} formFor={__modales[__datas.modale].formId} fieldName="address_building_name" label="forms->signup->building_name->label" autoComplete="building_name" classes={["sm:col-span-8"]} type="text" labelClasses={["block text-sm font-medium text-gray-700"]} inputClasses={["mt-1"]}/>
            {/* code_postal */}
            <TextualInput required={true} formFor={__modales[__datas.modale].formId} fieldName="address_zip_code" label="forms->signup->zip_code->label" autoComplete="zip_code" classes={["sm:col-span-4"]} type="text" labelClasses={["block text-sm font-medium text-gray-700"]} inputClasses={["mt-1"]}/>
            {/* localite */}
            <TextualInput required={true} formFor={__modales[__datas.modale].formId} fieldName="address_city" label="forms->signup->city->label" autoComplete="city" classes={["sm:col-span-4"]} type="text" labelClasses={["block text-sm font-medium text-gray-700"]} inputClasses={["mt-1"]}/>
            {/* pays */}
            <SelectCheck required={true} formFor={__modales[__datas.modale].formId} fieldName="address_country" label="forms->signup->country->label" classes={["sm:col-span-4"]} options={Object.values(countriesOptions).map(val => {return {"label": val.label, "value": val.value};})}/>
            <Divider classes={["col-span-12 mt-4 mb-3"]}/>
            {/* Nationalité */}
            <SelectWithIcons
                classes={["sm:col-span-6"]}
                label="forms->signup->nationality->label"
                fieldName="nationality"
                formFor={__modales[__datas.modale].formId}
                required={true}
                multiple={false}
                options={Object.values(countriesOptions).filter(el => el.authorized)}
            />
            {/* Mobile */}
            <TextualInput
                required={true}
                formFor={__modales[__datas.modale].formId}
                classes={["sm:col-span-6"]}
                type="tel"
                fieldName="mobile"
                label="forms->signup->mobile->label"
                autoComplete="mobile"
                labelClasses={["block text-sm font-medium text-gray-700"]}
                inputClasses={["mt-1"]}
            />
            {/* Sexe */}
            <SelectCheck classes={["sm:col-span-6"]} label="forms->signup->sexe->label" options={__gender_options}/>
            <span className="sm:col-span-6 mt-2"></span>
            {/* Has vehicle */}
            <CheckboxInput classes={["sm:col-span-6 mt-4"]} label="forms->signup->has_vehicle->label" fieldName="has_vehicle" formFor={__modales[__datas.modale].formId}/>
            {/* Has license */}
            <CheckboxInput classes={["sm:col-span-6 mt-4"]} label="forms->signup->has_license->label" fieldName="has_license" formFor={__modales[__datas.modale].formId}/>
            <span className="sm:col-span-2 mt-2"></span>
            <Button classes={["sm:col-span-4 mt-2 block text-center"]} onClick={() => __datas.setCurrentStep(__datas.step - 1)}>{t("forms->signup->retour_button->label")}</Button>
            <Button classes={["sm:col-span-4 mt-2 block text-center"]} onClick={() => signupCheckAdministratif(__datas.modale, true) &&  __datas.checkStepValidity(__datas.step, __datas.modale, true) && __datas.setCurrentStep(__datas.step + 1)}>{t("forms->signup->continuer_button->label")}</Button>
            {/* <span className="sm:col-span-2 mt-2"></span> */}
        </>
    );
};

interface SignupAdministratif {
    key?: any;
    checkStepValidity: Function;
    currentStep?: number;
    setCurrentStep: Function;
    step: number;
    modale: string;
};

export default hot(module)(SignupAdministratif);