import React from "react";
import { hot } from "react-hot-loader";
import { Entity_Annonce } from "../interfaces/entities";

import "./annonce-faq.css";

const AnnonceFAQ = ({ annonce }:AnnonceFAQ) => {
    return (
        <div id="annonce-faq">
        </div>
    );
}

interface AnnonceFAQ {
    annonce: Entity_Annonce;
};

export default hot(module)(AnnonceFAQ);