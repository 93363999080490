import React from "react";
import { hot } from "react-hot-loader";
import { useSelector } from "react-redux";
import { Entity_Annonce, Entity_User } from "../interfaces/entities";
import { ReduxStore } from "../interfaces/redux";
import { useTranslation } from "react-i18next";
import Button from "../Basics/Button";

import "./annonce-bandeau.css";
import { missing_informations, resolve_annonce_apply } from "../functions/annonce";
import { initSelectNone } from "../functions/inits";
import Link from "../Link";
import { urls } from "../const";
import LoadingGIF from "../Basics/LoadingGIF";

const AnnonceBandeau = ({ annonce }:AnnonceBandeau) => {
    
    const user = useSelector((state:ReduxStore) => state.user);
    const [missings, setMissings] = React.useState(missing_informations(user, annonce));
    const [linked, setLinked] = React.useState([]);
    const { t, i18n } = useTranslation();

    const resolve_candidat_label = (user:Entity_User, annonce:Entity_Annonce) => {
        console.log(user, annonce);
        if(!user.logged) {
            return t('pages->annonce->bandeau->candidat');
        }
        if(
            user.has_animals && annonce.animals > 0
            || user.has_vehicles && annonce.vehicles > 0
            || (user.affiliates ?? []).length > 0
        ) {
            return t('pages->annonce->bandeau->candidat-manage');
        }
        if(user.logged) {
            if(user.candidatures?.find(candidature => candidature.annonce == annonce.db_id)) {
                if(user.candidatures?.find(candidature => candidature.annonce == annonce.db_id && candidature.is_booked)) {return t('pages->annonce->bandeau->candidat-booked');}
                if(user.candidatures?.find(candidature => candidature.annonce == annonce.db_id && candidature.is_selected)) {return t('pages->annonce->bandeau->candidat-selected');}
                if(user.candidatures?.find(candidature => candidature.annonce == annonce.db_id && candidature.is_invited)) {return t('pages->annonce->bandeau->candidat-invited');}
                if(user.candidatures?.find(candidature => candidature.annonce == annonce.db_id && candidature.is_candidat)) {return t('pages->annonce->bandeau->candidat-remove');}
                if(user.candidatures?.find(candidature => candidature.annonce == annonce.db_id && candidature.is_abandonned)) {return t('pages->annonce->bandeau->candidat-abandonned');}
                if(user.candidatures?.find(candidature => candidature.annonce == annonce.db_id && candidature.is_excluded)) {return t('pages->annonce->bandeau->candidat-excluded');}
                if(user.candidatures?.find(candidature => candidature.annonce == annonce.db_id && candidature.is_missing_infos)) {return t('pages->annonce->bandeau->candidat-missing_infos');}
                return t('pages->annonce->bandeau->candidat-remove');
            }
            return t('pages->annonce->bandeau->candidat');
        }
        return t('pages->annonce->bandeau->candidat');
    };

    const resolve_complete_label = (user:Entity_User, annonce:Entity_Annonce) => {
        if(!user.logged) {
            return t('pages->annonce->bandeau->complete');
        }
        if(
            user.has_animals && annonce.animals > 0
            || user.has_vehicles && annonce.vehicles > 0
            || (user.affiliates ?? []).length > 0
        ) {
            return t('pages->annonce->bandeau->complete-all');
        }
        return t('pages->annonce->bandeau->complete');
    };

    React.useEffect(() => {
        setMissings(missing_informations(user, annonce));
    }, [user]);

    React.useEffect(() => {
        initSelectNone();
    }, []);
    
    return (
        <div id="annonce-bandeau">
            <h2 className="annonce-title ellipsis">{annonce.titre}</h2>
            <Button classes={["annonce-bandeau-candidat", "ellipsis"]} onClick={(e) => resolve_annonce_apply(user, annonce)}>
                <span>{resolve_candidat_label(user, annonce)}</span>
                {user.logged ? null : <Link {...{...urls.signin, classes: ["absolute-link"], modaleSpecificDatas: {onSuccess: "action:resolve_annonce_apply", datas: {annonce: annonce, user: null}}}}/>}
                <LoadingGIF id="annonce-bandeau-candidat-waiter" classes={["hidden"]}/>
            </Button>
            <Button classes={["annonce-bandeau-complete", "ellipsis"]} onClick={(e) => user.logged}>
                <span>{resolve_complete_label(user, annonce)}</span>
                {user.logged ? null : <Link {...{...urls.signin, classes: ["absolute-link"], modaleSpecificDatas: {onSuccess: "modale:annonce_resolve_complete", datas: {annonce: annonce, user: null}}}}/>}
            </Button>
        </div>
    );
}

interface AnnonceBandeau {
    annonce: Entity_Annonce;
};

export default hot(module)(AnnonceBandeau);