import { hot } from 'react-hot-loader';

import React from "react";

// ICONS
import { PlusSmIcon as PlusSmIconSolid } from '@heroicons/react/solid';
import { MinusSmIcon as MinusSmIconSolid } from '@heroicons/react/solid';

import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';

import { __buttonsClasses, __iconsClasses } from './const';
import { resolveClasses } from '../../functions';

// EXPLAIN - Par défaut leftIconB3
function resolveIcon(__icon:string|null = null, __type:string|null = null, __left = true) {
    if(__icon == null) {return null;}
    switch(__icon) {
        case "plus": return <PlusSmIconSolid className={__iconsClasses[__type != null ? __type : __left ? "leftIconB3" : "rightIconB3"] || __iconsClasses[__left ? "leftIconB3" : "rightIconB3"]}/>
        case "minus": return <MinusSmIconSolid className={__iconsClasses[__type != null ? __type : __left ? "leftIconB3" : "rightIconB3"] || __iconsClasses[__left ? "leftIconB3" : "rightIconB3"]}/>
        default: return null;
    }
}

function resolveButtonClasses(__version:string|null = null, __size:string|null = null) {
    if(__version == null) {__version = "primary";}
    if(__size == null) {__size = "B2";}
    return __buttonsClasses[`${__version}${__size}`] || __buttonsClasses["primaryB2"];
}

const Button = (__datas:Button = {}) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <button
            id={__datas.id}
            name={__datas.name}
            disabled={__datas.disabled || false}
            className={resolveClasses(resolveButtonClasses(__datas.version, __datas.size), (__datas.classes || []).join(' '))}
            onClick={(e:any) => __datas.onClick != undefined && __datas.onClick(e, this)}
            type={__datas.type || "button"}
            key={__datas.key}
        >
            {__datas.icon != undefined && (__datas.icon.first == undefined || __datas.icon.first == true) && (resolveIcon(__datas.icon.name, __datas.icon?.version, true) || null)}
            {__datas.children}
            {__datas.icon != undefined && __datas.icon?.first == false && (resolveIcon(__datas.icon.name, __datas.icon?.version, false) || null)}
        </button>
    );
};

interface Button {
    key?: any;
    disabled?: boolean;
    children?: any;
    id?: string;
    name?: string;
    classes?: string[];
    type?: "reset"|"button"|"submit";
    version?: string;
    size?: string;
    onClick?: Function;
    icon?: {
        first?: boolean;
        name: string;
        version?: string;
    };
};

export default hot(module)(Button);