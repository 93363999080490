import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../functions/inits';

import i18next from 'i18next';

const StepperProgressBar = (__datas:StepperProgressBar = Object()) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, [__datas]);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <div id={__datas.stepperId} className="relative pt-5">
            <h4 className="sr-only">Status</h4>
            {__datas.steps.map((etape, key:number) => 
                <p
                    className={`label-${etape.slug} text-sm h-5 absolute top-0 left-0 w-full font-medium text-gray-900 ${(__datas.currentStep || 0) == key ? "opacity-100" : "opacity-0"}`}
                    style={{transition: "opacity 0.1s ease-in-out 0.15s"}}
                    key={key}
                >
                    {t(etape.label)}
                </p>
            )}
            <div className="mt-6" aria-hidden="true">
            <div className="bg-gray-200 rounded-full overflow-hidden">
                <div className="h-2 bg-pink-900 rounded-full" style={{transition: 'width 0.3s ease-in-out 0s', width: `${(100 / __datas.steps.length) * ((__datas.currentStep || 0) + 1)}%` }} />
            </div>
            <div
                className="hidden sm:grid text-sm font-medium text-gray-600 mt-6"
                style={{
                    gridTemplateColumns: `repeat(${__datas.steps.length}, 1fr)`,
                }}
            >
                {__datas.steps.map((etape, key:number) => 
                    <div
                        className={[
                            `short-${etape.slug}`,
                            key == (__datas.currentStep || 0) ? "text-pink-900 border-b-pink-900 border-b-2" : "border-transparent border-b-2",
                            // key > 0 && key < __datas.steps.length - 1 ? "text-center" : "",
                            // key == __datas.steps.length - 1 ? "text-right" : "",
                            "text-center"
                        ].join(' ')}
                        style={{transition: 'color 0.3s ease-in-out 0s, border 0.3s ease-in-out 0s'}}
                        key={key}
                    >
                            {t(etape.short)}
                    </div>
                )}
            </div>
            </div>
        </div>
    );
};

interface StepperProgressBar {
    key?: any;
    currentStep?: number;
    stepperId?: string;
    steps: {
        slug: string;
        label: string;
        short: string;
    }[];
};

export default hot(module)(StepperProgressBar);
