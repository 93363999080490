import { hot } from "react-hot-loader";

import React from "react";
import Link from "../Link";

import "./index.css";
import { __navItems, __languages } from "./const";
import { NavItemInterface } from "../interfaces/elements";
import { ReduxStore } from "../interfaces/redux";
import { useTranslation } from "react-i18next";
import { initSelectNone } from '../functions/inits';
import { selectAll, selectOne } from "../functions/selectors";
import { useSelector } from "react-redux";

function Header(__datas:Header = {}) {

    const logged = useSelector((state:ReduxStore) => state.user.logged);
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        selectOne('#header-set-lng')?.setAttribute(
            'data-nb_langs',
            (selectAll('#header-set-lng > .dropdown-item', true).length).toFixed(0)
        );
        initSelectNone();
    }, [logged]);

    React.useEffect(() => {
        selectOne('html')?.setAttribute('lang', i18n.language.split('_')[0]);
    }, [i18n.language]);

    return (
        <header className="sticky top-0 z-10 bg-white overflow-hidden" key={__datas?.key}>
            <nav id="header-nav">
                {__navItems.length && __navItems.map((item:NavItemInterface, key) => <Link {...{...item, key: key}}/>).filter(el => el)}
            </nav>
            <ul id="header-set-lng" data-nb_langs="0" className="dropdown-menu lang-menu">
                <li className="dropdown-item current-lng">{`${t('lng_' + i18n.language)} (${i18n.language.split('_')[1]})`}</li>
                {Object.keys(__languages).map((lng:string, index) => {
                    if(__languages[lng].length == 1 && `${lng}_${__languages[lng][0]}` != i18n.language) {
                        return <li key={index} className="dropdown-item ellipsis" onClick={() => i18n.changeLanguage(`${lng}_${__languages[lng][0]}`)}>{`${t(`lng_${lng}_${__languages[lng][0]}`)} (${__languages[lng]})`}</li>
                    }
                    else if(__languages[lng].length > 1) {
                        return __languages[lng].map((sub_lng:string, sub_key:any) => {
                            if(`${lng}_${sub_lng}` == i18n.language) {return null;}
                            return (
                                <li
                                    key={sub_key}
                                    className="dropdown-item ellipsis"
                                    onClick={() => i18n.changeLanguage(`${lng}_${sub_lng}`)}
                                >
                                    {`${t(`lng_${lng}_${sub_lng}`)} (${sub_lng})`}
                                </li>
                            );
                        });
                    }
                    return null;
                }).filter(el => el)}
            </ul>
        </header>
    );
};

interface Header {
    key?: any;
};

export default hot(module)(Header);
