import { hot } from 'react-hot-loader';

import React from "react";

import MySpaceGenerals from './general';
import MySpacePhotos from './photos';
import MySpaceTabs from './tabs';
import MySpaceDisplay from './display';

import './index.css';
import { initSelectNone } from '../functions/inits';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../interfaces/redux';
import { Entity_User } from '../interfaces/entities';

function MySpace(__datas:MySpace = {}) {

    const user = useSelector((state:ReduxStore) => state.user);
    const loginActivity = useSelector((state:ReduxStore) => state.page.loginActivity);
    const { t, i18n } = useTranslation();
    const [tab, setTab]:[string, React.Dispatch<string>] = React.useState("generals");
    const [profile, setProfile]:[Entity_User, React.Dispatch<Entity_User>] = React.useState(user);

    // TODO - Créer une fonction de changement de profil affiché
    // TODO - Passre cette fonction à MySpaceGenerals
    // TODO - Dans MySpaceGenerals utiliser cette fonction sur les 'can-manage'

    React.useEffect(() => {
        initSelectNone();
    }, [loginActivity, user]);

    React.useEffect(() => {

    }, [i18n.language]);

    // IMPORTANT - À supprimer en PROD
    const test_count = {
        "generals": 5,
        "dressing": 3,
        "activities": 123
    };

    return (
        <section id="myspace" className="bg-gray-50 overflow-hidden" key={__datas?.key}>
            <MySpaceGenerals is_my_profile={user.db_id == profile.db_id} profile={profile} change_profile={setProfile}/>
            <MySpacePhotos photos={[profile.main_photos, ...(profile.photos ?? [])]}/>
            <MySpaceTabs tab={tab} count={test_count} changeTab={setTab}/>
            <MySpaceDisplay tab={tab} profile={profile}/>
        </section>
    );
};

interface MySpace {
    key?: any;
};

export default hot(module)(MySpace);
