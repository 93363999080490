import React from 'react';
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../functions/inits';
import { __backURLs } from '../functions/const';
import { Entity_Annonce } from '../interfaces/entities';

import "./annonces-elem.css";
import { hot } from 'react-hot-loader';
import Link from '../Link';
import { urls } from '../const';
import { resolve_annonce_detail, resolve_gender_icon } from '../functions/annonce';

const AnnoncesElem = ({annonce_key, annonce}:AnnoncesElem = {}) => {

    if(!annonce) {
        return <></>;
    }

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    // Ajouter un bouton d'action rapide pour mettre l'annonce en favoris
    // Ajouter un bouton d'action rapide pour postuler à l'annonce
    // Ajouter un bouton d'action rapide pour dépostuler à l'annonce

    return (
        <div id={`annonce-elem-${annonce.db_id}`} key={annonce_key} className="overflow-hidden relative">
            <div className="annonce-elem-title relative z-0">
                <span>{annonce.titre}</span>
                <Link
                    classes={["absolute-link", "relative", "z-0"]}
                    url={urls.annonce.url.replace('{hashid}', annonce.db_id)}
                    page={urls.annonce.page}
                    title={t('pages->annonces->annonce->link->title', {title: annonce.titre})}
                ></Link>
            </div>
            <div className="annonce-elem-details relative z-0">
                <div className="annonce-elem-details-pic">
                    {annonce.poster && <img src={process.env.BACK_URL + '/' + annonce.poster} alt={annonce.titre}/>}
                    {!annonce.poster && <img src={new URL('../../assets/icons/no-img.svg', import.meta.url)?.href} alt={annonce.titre}/>}
                </div>
                <div className="annonce-elem-details-descr">
                    <div className="annonce-elem-details-descr-short">
                        {
                            [
                                {type: "type", img: new URL('../../assets/icons/annonce/type.svg', import.meta.url)?.href},
                                {type: "project", img: new URL('../../assets/icons/annonce/project.svg', import.meta.url)?.href},
                                {type: "gender", img: new URL('../../assets/icons/annonce/gender.svg', import.meta.url)?.href},
                                {type: "money", img: new URL('../../assets/icons/annonce/money.svg', import.meta.url)?.href},
                                {type: "age", img: new URL('../../assets/icons/annonce/age.svg', import.meta.url)?.href},
                                {type: "land", img: new URL('../../assets/icons/annonce/land.svg', import.meta.url)?.href},
                                {type: "location", img: new URL('../../assets/icons/annonce/location.svg', import.meta.url)?.href},
                                {type: "calendar", img: new URL('../../assets/icons/annonce/calendar.svg', import.meta.url)?.href},
                            ]
                            .map((detail, detail_key) => (
                                detail.type == "gender" ?
                                <div className={`annonce-elem-details-descr-short-elem annonce-elem-short-${detail.type}`} key={detail_key}>
                                    {(resolve_gender_icon(annonce, "annonces") ?? []).map((icon, icon_i, icons) => (
                                        <><img src={icon.src} title={icon.title}/>{icon_i + 1 < icons.length ? ' | ' : ''}</>
                                    ))}
                                    <span>{resolve_annonce_detail(detail.type, annonce, "annonces")}</span>
                                </div> : 
                                <div className={`annonce-elem-details-descr-short-elem annonce-elem-short-${detail.type}`} key={detail_key}>
                                    <img src={detail.img} title={t(`pages->annonces->infos->details->icon->${detail}`)}/>
                                    <span>{resolve_annonce_detail(detail.type, annonce, "annonces")}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className="annonce-elem-details-descr-abstract">{annonce.descr && annonce.descr.length ? annonce.descr : t('pages->annonces->infos->abstract->no_descr')}</div>
                </div>
            </div>
        </div>
    );
};

interface AnnoncesElem {
    annonce_key?: any;
    annonce?: Entity_Annonce;
};

export default hot(module)(AnnoncesElem);