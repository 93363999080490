import { hot } from 'react-hot-loader';

import React from 'react';
import { useTranslation } from 'react-i18next';

import Footer from '../Footer';
import Header from '../Header';

import HomePage from '../../pages/home';
import _404Page from '../../pages/_404';
import AnnoncesPage from '../../pages/annonces';
import C7plus from '../../pages/c7plus';
import AnnoncePage from '../../pages/annonce';
import PortfolioPage from '../../pages/portfolio';
import HelpPage from '../../pages/help';
import MySpacePage from '../../pages/myspace';
import TestPage from '../../pages/test';
import TermsPage from '../../pages/terms';

import './reset.css';
import './const.css';
import './index.css';
import { initSelectNone } from '../functions/inits';
import { pages, urls } from '../const';

// REDUX
import { useSelector } from 'react-redux';
import { NavItemInterface } from '../interfaces/elements';
import { ReduxStore } from '../interfaces/redux';
import Modale from '../Modale';

let _i = -1;

export const pagesStructure = {
    [urls.home.url]: [<HomePage key={++_i}/>],
    [urls["404"].url]: [<_404Page key={++_i}/>],
    [urls.annonces.url]: [<AnnoncesPage key={++_i}/>],
    [urls.c7plus.url]: [<C7plus key={++_i}/>],
    [urls.annonce.url]: [<AnnoncePage key={++_i}/>],
    [urls.portfolio.url]: [<PortfolioPage key={++_i}/>],
    [urls.help.url]: [<HelpPage key={++_i}/>],
    [urls.myspace.url]: [<MySpacePage key={++_i}/>],
    [urls.test.url]: [<TestPage key={++_i}/>],
    [urls.terms.url]: [<TermsPage key={++_i}/>],
    "404": []
}

function Layout(datas:Layout = {}) {
    
    const currentPath = useSelector((state:ReduxStore) => state.page.pathname);
    const user = useSelector((state:ReduxStore) => state.user);
    const logged = useSelector((state:ReduxStore) => state.user.logged);

    const { t, i18n } = useTranslation();

    const hasHeader = function():boolean {return true;};

    const hasFooter = function():boolean {return true;};

    const resolve_dyn = (__search:string, __value:string) => {
        const scheme = __search.replace('/', '').split('/');
        const test = __value.replace('/', '').split('/');
        let params = {};
        if(scheme.length != test.length) {
            console.log("1 non");
            return false;
        }
        for(let i = 0 ; i < scheme.length; i++) {
            if(scheme[i] == test[i]) {
                console.log("2 continue");
                continue;
            }
            if(scheme[i].match(/\{[a-zA-Z0-9]*\}/gi)) {
                // UP - Gérer les match multiples pour le cas où plusieurs paramètres seraient entre les mêmes accolades
                // let matches = scheme[i].match(/\{[a-zA-Z0-9]*\}/gi);
                // matches?.forEach(match => {
                //     params[scheme[i].replace(/[{}]/gi, '')] = test[i];
                //     console.log("3 found parameter at position " + i + " '" + test[i] + "'");
                // })
                params[scheme[i].replace(/[{}]/gi, '')] = test[i];
                console.log("3 found parameter at position " + i + " '" + test[i] + "'");
                continue;
            }
            if(scheme[i] != test[i]) {
                console.log("4 static part " + i + " does not match with the current URL : '" + scheme[i] + "' <> '" + test[i] + "'");
            }
            return false;
        }
        return params;
    }

    const resolvePage = function() {
        let __page:NavItemInterface = pages[currentPath] || null;
        if(__page == null) {
            Object.keys(pages).filter(page => pages[page].dynamic == true)
            .forEach((dyn) => {
                if(__page != null) {return;}
                if(resolve_dyn(pages[dyn].url, currentPath)) {
                    __page = pages[dyn];
                    return;
                }
            });
            if(__page == null) {
                __page = pages["/404"];
            }
        }
        if(logged == false && __page.unlogged && urls[__page.unlogged]) {
            console.log(`Client non connecté", détour de '${currentPath}' vers '${__page.unlogged}'`);
            return pagesStructure[urls[__page.unlogged].url] ?? pagesStructure["404"] ?? [];
        }
        if(typeof __page.loggedAlt == "string" && logged == true) {
            console.log(`'Client connecté, détour de '${currentPath} vers '${__page.loggedAlt}'`);
            return pagesStructure[urls[__page.loggedAlt].url] ?? pagesStructure["404"] ?? [];
        }
        else if(__page.visitorAlt == "string" && logged == false) {
            console.log(`'Client non connecté, détour de '${currentPath} vers '${__page.visitorAlt}'`);
            return pagesStructure[urls[__page.visitorAlt].url] ?? pagesStructure["404"] ?? [];
        }
        return pagesStructure[urls[__page.slug ?? ""].url] ?? pagesStructure["404"] ?? [];
    };

    React.useEffect(() => {
        console.log("Utilisateur connecté : " + logged);
        if(typeof document != "undefined") {
            document.title = `CAS7ING - casting7.com | ${currentPath == urls.home.url ? t('pages->home->meta->title') : t(Object.values(urls).map((nav:NavItemInterface) => nav.url === currentPath ? nav.title || nav.libelle : null).filter(val => val)[0] || "pages->home->meta->title")}`;
        }
        initSelectNone();
        // IMPORTANT - Créer deux fonctions qui gèrent le comportement du déplacement dans l'historique de navigation
        // initPrevious();
        // initNext();
    }, [logged, user]);

    React.useEffect(() => {
        console.log("Changement de langue");
        // CANCEL - Utiliser Redux pour vérifier l'état du pathname
        // EXPLAIN - Redux dispatch l'événement, ce qui envoie le signal de re-render aux composants abonnés
    }, [i18n.language]);

    React.useEffect(() => {
        console.log("Changement de page");
        initSelectNone();
    }, [currentPath]);

    return (
        <>
            {hasHeader() && <Header/>}
            {resolvePage()}
            {hasFooter() && <Footer/>}
            <Modale/>
        </>
    );
};

interface Layout {

};

export default hot(module)(Layout);
