import { hot } from 'react-hot-loader';

import React, { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../functions/inits';

import { useSelector, useDispatch } from 'react-redux';

import ModaleSigninContent from "./Signin";
import ModaleSignupContent from "./Signup";
import ModaleForgotPasswordContent from "./ForgotPwd";
import ModaleImageViewContent from "./ImageView";
import ModaleAnnonceResolveEntityContent from "./AnnonceResolveEntity";

import { Dialog, Transition } from '@headlessui/react';
import { ReduxStore } from '../interfaces/redux';
import { setModale } from '../../features/page/pageSlice';

import './index.css';
import { formsBreakpoints } from './const';
import { __languages } from '../Header/const';
import { selectAll, selectOne } from '../functions/selectors';

export const __modales = {
    "signup": {
        "content": <ModaleSignupContent/>,
        "type": "form",
        "modaleId": "signup",
        "formId": "signup-form",
    },
    "signin": {
        "content": <ModaleSigninContent/>,
        "type": "form",
        "modaleId": "signin",
        "formId": "signin-form",
    },
    "forgot_pwd": {
        "content": <ModaleForgotPasswordContent/>,
        "type": "form",
        "modaleId": "forgot_pwd",
        "formId": "forgot_pwd-form",
    },
    "image_view": {
        "content": <ModaleImageViewContent/>,
        "type": "form",
        "modaleId": "image_view",
        "formId": "image_view-form"
    },
    "annonce_resolve_entity": {
        "content": <ModaleAnnonceResolveEntityContent/>,
        "type": "form",
        "modaleId": "annonce_resolve_entity",
        "formId": "annonce_resolve_entity-form"
    }
};

const ModaleBase = (__datas:ModaleBase = Object()) => {

    const { t, i18n } = useTranslation();
    const modale = useSelector((state:ReduxStore) => state.page.activeModale);
    const global_env = useSelector((state:ReduxStore) => state.page.modaleGlobalEnv);
    const [open, setOpen] = React.useState(modale == null ? false : true);
    const dispatch = useDispatch();

    // console.group("ModaleBase");
    // console.log(__datas);
    // console.log("open : ", open);
    // console.log("modale : ", modale);
    // console.groupEnd();
    
    React.useEffect(() => {
        selectOne('#mdoale-set-lng')?.setAttribute(
            'data-nb_langs',
            (selectAll('#mdoale-set-lng > .dropdown-item', true).length).toFixed(0)
        );
        initSelectNone();
        if(modale != null && modale in __modales) {
            setOpen(true);
            if(__modales[modale].type == "form") {

            }
        }
        else if(modale == null) {
            setOpen(false);
        }
    }, [modale]);

    React.useEffect(() => {
        
    }, [i18n.language]);

    if(modale == null) {
        return <></>;
    }

    return (
        <Transition.Root show={open} as={Fragment} key={__datas.key}>
            <Dialog as="div" className="relative z-10" onClose={() => {
                setOpen(false);
                dispatch(setModale(null));
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center text-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel data-modale={modale} className={`
                            modale-content relative bg-white px-4 pt-5 pb-4 text-left transform sm:rounded-sm md:rounded-md lg:rounded-lg max-h-screen overflow-hidden
                            ${formsBreakpoints[modale] ? formsBreakpoints[modale]["sm"] || "" : ""}
                            ${formsBreakpoints[modale] ? formsBreakpoints[modale]["md"] || "" : ""}
                            ${formsBreakpoints[modale] ? formsBreakpoints[modale]["lg"] || "" : ""}
                            ${formsBreakpoints[modale] ? formsBreakpoints[modale]["xl"] || "" : ""}
                            ${formsBreakpoints[modale] ? formsBreakpoints[modale]["2xl"] || "" : ""}
                            `.trim()}
                            style={{maxHeight: "calc(100vh - 5rem)"}}
                        >
                            <ul id="modale-set-lng" data-nb_langs="0" className="dropdown-menu lang-menu">
                                <li className="dropdown-item current-lng">{`${t('lng_' + i18n.language)} (${i18n.language.split('_')[1]})`}</li>
                                {Object.keys(__languages).map((lng:string, index) => {
                                    if(__languages[lng].length == 1 && `${lng}_${__languages[lng][0]}` != i18n.language) {
                                        return <li key={index} className="dropdown-item ellipsis" onClick={() => i18n.changeLanguage(`${lng}_${__languages[lng][0]}`)}>{`${t(`lng_${lng}_${__languages[lng][0]}`)} (${__languages[lng]})`}</li>
                                    }
                                    else if(__languages[lng].length > 1) {
                                        return __languages[lng].map((sub_lng:string, sub_key:any) => {
                                            if(`${lng}_${sub_lng}` == i18n.language) {return null;}
                                            return (
                                                <li
                                                    key={sub_key}
                                                    className="dropdown-item ellipsis"
                                                    onClick={() => i18n.changeLanguage(`${lng}_${sub_lng}`)}
                                                >
                                                    {`${t(`lng_${lng}_${sub_lng}`)} (${sub_lng})`}
                                                </li>
                                            );
                                        });
                                    }
                                    return null;
                                }).filter(el => el)}
                            </ul>
                            {/* MODALE CONTENT */}
                            {__modales[modale]?.content || <></>}
                            {/* MODALE CONTENT END */}
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

interface ModaleBase {
    key?: any;
};

export default hot(module)(ModaleBase);
