import { hot } from 'react-hot-loader';

import React from "react";

import { initSelectNone } from '../functions/inits';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../interfaces/redux';

import MySpaceDisplayGenerals from './Display/generals';
import MySpaceDisplayActivities from './Display/activities';
import MySpaceDisplayCV from './Display/artistic_cv';
import MySpaceDisplayAnimals from './Display/animals';
import MySpaceDisplayVehicles from './Display/vehicles';
import MySpaceDisplayDressing from './Display/dressing';
import { Entity_User } from '../interfaces/entities';

function MySpaceDisplay(__datas:MySpaceDisplay = {}) {

    const loginActivity = useSelector((state:ReduxStore) => state.page.loginActivity);
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, [loginActivity, __datas.profile]);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <div id="display" className="custom-scrollbar py-6 px-8 box-border">
            {__datas.tab == "generals" && <MySpaceDisplayGenerals profile={__datas.profile}/>}
            {__datas.tab == "activities" && <MySpaceDisplayActivities profile={__datas.profile}/>}
            {__datas.tab == "artist_cv" && <MySpaceDisplayCV profile={__datas.profile}/>}
            {__datas.tab == "animals" && <MySpaceDisplayAnimals profile={__datas.profile}/>}
            {__datas.tab == "vehicles" && <MySpaceDisplayVehicles profile={__datas.profile}/>}
            {__datas.tab == "dressing" && <MySpaceDisplayDressing profile={__datas.profile}/>}
        </div>
    );
};

interface MySpaceDisplay {
    key?: any;
    tab?: string;
    profile?: Entity_User;
};

export default hot(module)(MySpaceDisplay);