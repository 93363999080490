import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';

import { initSelectNone } from '../components/functions/inits';

const HelpPage = (__datas:HelpPage = {}) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <main data-page="help" className="custom-scrollbar">
            Help
        </main>
    );
};

interface HelpPage {
    key?: any;
};

export default hot(module)(HelpPage);