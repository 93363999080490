import { hot } from 'react-hot-loader';

import React from "react";

import './index.css';
import { initSelectNone } from '../functions/inits';
import { useTranslation } from 'react-i18next';
import { urls } from '../const';
import Link from '../Link';
import WhiteButton from '../Basics/Button/white-button';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../interfaces/redux';

function HeroHeader(__datas:HeroHeader = {}) {

    const logged = useSelector((state:ReduxStore) => state.user.logged);
    const loginActivity = useSelector((state:ReduxStore) => state.page.loginActivity);
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, [loginActivity, logged]);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <section id="hero-header" key={__datas?.key}>
            <div id="leading-content">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="block xl:inline">{t("hero-header->catch-black")}</span>{' '}
                    <span className="block text-pink-900 xl:inline">{t("hero-header->catch-pink")}</span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                    {t("hero-header->main-text")}
                    <Link {...{...urls.signup, id: "hero-leading-cta-signup-lnk", classes:[loginActivity ? "" : "red-link", "block", "hover:font-medium"]}}></Link>
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                    <Link {...urls.signup} classes={["cursor-pointer rounded-md shadow flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium text-white bg-pink-800 hover:bg-pink-900 md:py-4 md:text-lg md:px-10"]}></Link>
                    <WhiteButton id="hero-leading-cta-movies">
                        {t("hero-header->cta-movies-text")}
                    </WhiteButton>
                </div>
            </div>
            <div id="banner-image">
                <img id="banner-image-path" alt="hero header path" src={new URL("../../assets/images/hero-header-path.svg", import.meta.url)?.href}/>
                <img id="banner-image-picture" alt="hero header image" src={new URL("../../assets/images/hero-header-image.png", import.meta.url)?.href}/>
            </div>
        </section>
    );
};

interface HeroHeader {
    key?: any;
};

export default hot(module)(HeroHeader);
