import { hot } from 'react-hot-loader';

import React from "react";
import { Switch } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';
import { chaineAleatoire } from '../../functions/dynamic-id';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Toggle = (__datas:Toggle = {}) => {

    __datas.fieldName = __datas.fieldName || chaineAleatoire(16);

    const [enabled, setEnabled] = React.useState(__datas.enabled || false);

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, [__datas]);

    React.useEffect(() => {

    }, [i18n.language]);

    if(__datas.label && __datas.label.length > 0) {
        return (
            <Switch.Group as="div" className="flex items-center justify-between">
                {
                    (__datas.label_pos == "left" || __datas.label_pos != "right") &&
                    <Switch.Label as="span" className="mr-3 text-sm">
                        <span className="font-medium text-gray-900">{__datas.label}</span>{' '}
                        <span className="text-gray-500">{__datas.label_detail}</span>
                    </Switch.Label>
                }
                <Switch
                    checked={enabled}
                    onClick={__datas.onClick}
                    onChange={(e) => {
                        setEnabled(e);
                        __datas.onChange && __datas.onChange(e);
                    }}
                    className={classNames(
                    enabled ? 'bg-pink-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-pink-600 focus:ring-offset-2'
                    )}
                >
                    <span
                        aria-hidden="true"
                        className={classNames(
                            enabled ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                    />
                </Switch>
                {
                    __datas.label_pos == "right" &&
                    <Switch.Label as="span" className="ml-3 text-sm">
                        <span className="font-medium text-gray-900">{__datas.label}</span>{' '}
                        <span className="text-gray-500">{__datas.label_detail}</span>
                    </Switch.Label>
                }
            </Switch.Group>
        );
    }

    return (
        <Switch
            id={__datas.fieldName}
            checked={enabled}
            onClick={__datas.onClick}
            onChange={(e) => {
                setEnabled(e);
                __datas.onChange && __datas.onChange(e);
            }}
            form={__datas.formFor}
            className={`${classNames( enabled ? 'bg-pink-900' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-700')}${__datas.formFor ? " form-elem" : ""}`}
        >
            <span className="sr-only">{__datas.sr_txt ?? `${__datas.fieldName} toggle`}</span>
            <span aria-hidden="true" className={classNames( enabled ? 'translate-x-5' : 'translate-x-0'
                , 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )} />
            <span className={`${__datas.fieldName}_status`} data-checked={enabled}></span>
        </Switch>
    );
};

interface Toggle {
    key?: any;
    enabled?: boolean;
    label?: string;
    label_detail?: string;
    label_pos?: string;
    onClick?: any;
    onChange?: any;
    fieldName?: string;
    formFor?: string;
    sr_txt?: string;
};

export default hot(module)(Toggle);
