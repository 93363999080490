import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translation_de_LU from './translation/de_LU.json';
import translation_en_EN from './translation/en_EN.json';
import translation_fr_BE from './translation/fr_BE.json';
import translation_fr_CH from './translation/fr_CH.json';
import translation_fr_FR from './translation/fr_FR.json';
import translation_fr_LU from './translation/fr_LU.json';
import translation_lu_LU from './translation/lu_LU.json';
import translation_nl_BE from './translation/nl_BE.json';
import translation_nl_NL from './translation/nl_NL.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    de_LU: {translation: translation_de_LU},
    en_EN: {translation: translation_en_EN},
    fr_BE: {translation: translation_fr_BE},
    fr_CH: {translation: translation_fr_CH},
    fr_FR: {translation: translation_fr_FR},
    fr_LU: {translation: translation_fr_LU},
    lu_LU: {translation: translation_lu_LU},
    nl_BE: {translation: translation_nl_BE},
    nl_NL: {translation: translation_nl_NL},
};

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
    resources,
    lng: "fr_BE", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    fallbackLng: "fr_FR",
    keySeparator: "->",
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    // supportedLngs: ["de_LU", "en_EN", "fr_BE", "fr_CH", "fr_FR", "fr_LU", "lu-LU", "nl_BE", "nl_NL"],
}, () => {});

export default i18n;