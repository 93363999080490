import { hot } from 'react-hot-loader';

import React from "react";

import TextualInput from '../../Basics/Input/text-input';
import SelectCheck from '../../Basics/Select/check';
import { __modales } from '..';
import Button from '../../Basics/Button/index';
import i18next from 'i18next';
import { __signup_elements, __signup_etapes } from '../const';
import { signupCheckSubmit } from '../../functions/signup';
import { initSelectNone } from '../../functions/inits';

const __types = {
    "est": "Est",
    "mediterraneen": "Méditerraneen",
    "caucasien": "Caucasien",
    "metisse": "Métisse",
    "nord_africain": "Nord-africain",
    "africain": "Africain",
    "asiatique": "Asiatique",
    "indien": "Indien",
    "eurasien": "Eurasien",
    "sud_americain": "Sud-americain",
    "autre": "Autre",
};

const __cheveux = {
    "blond": "Blond",
    "brun": "Brun",
    "chatain": "Châtain",
    "noir": "Noir",
    "gris": "Gris",
    "roux": "Roux",
    "poivre_sel": "Poivre et sel",
    "chauve": "Chauve",
    "autre": "Autre",
};

const __yeux = {
    "bleus": "Bleus",
    "verts": "Verts",
    "marrons": "Marrons",
    "gris": "Gris",
    "noir": "Noirs",
    "vairons": "Vairons",
};

const __langue = [
    {"iso": "aa", "name": "Afar","original": "Afaraf"},
    {"iso": "ab", "name": "Abkhazian","original": "Аҧсуа"},
    {"iso": "ae", "name": "Avestan","original": "Avesta"},
    {"iso": "af", "name": "Afrikaans","original": "Afrikaans"},
    {"iso": "ak", "name": "Akan","original": "Akan"},
    {"iso": "am", "name": "Amharic","original": "አማርኛ"},
    {"iso": "an", "name": "Aragonese","original": "Aragonés"},
    {"iso": "ar", "name": "Arabic","original": "العربية"},
    {"iso": "as", "name": "Assamese","original": "অসমীয়া"},
    {"iso": "av", "name": "Avaric","original": "авар мацӀ ; магӀарул мацӀ"},
    {"iso": "ay", "name": "Aymara","original": "Aymar aru"},
    {"iso": "az", "name": "Azerbaijani","original": "Azərbaycan dili"},
    {"iso": "ba", "name": "Bashkir","original": "башҡорт теле"},
    {"iso": "be", "name": "Belarusian","original": "Беларуская"},
    {"iso": "bg", "name": "Bulgarian","original": "български език"},
    {"iso": "bh", "name": "Bihari","original": "भोजपुरी"},
    {"iso": "bi", "name": "Bislama","original": "Bislama"},
    {"iso": "bm", "name": "Bambara","original": "Bamanankan"},
    {"iso": "bn", "name": "Bengali","original": "বাংলা"},
    {"iso": "bo", "name": "Tibetan","original": "བོད་ཡིག"},
    {"iso": "br", "name": "Breton","original": "Brezhoneg"},
    {"iso": "bs", "name": "Bosnian","original": "Bosanski jezik"},
    {"iso": "ca", "name": "Catalan","original": "Català"},
    {"iso": "ce", "name": "Chechen","original": "нохчийн мотт"},
    {"iso": "ch", "name": "Chamorro","original": "Chamoru"},
    {"iso": "co", "name": "Corsican","original": "Corsu ; lingua corsa"},
    {"iso": "cr", "name": "Cree","original": "ᓀᐦᐃᔭᐍᐏᐣ"},
    {"iso": "cs", "name": "Czech","original": "Česky ; čeština"},
    {"iso": "cu", "name": "Old Church Slavonic","original": "Словѣньскъ"},
    {"iso": "cv", "name": "Chuvash","original": "чӑваш чӗлхи"},
    {"iso": "cy", "name": "Welsh","original": "Cymraeg"},
    {"iso": "da", "name": "Danish","original": "Dansk"},
    {"iso": "de", "name": "German","original": "Deutsch"},
    {"iso": "dv", "name": "Divehi","original": "ދިވެހި"},
    {"iso": "dz", "name": "Dzongkha","original": "རྫོང་ཁ"},
    {"iso": "ee", "name": "Ewe","original": "Ɛʋɛgbɛ"},
    {"iso": "el", "name": "Greek","original": "Ελληνικά"},
    {"iso": "en", "name": "English","original": "English"},
    {"iso": "eo", "name": "Esperanto","original": "Esperanto"},
    {"iso": "es", "name": "Spanish","original": "Español; castellano"},
    {"iso": "et", "name": "Estonian","original": "Eesti keel"},
    {"iso": "eu", "name": "Basque","original": "Euskara"},
    {"iso": "fa", "name": "Persian","original": "فارسی"},
    {"iso": "ff", "name": "Fulah","original": "Fulfulde"},
    {"iso": "fi", "name": "Finnish","original": "Suomen kieli"},
    {"iso": "fj", "name": "Fijian","original": "Vosa Vakaviti"},
    {"iso": "fo", "name": "Faroese","original": "Føroyskt"},
    {"iso": "fr", "name": "French","original": "Français ; langue française"},
    {"iso": "fy", "name": "Western Frisian","original": "Frysk"},
    {"iso": "ga", "name": "Irish","original": "Gaeilge"},
    {"iso": "gd", "name": "Scottish Gaelic","original": "Gàidhlig"},
    {"iso": "gl", "name": "Galician","original": "Galego"},
    {"iso": "gn", "name": "Guarani","original": "Avañe'ẽ"},
    {"iso": "gu", "name": "Gujarati","original": "ગુજરાતી"},
    {"iso": "gv", "name": "Manx","original": "Ghaelg"},
    {"iso": "ha", "name": "Hausa","original": "هَوُسَ"},
    {"iso": "he", "name": "Hebrew","original": "עברית"},
    {"iso": "hi", "name": "Hindi","original": "हिन्दी ; हिंदी"},
    {"iso": "ho", "name": "Hiri Motu","original": "Hiri Motu"},
    {"iso": "hr", "name": "Croatian","original": "Hrvatski"},
    {"iso": "ht", "name": "Haitian","original": "Kreyòl ayisyen"},
    {"iso": "hu", "name": "Hungarian","original": "magyar"},
    {"iso": "hy", "name": "Armenian","original": "Հայերեն"},
    {"iso": "hz", "name": "Herero","original": "Otjiherero"},
    {"iso": "ia", "name": "Interlingua","original": "Interlingua"},
    {"iso": "id", "name": "Indonesian","original": "Bahasa Indonesia"},
    {"iso": "ie", "name": "Interlingue","original": "Interlingue"},
    {"iso": "ig", "name": "Igbo","original": "Igbo"},
    {"iso": "ii", "name": "Sichuan Yi","original": "ꆇꉙ"},
    {"iso": "ik", "name": "Inupiaq","original": "Iñupiaq ; Iñupiatun"},
    {"iso": "io", "name": "Ido","original": "Ido"},
    {"iso": "is", "name": "Icelandic","original": "Íslenska"},
    {"iso": "it", "name": "Italian","original": "Italiano"},
    {"iso": "iu", "name": "Inuktitut","original": "ᐃᓄᒃᑎᑐᑦ"},
    {"iso": "ja", "name": "Japanese","original": "日本語 (にほんご)"},
    {"iso": "jv", "name": "Javanese","original": "Basa Jawa"},
    {"iso": "ka", "name": "Georgian","original": "ქართული"},
    {"iso": "kg", "name": "Kongo","original": "KiKongo"},
    {"iso": "ki", "name": "Kikuyu","original": "Gĩkũyũ"},
    {"iso": "kj", "name": "Kwanyama","original": "Kuanyama"},
    {"iso": "kk", "name": "Kazakh","original": "Қазақ тілі"},
    {"iso": "kl", "name": "Greenlandic","original": "Kalaallisut ; kalaallit oqaasii"},
    {"iso": "km", "name": "Khmer","original": "ភាសាខ្មែរ"},
    {"iso": "kn", "name": "Kannada","original": "ಕನ್ನಡ"},
    {"iso": "ko", "name": "Korean","original": "한국어 (韓國語) ; 조선말 (朝鮮語)"},
    {"iso": "kr", "name": "Kanuri","original": "Kanuri"},
    {"iso": "ks", "name": "Kashmiri","original": "कश्मीरी ; كشميري"},
    {"iso": "ku", "name": "Kurdish","original": "Kurdî ; كوردی"},
    {"iso": "kv", "name": "Komi","original": "коми кыв"},
    {"iso": "kw", "name": "Cornish","original": "Kernewek"},
    {"iso": "ky", "name": "Kirghiz","original": "кыргыз тили"},
    {"iso": "la", "name": "Latin","original": "Latine ; lingua latina"},
    {"iso": "lb", "name": "Luxembourgish","original": "Lëtzebuergesch"},
    {"iso": "lg", "name": "Ganda","original": "Luganda"},
    {"iso": "li", "name": "Limburgish","original": "Limburgs"},
    {"iso": "ln", "name": "Lingala","original": "Lingála"},
    {"iso": "lo", "name": "Lao","original": "ພາສາລາວ"},
    {"iso": "lt", "name": "Lithuanian","original": "Lietuvių kalba"},
    {"iso": "lu", "name": "Luba","original": "tshiluba"},
    {"iso": "lv", "name": "Latvian","original": "Latviešu valoda"},
    {"iso": "mg", "name": "Malagasy","original": "Fiteny malagasy"},
    {"iso": "mh", "name": "Marshallese","original": "Kajin M̧ajeļ"},
    {"iso": "mi", "name": "Māori","original": "Te reo Māori"},
    {"iso": "mk", "name": "Macedonian","original": "македонски јазик"},
    {"iso": "ml", "name": "Malayalam","original": "മലയാളം"},
    {"iso": "mn", "name": "Mongolian","original": "Монгол"},
    {"iso": "mo", "name": "Moldavian","original": "лимба молдовеняскэ"},
    {"iso": "mr", "name": "Marathi","original": "मराठी"},
    {"iso": "ms", "name": "Malay","original": "Bahasa Melayu ; بهاس ملايو"},
    {"iso": "mt", "name": "Maltese","original": "Malti"},
    {"iso": "my", "name": "Burmese","original": "ဗမာစာ"},
    {"iso": "na", "name": "Nauru","original": "Ekakairũ Naoero"},
    {"iso": "nb", "name": "Norwegian Bokmål","original": "Norsk bokmål"},
    {"iso": "nd", "name": "North Ndebele","original": "isiNdebele"},
    {"iso": "ne", "name": "Nepali","original": "नेपाली"},
    {"iso": "ng", "name": "Ndonga","original": "Owambo"},
    {"iso": "nl", "name": "Dutch","original": "Nederlands"},
    {"iso": "nn", "name": "Norwegian Nynorsk","original": "Norsk nynorsk"},
    {"iso": "no", "name": "Norwegian","original": "Norsk"},
    {"iso": "nr", "name": "South Ndebele","original": "Ndébélé"},
    {"iso": "nv", "name": "Navajo","original": "Diné bizaad ; Dinékʼehǰí"},
    {"iso": "ny", "name": "Chichewa","original": "ChiCheŵa ; chinyanja"},
    {"iso": "oc", "name": "Occitan","original": "Occitan"},
    {"iso": "oj", "name": "Ojibwa","original": "ᐊᓂᔑᓈᐯᒧᐎᓐ"},
    {"iso": "om", "name": "Oromo","original": "Afaan Oromoo"},
    {"iso": "or", "name": "Oriya","original": "ଓଡ଼ିଆ"},
    {"iso": "os", "name": "Ossetian","original": "Ирон ӕвзаг"},
    {"iso": "pa", "name": "Panjabi","original": "ਪੰਜਾਬੀ ; پنجابی"},
    {"iso": "pi", "name": "Pāli","original": "पािऴ"},
    {"iso": "pl", "name": "Polish","original": "Polski"},
    {"iso": "ps", "name": "Pashto","original": "پښتو"},
    {"iso": "pt", "name": "Portuguese","original": "Português"},
    {"iso": "qu", "name": "Quechua","original": "Runa Simi ; Kichwa"},
    {"iso": "rc", "name": "Reunionese","original": "Kréol Rénioné"},
    {"iso": "rm", "name": "Romansh","original": "Rumantsch grischun"},
    {"iso": "rn", "name": "Kirundi","original": "kiRundi"},
    {"iso": "ro", "name": "Romanian","original": "Română"},
    {"iso": "ru", "name": "Russian","original": "русский язык"},
    {"iso": "rw", "name": "Kinyarwanda","original": "Kinyarwanda"},
    {"iso": "sa", "name": "Sanskrit","original": "संस्कृतम्"},
    {"iso": "sc", "name": "Sardinian","original": "sardu"},
    {"iso": "sd", "name": "Sindhi","original": "सिन्धी ; سنڌي، سندھی"},
    {"iso": "se", "name": "Northern Sami","original": "Davvisámegiella"},
    {"iso": "sg", "name": "Sango","original": "Yângâ tî sängö"},
    {"iso": "sh", "name": "Serbo-Croatian","original": "srpskohrvatski jezik ; српскохрватски језик"},
    {"iso": "si", "name": "Sinhalese","original": "සිංහල"},
    {"iso": "sk", "name": "Slovak","original": "Slovenčina"},
    {"iso": "sl", "name": "Slovenian","original": "Slovenščina"},
    {"iso": "sm", "name": "Samoan","original": "Gagana fa'a Samoa"},
    {"iso": "sn", "name": "Shona","original": "chiShona"},
    {"iso": "so", "name": "Somali","original": "Soomaaliga ; af Soomaali"},
    {"iso": "sq", "name": "Albanian","original": "Shqip"},
    {"iso": "sr", "name": "Serbian","original": "српски језик"},
    {"iso": "ss", "name": "Swati","original": "SiSwati"},
    {"iso": "st", "name": "Sotho","original": "seSotho"},
    {"iso": "su", "name": "Sundanese","original": "Basa Sunda"},
    {"iso": "sv", "name": "Swedish","original": "Svenska"},
    {"iso": "sw", "name": "Swahili","original": "Kiswahili"},
    {"iso": "ta", "name": "Tamil","original": "தமிழ்"},
    {"iso": "te", "name": "Telugu","original": "తెలుగు"},
    {"iso": "tg", "name": "Tajik","original": "тоҷикӣ ; toğikī ; تاجیکی"},
    {"iso": "th", "name": "Thai","original": "ไทย"},
    {"iso": "ti", "name": "Tigrinya","original": "ትግርኛ"},
    {"iso": "tk", "name": "Turkmen","original": "Türkmen ; Түркмен"},
    {"iso": "tl", "name": "Tagalog","original": "Tagalog"},
    {"iso": "tn", "name": "Tswana","original": "seTswana"},
    {"iso": "to", "name": "Tonga","original": "faka Tonga"},
    {"iso": "tr", "name": "Turkish","original": "Türkçe"},
    {"iso": "ts", "name": "Tsonga","original": "xiTsonga"},
    {"iso": "tt", "name": "Tatar","original": "татарча ; tatarça ; تاتارچا"},
    {"iso": "tw", "name": "Twi","original": "Twi"},
    {"iso": "ty", "name": "Tahitian","original": "Reo Mā`ohi"},
    {"iso": "ug", "name": "Uighur","original": "Uyƣurqə ; ئۇيغۇرچ"},
    {"iso": "uk", "name": "Ukrainian","original": "українська мова"},
    {"iso": "ur", "name": "Urdu","original": "اردو"},
    {"iso": "uz", "name": "Uzbek","original": "O'zbek ; Ўзбек ; أۇزبېك"},
    {"iso": "ve", "name": "Venda","original": "tshiVenḓa"},
    {"iso": "vi", "name": "Viêt Namese","original": "Tiếng Việt"},
    {"iso": "vo", "name": "Volapük","original": "Volapük"},
    {"iso": "wa", "name": "Walloon","original": "Walon"},
    {"iso": "wo", "name": "Wolof","original": "Wollof"},
    {"iso": "xh", "name": "Xhosa","original": "isiXhosa"},
    {"iso": "yi", "name": "Yiddish","original": "ייִדיש"},
    {"iso": "yo", "name": "Yoruba","original": "Yorùbá"},
    {"iso": "za", "name": "Zhuang","original": "Saɯ cueŋƅ ; Saw cuengh"},
    {"iso": "zh", "name": "Chinese","original": "中文, 汉语, 漢語"},
    {"iso": "zu", "name": "Zulu","original": "isiZulu"}
];

const __niveau = {
    "maternel": "Langue maternelle",
    "debutant": "Débutant",
    "intermediaire": "Intermédiaire",
    "avance": "Avancé",
    "expert": "Expert",
    "bilingue": "Bilingue",
};

const SignupPersonnal = ({ key, setCurrentStep, currentStep, step, modale }:SignupPersonnal) => {

    const [langues, setLangues]:[{lang:number, lvl:number}[], React.Dispatch<{lang:number, lvl:number}[]>] = React.useState([{lang:0, lvl:0}]);
    
    React.useEffect(() => {
        initSelectNone();
    }, []);

    return (
        <>
            {/* Taille */}
            <TextualInput required={true} formFor={__modales[modale]?.formId} fieldName="height" label="forms->signup->height->label" autoComplete="height" classes={["sm:col-span-4"]} type="number" labelClasses={["block text-sm font-medium text-gray-700"]} inputClasses={["mt-1"]}/>
            {/* Poids */}
            <TextualInput required={true} formFor={__modales[modale]?.formId} fieldName="weight" label="forms->signup->weight->label" autoComplete="weight" classes={["sm:col-span-4"]} type="number" labelClasses={["block text-sm font-medium text-gray-700"]} inputClasses={["mt-1"]}/>
            {/* Type de physique */}
            <SelectCheck required={true} formFor={__modales[modale]?.formId} fieldName="type_physic" label="forms->signup->type_physic->label" classes={["sm:col-span-4"]} options={Object.keys(__types).map(key => {return {"label": __types[key], "value": key};})}/>
            {/* Couleur des cheveux */}
            <SelectCheck required={true} formFor={__modales[modale]?.formId} fieldName="hair_color" label="forms->signup->hair_color->label" classes={["sm:col-span-4"]} options={Object.keys(__cheveux).map(key => {return {"label": __cheveux[key], "value": key};})}/>
            {/* Couleur des yeux */}
            <SelectCheck required={true} formFor={__modales[modale]?.formId} fieldName="eyes_color" label="forms->signup->eyes_color->label" classes={["sm:col-span-4"]} options={Object.keys(__yeux).map(key => {return {"label": __yeux[key], "value": key};})}/>
            <span className="sm:col-span-4"></span>
            {/* Photos */}

            <h3 className="sm:col-span-12 my-2 text-pink-900 font-bold mt-4">{i18next.t("forms->signup->langues")}</h3>
            {/* Langues */}
            {/* EXPLAIN - Si on ajoute une langue cette section apparaît */}
            {langues.map((_lang:{lang:number, lvl:number}, key:number) => 
                <div className="col-span-12 flex gap-x-3 items-center signup-lang" key={key}>
                    <SelectCheck
                        // label="forms->signup->choose_lang->label"
                        label={false}
                        fieldName={`signup_add_iso_langue_${key}`}
                        options={__langue.map((lang, lng_key:number) => {
                            return {value: lng_key, label: `${lang.name} (${lang.original})`, authorized: true};
                        })}
                        defaultSelected={_lang.lang}
                        classes={["mt-1 align-middle inline-flex w-1/5 flex-auto"]}
                        onChange={(e) => {
                            let temp = langues;
                            temp[key].lang = e;
                            console.log(temp);
                            setLangues(temp);
                        }}
                    />
                    <SelectCheck
                        // label="forms->signup->choose_lang_lvl->label"
                        label={false}
                        fieldName={`signup_add_niveau_langue_${key}`}
                        classes={["mt-1 align-middle inline-flex w-1/5 flex-auto"]}
                        defaultSelected={_lang.lvl}
                        options={Object.values(__niveau).map((label:string, lvl_key:number) => {return {value: lvl_key, label: label};})}
                        onChange={(e) => {
                            let temp = langues;
                            temp[key].lvl = e;
                            console.log(temp);
                            setLangues(temp);
                        }}
                    />
                    {/* EXPLAIN - Si ce bouton est pressé alors la langue est ajoutée et la section disparaît */}
                    <span
                        className="align-middle inline-flex w-1/5 flex-auto text-center mt-1 text-pink-800 hover:text-pink-900 hover:cursor-pointer text-md font-normal hover:font-semibold"
                        onClick={(e) => {setLangues(langues.splice(key, 1));}}
                    >{i18next.t("forms->signup->remove_lang->label")}</span>
                </div>
            )}
            <span className="sm:col-span-4 py-2 my-2"></span>
            <Button icon={{first: false, name: "plus"}} classes={["col-span-4 justify-center my-2"]} type={"button"}
                onClick={(e) => {
                    console.log([...langues, {lang:0, lvl:0}]);
                    setLangues([...langues, {lang:0, lvl:0}]);
                }}
            >{i18next.t("forms->signup->add_lang->label")}</Button>
            <span className="sm:col-span-4 py-2 my-2"></span>
            {/* add langue */}
            <span className="sm:col-span-2 py-2"></span>
        <Button classes={["sm:col-span-4 mt-2 block text-center"]} onClick={() => setCurrentStep(step - 1)}>{i18next.t("forms->signup->retour_button->label")}</Button>
            <Button
                classes={["sm:col-span-4 mt-2 block text-center"]}
                type="submit"
                onClick={(e) => signupCheckSubmit(e, currentStep, modale, setCurrentStep)}
            >{i18next.t("forms->signup->creer_button->label")}</Button>
            {/* <span className="sm:col-span-2 mt-2"></span> */}
        </>
    );

};

interface SignupPersonnal {
    key?: any;
    setCurrentStep: Function;
    currentStep?: number;
    step: number;
    modale: string;
};

interface SignupPersonnalState {
    langues: {lang:number, lvl:number}[];
}

export default hot(module)(SignupPersonnal);