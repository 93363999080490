import { configureStore } from '@reduxjs/toolkit';
import pageSlice from '../features/page/pageSlice';
import userSlice from '../features/user/userSlice';

const store = configureStore({
    reducer: {
        page: pageSlice,
        user: userSlice
    }
});

export default store;