import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';

const Divider = (__datas:Divider = Object()) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    if(__datas.type == "with-label") {
        return (
            <div className={`relative ${__datas.classes?.join(' ')}`}>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                    <span className={`px-2 bg-white text-sm text-gray-500 ${__datas.textClasses?.join(' ')}`}>{t(__datas.label || "")}</span>
                </div>
            </div>
        );
    }
    
    return (
        <div className={`relative ${__datas.classes?.join(' ')}`}>
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
            </div>
        </div>
    );
};

interface Divider {
    key?: any;
    type?: string;
    label?: string;
    classes?: Array<string>;
    textClasses?: Array<string>;
};

export default hot(module)(Divider);