import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';

import HeroHeader from "../components/HeroHeader";

import { initSelectNone } from '../components/functions/inits';

const HomePage = (__datas:HomePage = {}) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <main data-page="home" className="custom-scrollbar">
            <HeroHeader/>
        </main>
    );
};

interface HomePage {
    key?: any;
};

export default hot(module)(HomePage);