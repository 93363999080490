import React from "react";
import { hot } from "react-hot-loader";
import { Entity_Annonce } from "../interfaces/entities";

import AnnonceGeneral from "./annonce-general";
import AnnonceDetails from "./annonce-details";

import "./annonce-infos.css";

const AnnonceInfos = ({ annonce }:AnnonceInfos) => {

    return (
        <div id="annonce-infos">
            <AnnonceGeneral annonce={annonce}/>
            <AnnonceDetails annonce={annonce}/>
        </div>
    );
}

interface AnnonceInfos {
    annonce: Entity_Annonce;
};

export default hot(module)(AnnonceInfos);