import React from "react";
import { initSelectNone } from "../components/functions/inits";
import { __backURLs } from "../components/functions/const";
import LoadingGIF from "../components/Basics/LoadingGIF";
import { Entity_Annonce } from "../components/interfaces/entities";
import { hot } from "react-hot-loader";
import AnnonceBandeau from "../components/Annonce/annonce-Bandeau";
import AnnonceInfos from "../components/Annonce/annonce-infos";
import AnnonceFAQ from "../components/Annonce/annonce-faq";
import { ReduxStore } from "../components/interfaces/redux";
import { useSelector } from 'react-redux';

const AnnoncePage = (datas:AnnoncePage) => {

    const [annonce, setAnnonce]:[any , React.Dispatch<any>] = React.useState(null);
    const [loading, setLoading]:[boolean , React.Dispatch<boolean>] = React.useState(true);
    const user = useSelector((state:ReduxStore) => state.user);
    const page = useSelector((state:ReduxStore) => state.page);

    React.useEffect(() => {
        console.log("annonce chargée");
    }, [annonce]);

    React.useEffect(() => {
        setLoading(true);
        let req_init:RequestInit = {
            "method": user.logged ? "POST" : "GET"
        };
        if(user.logged) {
            req_init["body"] = JSON.stringify({
                "user": user.db_id
            });
        }
        fetch(process.env.BACK_URL + __backURLs.api_get_annonce.url.replace('{hashid}', location.pathname.split('/').slice(-1)[0]), req_init)
        .then(res => res.json())
        .then((res:{status:string, message:string, datas:Entity_Annonce}) => {
            setAnnonce(res.datas);
            setLoading(false);
        })
        .catch(err => console.log(err));
    }, [user.logged, page]);

    React.useEffect(() => {
        initSelectNone();
    }, []);

    if(annonce == null) {
        return (
            <main data-page="annonce" className="no-scrollbar">
                <div className="loading-mask">
                    <LoadingGIF id="annonce-load"/>
                </div>
            </main>
        );
    }

    return (
        <main data-page="annonce" className="no-scrollbar">
            {loading ? <div className="loading-mask">
                <LoadingGIF id="annonce-change"/>
            </div> : null}
            <AnnonceBandeau annonce={annonce}/>
            <div id="annonce-details" className="custom-scrollbar">
                <AnnonceInfos annonce={annonce}/>
                <AnnonceFAQ annonce={annonce}/>
            </div>
        </main>
    );
};

interface AnnoncePage {

};

export default hot(module)(AnnoncePage);