import { resolveURL } from "./functions";

export const urls = {
    "headerLogoHome": {
        "slug": "home",
        "title": "nav_links->home",
        "libelle": "nav_links->home",
        "url": resolveURL("/"),
        "id": "header-logo",
        "img": {
            "src": new URL("../assets/images/header-logoc7.png", import.meta.url),
            "alt": "Logo C7",
            "title": "Logo C7"
        },
        "type": "image",
    },
    "home": {
        "slug": "home",
        "title": "nav_links->home",
        "libelle": "nav_links->home",
        "url": resolveURL("/"),
        "classes": ["nav-item"]
    },
    "404": {
        "slug": "404",
        "title": "nav_links->404",
        "libelle": "nav_links->404",
        "url": resolveURL("/404"),
        "classes": ["nav-item"]
    },
    "annonces": {
        "slug": "annonces",
        "title": "nav_links->annonces",
        "libelle": "nav_links->annonces",
        "url": resolveURL("/annonces"),
        "classes": ["nav-item"],
    },
    "c7plus": {
        "slug": "c7plus",
        "title": "nav_links->c7plus",
        "libelle": "nav_links->c7plus",
        "url": resolveURL("/c7plus"),
        "classes": ["nav-item"],
    },
    "annonce": {
        "slug": "annonce",
        "dynamic": true,
        "title": "nav_links->annonce",
        "libelle": "nav_links->annonce",
        "url": resolveURL("/annonces/{hashid}"),
        "page": resolveURL("/annonces/{hashid}"),
        "classes": ["nav-item"],
    },
    "portfolio": {
        "slug": "portfolio",
        "title": "nav_links->portfolio",
        "libelle": "nav_links->portfolio",
        "url": resolveURL("/portoflio"),
        "classes": ["nav-item"],
    },
    "help": {
        "slug": "help",
        "title": "nav_links->help",
        "libelle": "nav_links->help",
        "url": resolveURL("/help"),
        "classes": ["nav-item"],
    },
    "myspace": {
        "unlogged": "home",
        "visitorAlt": "signup",
        "slug": "myspace",
        "title": "nav_links->myspace",
        "libelle": "nav_links->myspace",
        "url": resolveURL("/myspace"),
        "type": "red-link",
        "classes": ["nav-item"],
    },
    "logout": {
        "loggedAlt": "login",
        "action": "logout",
        "slug": "logout",
        "title": "nav_links->logout",
        "libelle": "nav_links->logout",
        "url": resolveURL("/logout"),
        "classes": ["nav-item"],
    },
    "test": {
        "slug": "test",
        "title": "Test",
        "libelle": "Test",
        "url": resolveURL("/test"),
        "classes": ["nav-item"],
    },
    "terms": {
        "slug": "terms",
        "title": "nav_links->terms",
        "libelle": "nav_links->terms",
        "url": resolveURL("/terms"),
        "classes": ["nav-item"],
    },
    // Modale
    "signup": {
        "loggedAlt": "myspace",
        "modale": "signup",
        "slug": "signup",
        "title": "nav_links->signup",
        "libelle": "nav_links->signup",
        "url": resolveURL("/signup"),
        "classes": ["nav-item"],
    },
    "signin": {
        "loggedAlt": "logout",
        "modale": "signin",
        "slug": "signin",
        "title": "nav_links->signin",
        "libelle": "nav_links->signin",
        "url": resolveURL("/signin"),
        "classes": ["nav-item"],
    },
    "forgot_pwd": {
        "modale": "forgot_pwd",
        "slug": "forgot_pwd",
        "title": "nav_links->forgot_pwd",
        "libelle": "nav_links->forgot_pwd",
        "url": resolveURL("/forgot_pwd"),
        "classes": ["nav-item"],
    },
    "image_view": {
        "modale": "image_view",
        "slug": "image_view",
        "title": "forms->image_view->title",
        "libelle": "forms->image_view->libelle",
        "url": resolveURL("/img_view"),
        "classes": []
    },
    "annonce_resolve_entity": {
        "modale": "annonce_resolve_entity",
        "slug": "annonce_resolve_entity",
        "title": "nav_links->annonce_resolve_entity",
        "libelle": "nav_links->annonce_resolve_entity",
        "url": resolveURL("/annonce_resolve_entity"),
    },
}

export const pages:PagesList = Object.fromEntries(
    Object.values(urls).map(nav => [nav.url, nav])
);

interface PagesList {
    slug?: string;
    title?: string;
    libelle?: string;
    url?: string;
    page?: string;
    id?: string;
    img?: {
        src: string;
        alt: string;
        title: string;
    };
    type?: string;
    dynamic?: string;
    unlogged?: string;
    visitorAlt?: string;
    loggedAlt?: string;
    modale?: string;
    classes?: string[];
}