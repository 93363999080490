/**
 * @file Page informations and useful tools
*/


import { _error, _log, _trace } from "./const";
import { isElement, isInt, isObject, isString } from "./is-type";

export const avoirURLBase = () => {
    _log("avoirURLBase");
    return window.location.origin + (window.location.host == "localhost" ? '/c7_back' : '');
};

export const avoirRelativeURL = () => {
    _log("avoirRelativeURL");
    return window.location.pathname;
};

/**
 * 
 * @param {string} _url 
 * @returns boolean|void
*/
export const redirectTo = (_url) => {
    if(typeof _url != "string") {
        return false;
    }
    if(_url.length == 0) {
        return false;
    }
    window.location.href = _url;
}

export const get_url_params = () => {
    if(typeof window != undefined) {
        return {
            pathname: get_url_pathname(),
            search: get_url_search(true),
            hash: get_url_hash(),
        };
    }
    else {
        return {};
    }
}

// protocol + :// + host + pathname + search + hash
// protocol + :// + hostname + port + pathname + search + hash

export const get_url_port = () => {if(typeof window != undefined) {return window.location.port;}else {return {};}};
export const get_url_pathname = () => {if(typeof window != undefined) {return window.location.pathname;}else {return {};}};
export const get_url_search = (as_array = true) => {
    if(typeof window != undefined) {
        return as_array ? Object.fromEntries(window.location.search.replace('?', '').split('&').map(elem => elem.split('='))) : window.location.search;
    }
    else {return {};}
};
export const get_url_hash = () => {if(typeof window != undefined) {return window.location.hash;}else {return {};}};

export const url_from_params = (_params) => {
    if(!isObject(_params)) {
        return '';
    }
    let _retour = window.location.pathname + (Object.keys(_params).length > 0 ? '?' : '');
    Object.keys(_params).forEach((_param, _i) => {
        if(_params[_param]) {
            _retour += `${_i > 0 ? '&' : ''}${_param}=${_params[_param]}`;
        }
    })
    return _retour;
}

export const indexElement = (_elem) => {
    if(isElement(_elem)) {
        let _parent = _elem?.parentElement;
        if(isElement(_parent)) {
            let _index = 0;
            while(_parent.children[_index]) {
                if(_parent.children[_index] === _elem) {
                    return _index;
                }
                _index++;
            }
        }
        else {
            return -1;
        }
    }
    return -1;
}

export const randomString = function(length = 10, letters = true, numbers = true)
{
    let characters = "";
    if(letters == true) {
        characters += "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    }
    if(numbers == true) {
        characters += "0123456789"
    }
    let charactersLength = characters.length;
    let randomString = '';
    for (let i = 0; i < length; i++) {
        randomString += characters[(Math.random() * charactersLength - 1).toFixed(0)];
    }
    return randomString;
}

export const randColor = (rgba = null) => {
    let _1 = Math.floor(Math.random() * 255);
    let _2 = Math.floor(Math.random() * 255);
    let _3 = Math.floor(Math.random() * 255);
    return `#${[_1, _2, _3].map(num => num > 15 ? num.toString(16) : `0${num.toString(16)}`).join('')}${(rgba) ? (rgba * 2.55).toFixed(0).toString() : ''}`;
}

export const preventDefault = (e) => e.preventDefault();

export const strToDom = function(str) {
    try {
        return document.createRange().createContextualFragment(str);
    }
    catch(_err) {
        _trace(_err);
        _error(_err);
        return str;
    }
}

/**
 * @param {string} html representing a single element
 * @return {Element}
*/
export const htmlToElement = function(html:string) {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template?.append(strToDom(html));
    return template.content.firstChild;
}

/**
 * @param {string} html representing any number of sibling elements
 * @return {NodeList} 
*/
export const htmlToElements = function(html) {
    var template = document.createElement('template');
    template?.append(strToDom(html));
    return template.content.childNodes;
}

export const getOS = function() {
    if(typeof navigator != "undefined") {
        if (navigator.appVersion.indexOf("Win") != -1) {
            return "Windows";
        }
        if (navigator.appVersion.indexOf("Mac") != -1) {
            return "MacOS";
        }
        if (navigator.appVersion.indexOf("Linux") != -1) {
            return "Linux";
        }
    }
    return undefined;
}

export const detectOS = getOS() ?? "Unknown OS";

export const scrollX = function(_dx = null, _elems:Array<HTMLElement>|null = null) {
    if(_dx == null || _dx == 0 || _elems == null || !Array.isArray(_elems) || _elems.length == 0) {
        return false;
    }
    _elems.forEach(_elem => {
        _elem.scrollLeft += _dx;
    });
}

export const scrollY = function(_dy = null, _elems:Array<HTMLElement>|null = null) {
    if(_dy == null || _dy == 0 || _elems == null || !Array.isArray(_elems) || _elems.length == 0) {
        return false;
    }
    _elems.forEach(_elem => {
        _elem.scrollTop += _dy;
    });
}

export const insertFirst = function(newNode, parent) {
    // console.log("insertFirst");
    // console.log(newNode);
    // console.log(parent);
    if((newNode instanceof Node || newNode instanceof Element) && parent instanceof Element) {
        if(parent.childElementCount == 0) {insertLast(newNode, parent);}
        else {
            insertBefore(newNode, parent.children[0]);
        }
    }
}
export const insertLast = function(newNode, parent) {
    // console.log("insertLast");
    // console.log(newNode);
    // console.log(parent);
    if((newNode instanceof Node || newNode instanceof Element) && parent instanceof Element) {
        parent?.appendChild(newNode);
    }
}

export const insertAfter = function(newNode, existingNode) {
    // console.log("insertAfter");
    // console.log(newNode);
    // console.log(existingNode);
    if((newNode instanceof Node || newNode instanceof Element) && existingNode instanceof Element) {
        existingNode?.parentElement?.insertBefore(newNode, existingNode?.nextSibling);
    }
}

export const insertBefore = function(newNode, existingNode) {
    // console.log("insertBefore");
    // console.log(newNode);
    // console.log(existingNode);
    if((newNode instanceof Node || newNode instanceof Element) && existingNode instanceof Element) {
        existingNode?.parentElement?.insertBefore(newNode, existingNode);
    }
}

export const filter_object = function(obj, predicate) {
    return Object.keys(obj)
        .filter( key => predicate(obj[key]) )
        .reduce( (res, key) => (res[key] = obj[key], res), {} );
}

export const resolveEntityPath = function(_entity:any = null, _path:any = null) {
    // console.log("resolveEntityPath");
    if(!isObject(_entity) || !isString(_path)) {return null;}
    _path = (_path ?? "").split('__');
    return _path.length > 1 ? resolveEntityPath(_entity[_path[0] ?? 0], _path.slice(1).join('__')) : _entity[_path[0] ?? 0] ?? null;
}

export const loadingGIF = function(_params:any = {}, _from_remote = false) {
    if(_from_remote == false) {
        return `
            <div ${_params.id && `id="${_params.id}"` || ""} class="loading-gif ${_params.classes && _params.classes.join(' ') || ""}">
                <div class="loader-circle"></div>
                <div class="loader-bar"></div>
            </div>
        `
    };
}

/**
 * @description Remonte les balises jusqu'au critère recherché
*/
export const resolveUpUntil = function(_elem:any|null = null, _critere = null, _type = "class")
{
    if(_elem == null || _critere == null) {return false;}
    if(!isElement(_elem)) {return null;}
    if(!isString(_critere) && (_type == "class" || _type == "id" || _type == "name")) {return false;}

    do {
        switch(_type) {
            case "id": if(_elem.id == _critere) {return _elem;}
            case "name": if(_elem.name == _critere) {return _elem;}
            case "class":
            default:
                if(_elem?.classList.contains(_critere)) {return _elem;}
        }
        _elem = _elem?.parentElement || null;
    } while(_elem?.parentElement);
    return _elem;
}

export const getFullDate = function(_timestamp = Date.now(), _need = true) {
    if(!isInt(_timestamp) && _need == false) {return false;}
    if(!isInt(_timestamp)) {_timestamp = Date.now();}
    let _str_date = `${new Date(_timestamp).getFullYear()}-`;
    _str_date += `${new Date(_timestamp).getMonth() + 1 < 10 ? '0' : ''}${new Date(_timestamp).getMonth() + 1}-`;
    _str_date += `${new Date(_timestamp).getDate() < 10 ? '0' : ''}${new Date(_timestamp).getDate()}`;
    return _str_date;
}

export const getFullTime = function(_timestamp) {

}

export const getFullDateTime = function(_timestamp) {

}

export const isPressed = function(__key:string|null = null, __event:KeyboardEvent|null = null) {
    if(__key == null || __event == null) {return false;}
    switch(__key) {
        case "Enter": return __event.type == "keydown" && (["Enter", "NumpadEnter"].indexOf(__event.code) > -1 || __event.key == "Enter" || __event.keyCode == 13);
        case "Tab": return __event.type == "keydown" && (__event.code == "Tab" || __event.key == "Tab" || __event.keyCode == 9);
        default: return false;
    }
}

export const isReleased = function(__key:string|null = null, __event:KeyboardEvent|null = null) {
    if(__key == null || __event == null) {return false;}
    switch(__key) {
        case "Enter": return __event.type == "keyup" && (["Enter", "NumpadEnter"].indexOf(__event.code) > -1 || __event.key == "Enter" || __event.keyCode == 13);
        case "Tab": return __event.type == "keyup" && (__event.code == "Tab" || __event.key == "Tab" || __event.keyCode == 9);
        default: return false;
    }
}

// TEST
export const wheeze = function(_elem:Element|null = null, __type:string = "horizontal") {
    if(_elem instanceof Element) {
        let _solved_class = !__type || __type == "horizontal" ? "h-wheeze" : __type == "vertical" ? "v-wheeze" : __type == "pulse" ? "pulse-wheeze" : "h-wheeze";
        _elem.classList.add(_solved_class);
        let _time = setTimeout(function() {
            _elem.classList.remove(_solved_class);
        }, 1000);
        clearTimeout(_time);
    }
    return false;
}

export const elemAttributes = function(__elem:Element|null = null, __as_object = false) {
    if(!(__elem instanceof Element)) {return [];}
    return __as_object ? Object.fromEntries(__elem.getAttributeNames().map(_att => [_att, __elem.getAttribute(_att)])) : __elem.getAttributeNames();
}

export const em_reverse_str = function(str:string|null = null) {
    if(str == null) {return "";}
    if(typeof str == "string") {
        return str.split('').reverse().join('');
    }
    return "";
}

export const delete_cookie = function(name:string, path?:string, domain?:string ) {
    if( get_cookie( name ) ) {
        document.cookie = name + "=" +
        ((path) ? ";path="+path:"")+
        ((domain) ?";domain="+domain:"") +
        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

export const get_cookie = function(name:string) {
    return document.cookie.split(';').some(c => {
        return c.trim().startsWith(name + '=');
    });
}