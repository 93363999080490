import { hot } from 'react-hot-loader';

import React from "react";

import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';
import { _log, _trace } from '../../functions/const';
import { ReduxStore } from '../../interfaces/redux';
import Divider from '../../Basics/Divider';
import Link from '../../Link';
import { urls } from '../../const';
import { __modales } from '../index';

const ModaleForgotPasswordContent = (__datas:ModaleForgotPasswordContent = {}) => {

    const modale = useSelector((store:ReduxStore) => store.page.activeModale);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {
        
    }, [i18n.language]);

    if(modale == null) {
        return <></>;
    }

    return (
        <>
            <form id={__modales[modale].formId} name="forgot_pwd-modale" className="space-y-8" key={__datas.key}
                onSubmit={(e) => {
                    e.preventDefault();
                    console.log(e);
                }}
            >
            </form>
            <Divider classes={["block", "my-4"]}/>
            <div className="block w-full text-center pt-4">
                <span>{t("forms->forgot_pwd->have_an_account")}</span>
                <Link {...{...urls.signin, title: "forms->forgot_pwd->signin", libelle: "forms->forgot_pwd->signin", classes: ["text-pink-800", "hover:text-pink-900", "hover:font-bold"]}}>{t("forms->forgot_pwd->signin")}</Link>
            </div>
        </>
    );
};

interface ModaleForgotPasswordContent {
    key?: any;
};

export default hot(module)(ModaleForgotPasswordContent);