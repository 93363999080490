import { __modales } from ".";
import store from "../../app/store";
import { setModale, setModaleGlobalEnv, setModaleSpecificEnv, setPage } from "../../features/page/pageSlice";
import { urls } from "../const";
import { resolveFormElementValidity } from "../functions";
import { resolve_annonce_apply } from "../functions/annonce";
import { _error, _log, _trace } from "../functions/const";
import { selectOne } from "../functions/selectors";
import __signin__ from "../functions/signin";
import __signup__ from "../functions/signup";
import { Modale_PostAction } from "../interfaces/redux";
import { __signup_elements, __signup_etapes } from "./const";

export const checkStepValidity = function(__step:number|null = null, __modale:string|null = null, __forceReport:boolean = false, __force:boolean = false) {
    // _trace("checkStepValidity(" + __step + ")");
    // if(__force == true) {return true;}
    if(__modale == null) {return false;}
    const _steps = resolveSteps(__modale);
    const _elements = resolveElements(__modale);
    if(_steps == null || _elements == null) {return false;}
    if(__step == null || __step < 0 && __step >= _steps.length || __step >= _elements.length) {return false;}
    const _retour = _elements[__step].map(name => 
        resolveFormElementValidity(__modales[__modale].formId, name)
    ).filter(res => res);
    // console.log(_retour);
    __forceReport &&
    selectOne(`#${__modales[__modale].formId} #stepper-part_${_steps[0]} .invalid:not(.not-focusable)`) != null &&
    selectOne(`#${__modales[__modale].formId}`).reportValidity();
    return _retour.length == _elements[__step].length;
}

function resolveSteps(__modale:string|null = null) {
    if(__modale == null) {return null;}
    switch(__modale)
    {
        case "signup": return __signup_etapes;
        default: return null;
    }
}

function resolveElements(__modale:string|null = null) {
    if(__modale == null) {return null;}
    switch(__modale)
    {
        case "signup": return __signup_elements;
        default: return null;
    }
}

export function resolveModaleSuccess(__modale:string|null = null, __glob:Modale_PostAction|null = null, __spec:Modale_PostAction|null = null) {
    _log("resolveModaleSuccess");
    console.log(__modale);
    console.log(__glob);
    console.log(__spec);

    if([__glob, __spec].filter(el => el).length == 0) {
        store.dispatch(setModale(null));
        return;
    }
    [__glob, __spec].filter(el => el).forEach(_env => {processModalePostAction(_env?.onSuccess, resolveDatas(_env?.datas));});
}

export function resolveModaleError(__modale:string|null = null, __glob:Modale_PostAction|null = null, __spec:Modale_PostAction|null = null) {
    _log("resolveModaleError");
    console.log(__modale);
    console.log(__glob);
    console.log(__spec);

    if([__glob, __spec].filter(el => el).length == 0) {
        store.dispatch(setModale(null));
        return;
    }
    [__glob, __spec].filter(el => el).forEach(_env => {processModalePostAction(_env?.onError, resolveDatas(_env?.datas));});
}

function resolveDatas(__datas:any = null) {
    _log("resolveDatas");
    console.log(__datas);
    return Object.fromEntries(Object.keys(__datas).map(data => [data, __datas[data] ?? resolveData(data)]));
}

function resolveData(__data:any = null) {
    _log("resolveData");
    
    switch(__data) {
        case "user": return store.getState().user;
        default: return null;
    }
}

function processModalePostAction(__action:string|null = null, __datas:any = null) {
    _log("processModalePostAction");
    
    try {
        if(__action == null) {return false;}

        const [ __type, __value ] = __action.split(":");

        console.log(__type);
        console.log(__value);

        switch(__type)
        {
            case "modale":
                console.log("Change modale : " + __value);
                store.dispatch(setModale(__value));
                store.dispatch(setModaleGlobalEnv(__datas.modaleGlobalEnv ?? null));
                store.dispatch(setModaleSpecificEnv(__datas.modaleSpecificEnv ?? null));
                break;
            case "action":
                store.dispatch(setModale(null));
                console.log("Action : " + __value);
                switch(__value) {
                    case "resolve_annonce_apply":
                        console.log("resolve_annonce_apply");
                        console.log(__datas);
                        if((__datas.user.candidatures ?? []).find(candidature => candidature.annonce === __datas.annonce.db_id)) {return;}
                        resolve_annonce_apply(__datas.user, __datas.annonce);
                        break;
                    case "close":
                        store.dispatch(setModale(null));
                        break;
                    case "signup":
                        __signup__();
                        break;
                    case "login":
                        __signin__();
                        break;
                    default:
                        break;
                }
                break;
            default:
                console.log("Action inconnue : " + __type);
                break;
        }

        return true;
    }
    catch(_err) {
        _error("processModalePostAction", _err);
        return false;
    }
}