export const activities_scheme:ProfilePartScheme = [[[]]];

export const animals_scheme:ProfilePartScheme = [[[]]];

export const cv_scheme:ProfilePartScheme = [[[]]];

export const dressing_scheme:ProfilePartScheme = [[[]]];

export const generals_scheme:ProfilePartScheme = [
    [
        [
            {type: 'titre', label: "pages->myspace->display->generals->generals->label"},
            {type: 'information', resolve: 'nom_famille', label: "pages->myspace->display->generals->generals->surname"},
            {type: 'information', resolve: 'prenom', label: "pages->myspace->display->generals->generals->firstname"},
            {type: 'information', resolve: 'nom_artiste', label: "pages->myspace->display->generals->generals->artist_name"},
            {type: 'information', resolve: 'nationalite', label: "pages->myspace->display->generals->generals->nationality"},
            {type: 'information', resolve: 'date_naissance', label: "pages->myspace->display->generals->generals->birthdate"},
            {type: 'information', resolve: 'mobile', label: "pages->myspace->display->generals->generals->mobile"},
            {type: 'information', resolve: 'main_address_str', label: "pages->myspace->display->generals->generals->address"},
            {type: 'information', resolve: 'driving_licenses_str', label: "pages->myspace->display->generals->generals->driving_licenses"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->generals->professionnal_status"},
        ],
        [
            {type: 'titre', label: "pages->myspace->display->generals->physic->label"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->physic->physic_type"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->physic->hair_color"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->physic->eyes_color"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->physic->facial_hair"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->physic->distinctive_signs"},
        ],
        [
            {type: 'titre', label: "pages->myspace->display->generals->inscribed_as->label"},
            {type: 'toggle', label: "pages->myspace->display->generals->inscribed_as->figuration"},
            {type: 'toggle', label: "pages->myspace->display->generals->inscribed_as->pet_owner"},
            {type: 'toggle', label: "pages->myspace->display->generals->inscribed_as->silhouette"},
            {type: 'toggle', label: "pages->myspace->display->generals->inscribed_as->vehicle_owner"},
            {type: 'toggle', label: "pages->myspace->display->generals->inscribed_as->actor"},
            {type: 'button', label: "pages->myspace->display->generals->inscribed_as->create_c7_relationship"},
        ],
    ],
    [
        [
            {type: 'titre', label: "pages->myspace->display->generals->measurements->label"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->height"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->weight"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->pull"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->pantalon"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->jean"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->shoes"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->gloves"},
        ],
        [
            {type: 'titre', label: ''},
            {type: 'categorie', label: "pages->myspace->display->generals->measurements->sizes->label"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->sizes->chest"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->sizes->waist"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->sizes->hips"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->sizes->head"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->sizes->neck"},
        ],
        [
            {type: 'titre', label: ''},
            {type: 'categorie', label: "pages->myspace->display->generals->measurements->lengths->label"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->lengths->back_width"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->lengths->shoulder_width"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->lengths->arm_length"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->lengths->arm_to_wrist"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->lengths->waist_to_floor"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->measurements->lengths->crotch_to_floor"},
        ],
    ],
    [
        [
            {type: 'titre', label: "pages->myspace->display->generals->languages->label"},
            {type: 'information', resolve: null, label: "pages->myspace->display->generals->languages->mother_tongue"},
        ],
        [
            {type: 'titre', label: ''},
        ],
        [],
    ],
];

export const vehicles_scheme:ProfilePartScheme = [[[]]];

// interface ProfileBoxPartInformationScheme {
//     type?: string;
//     label?: string;
//     resolve?: string|null;
// };

// interface ProfileBoxPartScheme extends Array<ProfileBoxPartInformationScheme> {

// };

// interface ProfileBoxScheme extends Array<ProfileBoxPartScheme> {

// };

interface ProfileBoxPartInformationScheme {
    type?: string;
    label?: any;
    resolve?: string|null;
}

interface ProfileBoxPartScheme extends Array<ProfileBoxPartInformationScheme> {

}

interface ProfileBoxScheme extends Array<ProfileBoxPartScheme> {

}

interface ProfilePartScheme extends Array<ProfileBoxScheme> {

}