import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';

import { initSelectNone } from '../../functions/inits';
import { ElementHTML } from '../../interfaces/elements';

const WhiteButton = (__datas:WhiteButton = {}) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <div
            className={`cursor-pointer mt-3 sm:mt-0 sm:ml-3 flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-pink-700 bg-pink-100 hover:bg-pink-200 md:py-4 md:text-lg md:px-10 ${(__datas.classes || []).join(' ')}`}>
            {__datas.children}
        </div>
    );
};

interface WhiteButton extends ElementHTML {
    key?: any;
    children?: any;
};

export default hot(module)(WhiteButton);
