import i18next from "i18next";
import store from "../../app/store";
import { setModale, setSignupActivity } from "../../features/page/pageSlice";
import { setUser } from "../../features/user/userSlice";
import { extractValue, resolveElementType, resolveXHRBody } from "../functions";
import { APIResponse, APISignupResponse } from "../interfaces/elements";
import { Entity_User } from "../interfaces/entities";
import { __modales } from "../Modale";
import { __signup_elements, __signup_etapes } from "../Modale/const";
import { checkStepValidity, resolveModaleError, resolveModaleSuccess } from "../Modale/functions";
import { _error, _trace, __backURLs } from "./const";
import { _fetch } from "./fetch";
import { getById, selectAll, selectOne } from "./selectors";

export default function __signup__(__form:string|null = null, __type:string|null = null) {
    if(__type != "refresh" && (__form == null || !document?.forms[__form])) {return false;}
    if(__form != null && document?.forms[__form].reportValidity() == false) {
        return false;
    }
    
    spinAppear(__form);
    
    selectOne(`#${__form} button[type="submit"] .animate-spin`)?.setAttribute('visibility', 'visible');
    
    if(__form != null && __type == "google") {
        return signupGoogle(__form);
    }
    if(__form != null && __type == "facebook") {
        return signupFacebook(__form);
    }
    if(__form != null && __type == "twitter") {
        return signupTwitter(__form);
    }
    if(__form != null && __type == "twitter") {
        return signupInstagram(__form);
    }
    if(__form != null && (__type == null || __type == "standard" || __type != "refresh")) {
        return signupStandard(__form);
    }

    store.dispatch(setSignupActivity(false));

    return false;
}

function signupGoogle(__form:string|null = null) {
    if(__form == null) {return false;}
    return true;
}

function signupFacebook(__form:string|null = null) {
    if(__form == null) {return false;}
    return true;
}

function signupTwitter(__form:string|null = null) {
    if(__form == null) {return false;}
    return true;
}

function signupInstagram(__form:string|null = null) {
    if(__form == null) {return false;}
    return true;
}

function signupStandard(__form:string|null = null) {
    if(__form == null) {return false;}
    const __formData = new FormData();
    const __elements = __signup_elements.join(' ').split(',');
    __elements.forEach(el => __formData.append(el, extractValue(el, resolveElementType(getById(el)))));
    let __langs = selectAll('[id^="signup_add_iso_langue_"]');
    let __lvls = selectAll('[id^="signup_add_niveau_langue_"]');
    __formData.append("langs", Object.fromEntries(Array(__langs.length).fill(0).map((_null:number, index:number) => {
        return [__langs[index], __lvls[index]];
    })));
    signinWithBack(__form, __formData, "standard");
    return true;
}

function signinWithBack(__form:string|null = null, __body:FormData|Object|string|null = null, __type:string|null = null) {
    try {
        console.log(__form);
        console.log(resolveXHRBody(__body));
        console.log(__type);
        return false;
        _fetch(
            `${process.env.BACK_URL}${__backURLs.api_signup.url}`,
            "POST",
            resolveXHRBody(__body),
            (_form:HTMLFormElement|null = null, _res:APISignupResponse|null = null) => {
                if(_res == null) {return false;}
                spinDisappeppear(__form);
                if([200, "success"].indexOf(_res.status) > -1 && typeof _res.message != "string") {
                    updateUser(_res.message, __type || "standard");
                    resolveModaleSuccess(...['activeModale', 'modaleGlobalEnv', 'modaleSpecificEnv'].map(attr => store.getState().page[attr]));
                }
            },
            (_form:HTMLFormElement|null = null, _err:Object|null = null) => {
                resolveModaleError(...['activeModale', 'modaleGlobalEnv', 'modaleSpecificEnv'].map(attr => store.getState().page[attr]));
                if(_err == null) {return false;}
                spinDisappeppear(__form);
                _error(_err);
            },
            selectOne(`#${__form}`),
            "json"
        );
    }
    catch(_err) {
        resolveModaleError(...['activeModale', 'modaleGlobalEnv', 'modaleSpecificEnv'].map(attr => store.getState().page[attr]));
        _trace(_err);
        _error(_err);
    }
}

function updateUser(__datas:Entity_User|null = null, __type:string|null = null) {
    if(__datas == null || __type == null) {return false;}
    if(typeof document != "undefined") {
        document.cookie = __datas.refresh ? `c7ur=${__datas.refresh}; max-age=${60 * 60 * 24 * 7}; path=/; secure=; SameSite=strict` : "";
    }
    store.dispatch(setUser({...store.getState().user, ...__datas, logged: true}));
}

// CHECKERS

export const signupCheckGeneral = function(__modale:string|null = null, __forceReport:boolean = false) {
    // _trace(`signupCheckGeneral(${__modale}, ${__forceReport})`);
    if(__modale == null) {return false;}
    const _asGroup = selectOne('.sign_as_checkboxGroup_status');
    const _nbAsSelected = selectAll(`#${__modales[__modale].formId} .sign_as_checkboxGroup input:checked`, true).length;
    // console.log(_asGroup);
    // console.log(_nbAsSelected);
    if(_asGroup instanceof HTMLSpanElement && _nbAsSelected == 0) {
        _asGroup.textContent = i18next.t("errors->forms->signup->asGroup->none_selected");
        _asGroup.classList.add("invalid");
    }
    if(_asGroup instanceof HTMLSpanElement && _nbAsSelected > 0) {
        _asGroup.classList.remove("invalid");
        _asGroup.textContent = "";
    }
    if(_nbAsSelected == 0) {
        __forceReport &&
        selectOne(`#${__modales[__modale].formId} #stepper-part_${__signup_etapes[0]} .invalid:not(.sign_as_checkboxGroup_status)`) != null &&
        selectOne(`#${__modales[__modale].formId}`).reportValidity();
        return false;
    }
    return true;
}

export const signupCheckAdministratif = function(__modale:string|null = null, __forceReport:boolean = false) {
    // _trace(`signupCheckAdministratif(${__modale}, ${__forceReport})`);
    if(__modale == null) {return false;}
    return true;
}

export const signupCheckPersonnal = function(__modale:string|null = null, __forceReport:boolean = false) {
    // _trace(`signupCheckPersonnal(${__modale}, ${__forceReport})`);
    if(__modale == null) {return false;}
    // TODO Vérification langues
    return true;
}

export const signupCheckSubmit = function(__e:Event|null = null, __currentStep:number|null = null, __modale:string|null = null, __setCurrentStep:Function|null = null) {
    // console.log("%cButton submit", "background:black;font-size:18px;padding: 3px 7px;font-weight:bold;color:red;");
    // console.log(__signup_etapes[__currentStep]);
    if(__e == null) {return false;}
    if(__currentStep == null) {__currentStep = 0;}
    if(__signup_etapes[__currentStep] == "generals") {
        __e.preventDefault();
        const _checkStepValidity = checkStepValidity(__currentStep, __modale, true);
        // console.log("_checkStepValidity : ", _checkStepValidity); // TEST if(_checkStepValidity == false) {return false;}
        const _signupCheckGeneral = signupCheckGeneral(__modale, true);
        // console.log("_signupCheckGeneral : ", _signupCheckGeneral); // TEST if(_signupCheckGeneral == false) {return false;}
        _checkStepValidity && _signupCheckGeneral && __setCurrentStep instanceof Function && __setCurrentStep(__currentStep + 1);
    }
    if(__signup_etapes[__currentStep] == "administrative") {
        __e.preventDefault();
        const _checkStepValidity = checkStepValidity(__currentStep, __modale, true);
        // console.log("_checkStepValidity : ", _checkStepValidity); // TEST if(_checkStepValidity == false) {return false;}
        const _signupCheckAdministratif = signupCheckAdministratif(__modale, true);
        // console.log("_signupCheckAdministratif : ", _signupCheckAdministratif); // TEST if(_signupCheckAdministratif == false) {return false;}
        _checkStepValidity && _signupCheckAdministratif && __setCurrentStep instanceof Function && __setCurrentStep(__currentStep + 1);
    }
    if(__signup_etapes[__currentStep] == "physical") {
        const _checkStepValidity = checkStepValidity(__currentStep, __modale, true);
        // console.log("_checkStepValidity : ", _checkStepValidity); // TEST if(_checkStepValidity == false) {return false;}
        const _signupCheckPersonnal = signupCheckPersonnal(__modale, true);
        // console.log("_signupCheckPersonnal : ", _signupCheckPersonnal); // TEST if(_signupCheckPersonnal == false) {return false;}
        if(_checkStepValidity && _signupCheckPersonnal) {
            __e.preventDefault();
            __signup__(__modales.signup.formId);
        }
    }
}

// TOOLS

function spinAppear(__form:string|null = null) {
    if(__form == null) {
        store.dispatch(setSignupActivity(true));
        return void(0);
    }
    selectOne(`#${__form} button[type="submit"] .animate-spin`)?.setAttribute('visibility', 'visible')
}

function spinDisappeppear(__form:string|null = null) {
    if(__form == null) {
        store.dispatch(setSignupActivity(false));
        return void(0);
    }
    selectOne(`#${__form} button[type="submit"] .animate-spin`)?.setAttribute('visibility', 'hidden')
}