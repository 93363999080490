import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';

const AnimationSpinner = (__datas:AnimationSpinner = Object()) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <svg
            className={`animate-spin -ml-1 mr-3 h-5 w-5 text-${__datas.color ? __datas.color : "white"}`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            visibility={__datas.hidden ? "hidden" : "visible"}
        >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    );
};

interface AnimationSpinner {
    key?: any;
    hidden?: boolean;
    color?: string;
};

export default hot(module)(AnimationSpinner);
