import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';

import { initSelectNone } from '../components/functions/inits';

import Button from "../components/Basics/Button/index";
import IconButton from "../components/Basics/Button/icon";
import TextInput from '../components/Basics/Input/text-input';
import CheckboxInput from '../components/Basics/Input/checkbox-input';
import Toggle from '../components/Basics/Toggle';
import BreadCrumb from '../components/BreadCrumb';
import DropdownIcons from '../components/Dropdown/dropdown-icons';
import { chaineAleatoire } from '../components/functions/dynamic-id';
import Select from '../components/Basics/Select/check';

const TestPage = (__datas:TestPage = {}) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <main data-page="test" className="custom-scrollbar">
            <section id="buttons">
                <h2>Boutons</h2>
                <IconButton/>
                <Button version="primary">Test primary</Button>
                <Button version="secondary">Test secondary</Button>
                <Button version="white">Test white</Button>
                <Button version="withIcon" size="B1" icon={{name: "plus"}}>Test with icon left</Button>
                <Button version="withIcon" size="B4" icon={{name: "plus", first: false}}>Test with icon right</Button>
                <Button version="rounded">Test rounded</Button>
            </section>
            <section id="inputs">
                <h2>Inputs</h2>
                <TextInput classes={["w-auto", "inline-block"]}/>
                <CheckboxInput classes={["mt-2"]} fieldName={chaineAleatoire(16)}/>
            </section>
            <section id="BreadCrumb">
                <h2>BreadCrumb</h2>
                <BreadCrumb/>
            </section>
            <section id="Toggle">
                <h2>Toggle</h2>
                <Toggle/>
            </section>
            <section id="Select">
                <h2>Select</h2>
                <Select options={[
                    {"label": "Réponse A", "value": "A"},
                    {"label": "Réponse B", "value": "B"},
                    {"label": "Réponse C", "value": "C"}
                ]}/>
            </section>
            <section id="Dropdown">
                <h2>Dropdown</h2>
                <DropdownIcons groups={[
                    {menus: [
                            {libelle: "G1-O1", icon: "plusIcon"},
                            {libelle: "G1-O2", icon: "plusIcon"},
                            {libelle: "G1-O3", icon: "plusIcon"}
                    ]},
                    {menus: [
                            {libelle: "G2-O1", icon: "plusIcon"},
                            {libelle: "G2-O2", icon: "plusIcon"},
                            {libelle: "G2-O3", icon: "plusIcon"},
                            {libelle: "G2-O4", icon: "plusIcon"},
                            {libelle: "G2-O5", icon: "plusIcon"},
                            {libelle: "G2-O6", icon: "plusIcon"}
                    ]},
                    {menus: [
                            {libelle: "G3-O1", icon: "plusIcon"}
                    ]}
                ]}/>
            </section>
        </main>
    );
};

interface TestPage {
    key?: any;
};

export default hot(module)(TestPage);