import { hot } from 'react-hot-loader';

import React from "react";

import './index.css';
import { initSelectNone } from '../functions/inits';
import { useTranslation } from 'react-i18next';

function Footer(__datas:Footer = {}) {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <footer key={__datas?.key}>
        </footer>
    );
};

interface Footer {
    key?: any;
};

export default hot(module)(Footer);
