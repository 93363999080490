import { urls } from "../const";

import { NavItemInterface } from "../interfaces/elements";

export const __navItems:NavItemInterface[] = [
    urls.headerLogoHome,
    urls.home,
    urls.annonces,
    urls.c7plus,
    urls.portfolio,
    urls.help,
    {...urls.signup, loggedAlt: "myspace"},
    {...urls.signin, loggedAlt: "logout"},
    urls.test
];

export const __languages = {
    "de": ["LU"],
    "en": ["EN"],
    "fr": ["BE", "CH", "FR", "LU"],
    "lu": ["LU"],
    "nl": ["BE", "NL"],
};
