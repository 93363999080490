import { hot } from 'react-hot-loader';

import React from "react";

import { initSelectNone } from '../functions/inits';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../interfaces/redux';
import { Entity_User } from '../interfaces/entities';
import Button from '../Basics/Button/index';

function MySpaceGeneral(__datas:MySpaceGeneral = {profile: null, is_my_profile: true}) {

    // TODO - Changer les 'can-manage' en bouton

    const user = useSelector((state:ReduxStore) => state.user);
    const loginActivity = useSelector((state:ReduxStore) => state.page.loginActivity);
    const { t, i18n } = useTranslation();

    // IMPORTANT - À supprimer en PROD
    const affiliates_tests = [
        {full_name: "Test 1"},
        {full_name: "Test 2"},
        {full_name: "Test 3"},
        {full_name: "Test 4"},
        {full_name: "Test 5"}
    ];

    React.useEffect(() => {
        initSelectNone();
    }, [loginActivity, user]);

    React.useEffect(() => {

    }, [i18n.language]);

    return (
        <div className="bg-gray-100 shadow-gray-300 shadow-inner w-full pl-4 py-1">
            <div className="text-black inline-block align-middle font-bold">{[t('pages->myspace->general->actually-on'), ':', __datas.is_my_profile ? t('pages->myspace->general->actualy-on-my-profile') : __datas.profile?.full_name].join(' ')}</div>
            {Array.isArray(user.affiliates ?? affiliates_tests) && (user.affiliates ?? affiliates_tests).length > 0 ?
                <div className="ml-4 inline-block align-middle h-10 py-1 font-semibold">
                    {__datas.is_my_profile == false && <Button onClick={() => __datas.change_profile && __datas.change_profile(user)} classes={["h-8", "align-middle", "mr-4"]} version="primary" size="B1">{t('pages->myspace->general->return_to_my_profile')}</Button>}
                    <span className="inline-block align-middle">{[t('pages->myspace->general->can-manage'), ':'].join(' ')}</span>
                    {(user.affiliates ?? affiliates_tests).map(affiliate => (
                        <Button
                            onClick={() => __datas.change_profile && __datas.change_profile(affiliate)}
                            version="secondary"
                            size="B1"
                            classes={["bg-gray-500", "text-black", "inline-block", "align-middle", "h-8", 'mx-2']}
                        >
                            {affiliate.full_name}
                        </Button>
                    ))}
                </div>
                : <></>
            }
        </div>
    );
};

interface MySpaceGeneral {
    key?: any;
    profile: Entity_User|null;
    is_my_profile: boolean|null;
    change_profile?: Function;
};

export default hot(module)(MySpaceGeneral);

// __datas.id
// __datas.key
// __datas.classes
// __datas.loggedAlt
// __datas.visitorAlt
// __datas.style
// __datas.slug
// __datas.action
// __datas.target
// __datas.modale
// __datas.url
// __datas.title
// __datas.url
// __datas.type
// __datas.img
// __datas.img.id
// __datas.img.src
// __datas.img.src.href
// __datas.img.title
// __datas.img.alt
// __datas.children
// __datas.libelle
