import { hot } from 'react-hot-loader';

import React from "react";
import { PlusSmIcon as PlusSmIconSolid } from '@heroicons/react/solid';
import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/react/outline';
import { MinusSmIcon as MinusSmIconSolid } from '@heroicons/react/solid';
import { MinusSmIcon as MinusSmIconOutline } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';

function resolveIcon(__icon:string|null = null, __type:string|null = null, __left = true) {
    if(__icon == null) {return null;}
    let _size = 0;
    if(__type != null) {_size = parseInt(__type?.replace('size-', ''), 10);}
    else {_size = 3;}
    switch(__icon) {
        case "minus": return _size > 3 ? <MinusSmIconSolid className={__iconClasses[_size]} aria-hidden="true"/> : <MinusSmIconOutline className={__iconClasses[_size]} aria-hidden="true"/>;
        case "plus":
        default:
            return _size > 3 ? <PlusSmIconSolid className={__iconClasses[_size]} aria-hidden="true"/> : <PlusSmIconOutline className={__iconClasses[_size]} aria-hidden="true"/>;
    }
}

const __iconClasses = [
    "h-5 w-5",
    "h-5 w-5",
    "h-6 w-6",
    "h-6 w-6",
    "h-5 w-5",
];

const IconButton = (__datas:IconButton = {}) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    if(__datas.size != "undefined" && __datas.size == "size-1") {
        return (
            <button
                type="button"
                className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-pink-900 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-700"
                onClick={(e:any) => __datas.onClick != undefined && __datas.onClick(e, this)}
            >
                {resolveIcon(__datas.icon, __datas.size)}
            </button>
        );
    }
    if(__datas.size != "undefined" && __datas.size == "size-2") {
        return (
            <button
                type="button"
                className="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-pink-900 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-700"
                onClick={(e:any) => __datas.onClick != undefined && __datas.onClick(e, this)}
            >
                {resolveIcon(__datas.icon, __datas.size)}
            </button>
        );
    }
    if(__datas.size != "undefined" && __datas.size == "size-4") {
        return (
            <button
                type="button"
                className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-pink-900 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-700"
                onClick={(e:any) => __datas.onClick != undefined && __datas.onClick(e, this)}
            >
                {resolveIcon(__datas.icon, __datas.size)}
            </button>
        );
    }
    if(__datas.size != "undefined" && __datas.size == "size-5") {
        return (
            <button
                type="button"
                className="inline-flex items-center p-3 border border-transparent rounded-full shadow-sm text-white bg-pink-900 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-700"
                onClick={(e:any) => __datas.onClick != undefined && __datas.onClick(e, this)}
            >
                {resolveIcon(__datas.icon, __datas.size)}
            </button>
        );
    }

    // EXPLAIN - Le size-3 est celui retourné par défaut en cas de valeur nulle ou erronnée
    return (
        <button
            type="button"
            className={`inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-pink-900 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-700 ${__datas.classes?.join(' ')}`}
            onClick={(e:any) => __datas.onClick != undefined && __datas.onClick(e, this)}
        >
            {resolveIcon(__datas.icon, __datas.size)}
        </button>
    );
};

interface IconButton {
    key?: any;
    icon?: string;
    size?: string;
    onClick?: Function;
    classes?: string[];
};

export default hot(module)(IconButton);