import { hot } from 'react-hot-loader';

import React from "react";

import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';
import { _log, _trace } from '../../functions/const';
import { ReduxStore } from '../../interfaces/redux';
import Divider from '../../Basics/Divider';
import { __modales } from '../index';

const ModaleAnnonceResolveEntityContent = (__datas:ModaleAnnonceResolveEntityContent = {}) => {

    const modale = useSelector((store:ReduxStore) => store.page.activeModale);
    const {user, annonce} = useSelector((store:ReduxStore) => store.page.modaleSpecificEnv);
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        
    }, [i18n.language]);

    React.useEffect(() => {
        
    }, [annonce, user]);

    React.useEffect(() => {
        initSelectNone();
    }, []);

    if(modale == null) {
        return <></>;
    }

    if(!annonce || ! user) {
        return <></>;
    }

    // IMPORTANT - Faire une ligne par candidature avec un loading gif qui se change en pouce vert ou rouge pour chacun au fur et à mesure des retours du back

    return (
        <>
            <form id={__modales[modale].formId} name="forgot_pwd-modale" className="space-y-8" key={__datas.key}
                onSubmit={(e) => {
                    e.preventDefault();
                    console.log(e);
                }}
            >
            </form>
            <Divider classes={["block", "my-4"]}/>
            <div className="block w-full text-center pt-4">
                {annonce.animals > 0 && !(annonce.vehicles > 0) && !(annonce.men > 0) && !(annonce.women > 0) && <span>{t("forms->annonce_resolve_entity->title-animal")}</span>}
                {annonce.animals > 0 && !(annonce.vehicles > 0) && (annonce.men > 0 || annonce.women > 0) && <span>{t("forms->annonce_resolve_entity->title-animal-human")}</span>}
                {!(annonce.animals > 0) && annonce.vehicles > 0 && !(annonce.men > 0) && !(annonce.women > 0) && <span>{t("forms->annonce_resolve_entity->title-vehicle")}</span>}
                {!(annonce.animals > 0) && annonce.vehicles > 0 && (annonce.men > 0 || annonce.women > 0) && <span>{t("forms->annonce_resolve_entity->title-vehicle-human")}</span>}
                {annonce.animals > 0 && annonce.vehicles > 0 && !(annonce.men > 0) && !(annonce.women > 0) && <span>{t("forms->annonce_resolve_entity->title-animal-vehicle")}</span>}
                {annonce.animals > 0 && annonce.vehicles > 0 && (annonce.men > 0 || annonce.women > 0) && <span>{t("forms->annonce_resolve_entity->title-all")}</span>}
            </div>
        </>
    );
};

interface ModaleAnnonceResolveEntityContent {
    key?: any;
};

export default hot(module)(ModaleAnnonceResolveEntityContent);