import i18next from "i18next";
import { DEV_MODE, PROD_MODE, STAGE_MODE, _error, _log } from "./functions/const";
import { getById, selectOne } from "./functions/selectors";
import { FormElement } from "./interfaces/elements";

const __dev_base = "";
const __test_base = "/c7front/dist/index.html";
const __prod_base = document?.location.origin;

function resolveBase() {
    if(DEV_MODE) {console.log("%cDEV_MODE", "background: red; color: yellow; font-size: 18px; padding: 5px 12px;");}
    if(PROD_MODE) {console.log("%cPROD_MODE", "background: red; color: yellow; font-size: 18px; padding: 5px 12px;");}
    if(STAGE_MODE) {console.log("%cSTAGE_MODE", "background: red; color: yellow; font-size: 18px; padding: 5px 12px;");}
    if(DEV_MODE) {
        if(document?.location.href.indexOf('dist') >= 0) {return __test_base};
        if(document?.location.href.indexOf('localhost') >= 0) {return __dev_base};
    }
    if(STAGE_MODE) {
        return document?.location.origin;
    }
    if(PROD_MODE) {
        return __prod_base;
    }
    return "";
}

export const resolvePathname = function(__pathname:string|null = null) {
    return (__pathname ?? document?.location.pathname).replace(resolveBase(), "");
}

export const resolveURL = function(__url:string|null = null) {
    return resolveBase() + (__url ?? "/");
}

const __classes = {
    "display": ["inline-flex", "inline-grid", "inline", "block", "flex", "grid"]
};

export const resolveClasses = function(__base = "", __sup = "") {
    if(Array.isArray(__base)) {__base = __base.join(' ');}
    if(Array.isArray(__sup)) {__sup = __sup.join(' ');}
    // RESOLVE DISPLAY
    __classes.display.forEach(_sup_class => __classes.display.forEach(_base_class => __base = __base.indexOf(_base_class) > -1 && __sup.indexOf(_sup_class) > -1 ? __base.replace(_base_class, '') : __base));
    return `${__base} ${__sup}`;
}

// FORM VALIDITY

// consts
export const resolveElementType = function(__el:Element|null = null) {
    if(__el == null) {return "undefined";}
    return __el.tagName == 'INPUT' ? `${__el.tagName.toLowerCase()}_${__el['type']}` : __el.tagName;
}

export const resolveError = function(__el:FormElement|null = null) {
    if(__el instanceof HTMLElement) {
        if(__el.validity.badInput == true) {return i18next.t(`errors->${resolveElementType(__el).toLowerCase()}->badInput`);}
        if(__el.validity.patternMismatch == true) {return i18next.t(`errors->${resolveElementType(__el).toLowerCase()}->patternMismatch`);}
        if(__el.validity.rangeOverflow == true) {return i18next.t(`errors->${resolveElementType(__el).toLowerCase()}->rangeOverflow`);}
        if(__el.validity.rangeUnderflow == true) {return i18next.t(`errors->${resolveElementType(__el).toLowerCase()}->rangeUnderflow`);}
        if(__el.validity.stepMismatch == true) {return i18next.t(`errors->${resolveElementType(__el).toLowerCase()}->stepMismatch`);}
        if(__el.validity.tooLong == true) {return i18next.t(`errors->${resolveElementType(__el).toLowerCase()}->tooLong`);}
        if(__el.validity.tooShort == true) {return i18next.t(`errors->${resolveElementType(__el).toLowerCase()}->tooShort`);}
        if(__el.validity.typeMismatch == true) {return i18next.t(`errors->${resolveElementType(__el).toLowerCase()}->typeMismatch`);}
        if(__el.validity.valueMissing == true) {return (resolveElementType(__el).indexOf("checkbox") < 0 ? "" : " - ") + i18next.t(`errors->${resolveElementType(__el).toLowerCase()}->valueMissing`);}
        if(__el.validity.valid == false) {return i18next.t(`errors->${resolveElementType(__el).toLowerCase()}->valid`);}
    }
    return null;
}

// features

export const extractValue = function(__fieldName:string|null = null, __type:string|null = null):any {
    if(__fieldName == null || __type == null) {return null;}
    let _temp:any = null;
    switch(__type) {
        case "input_text":
        case "input_number":
        case "input_email":
        case "input_password":
        case "input_range":
            _temp = getById(__fieldName);
            if(_temp instanceof HTMLInputElement) {return _temp.value;} return null;
        case "input_checkbox":
            _temp = getById(__fieldName);
            if(_temp instanceof HTMLInputElement) {return _temp.checked;} return null;
        case "select_check":
        case "select_with-icon":
            _temp = getById(__fieldName);
            if(_temp instanceof HTMLInputElement) {return _temp.value;} return null;
        default: return null;
    }
};

export const resolveFormElementValidity = function(__form:string|null = null, __el:string|null = null) {
    // _log("resolveFormElementValidity");
    if(typeof __form != "string" ||typeof __el != "string") {return false;}
    const _form:HTMLFormElement|null = document?.forms[__form];
    const _el:FormElement|null = selectOne(`#${__form} [name="${__el}"]`);
    // console.log(_el);
    if(_form instanceof HTMLFormElement && _el instanceof HTMLElement) {
        const _status:HTMLSpanElement|null = selectOne(`#${__form} .${__el}_status`);
        if(_el == null || (_el.name && typeof _el.checkValidity == "undefined")) {return false;}
        setFormFieldStatus(_el, _status, __form);
        return _el.checkValidity();
    }
    return false;
}

const _validity = "custom-validated";

export const setFormFieldStatus = function(__el:FormElement|null = null, __statusEl:HTMLSpanElement|null = null, __form:string|null = null) {
    if(__el instanceof HTMLElement && __statusEl instanceof HTMLElement) {
        __el.classList.remove(_validity);
        __el.setCustomValidity("");
        if(__el?.validity.valid == true) {
            __el.classList.contains("invalid") && __el.classList.add("valid");
            __el.classList.remove("invalid");
            // __statusEl.classList.add("hidden");
            // __statusEl.classList.remove("invalid");
        }
        else {
            !__el.classList.contains(_validity) && typeof __el.setCustomValidity(resolveError(__el) || __el.validationMessage) == "undefined" && __el.classList.add(_validity);
            __el.classList.remove("valid");
            __el.classList.add("invalid");
            __el.addEventListener('keyup', () => {
                setFormFieldStatus(__el, __statusEl, __form);
            }, {once: true});
            // __statusEl.classList.add("invalid");
            // __statusEl.classList.remove("hidden");
        }
        // __statusEl.textContent = __el.validity.valid ? "" : resolveError(__el);
        return true;
    }
    return false;
}

export const resolveXHRBody = function(__body:FormData|Object|string|null = null) {
    _log("resolveXHRBody");
    
    let retour:Object = {};
    
    if(__body == null) {retour = {};}
    
    if(__body instanceof FormData) {
        retour = {};
        for(var v of __body) {
            retour[v[0]] = v[1];
        }
    }
    else if(__body instanceof Object) {retour = __body;}

    return retour;
}
