import { Entity_Animal, Entity_Annonce, Entity_User, Entity_Vehicule } from "../interfaces/entities";
import i18next from "i18next";
import { __backURLs, _log } from "./const";
import store from "../../app/store";
import { setModale, setModaleSpecificEnv } from '../../features/page/pageSlice';
import { selectOne } from "./selectors";
import { APICandidatResponse } from "../interfaces/elements";
import { setUser } from "../../features/user/userSlice";

export const resolve_gender_icon = (_ann:Entity_Annonce, from?:string) => {
    if(!_ann) return [];
    if(typeof _ann !== "object") return [];

    let retour:Array<{src:string, title:string}> = [];

    if(_ann.men > 0 || _ann.women > 0) {
        retour.push({
            src: new URL('../../assets/icons/annonce/gender.svg', import.meta.url)?.href,
            title: [
                _ann.men ? i18next.t(`pages->${from}->infos->details->icon->gender-male`) : null,
                _ann.women ? i18next.t(`pages->${from}->infos->details->icon->gender-female`) : null
            ].filter(el => el).join(' | ')
        });
    }
    if(_ann.animals > 0) {retour.push({src: new URL('../../assets/icons/annonce/animal.svg', import.meta.url)?.href, title: i18next.t(`pages->${from}->infos->details->icon->animals`)});}
    if(_ann.vehicles > 0) {retour.push({src: new URL('../../assets/icons/annonce/vehicle.svg', import.meta.url)?.href, title: i18next.t(`pages->${from}->infos->details->icon->vehicles`)});}

    return retour;
}

export const resolve_annonce_detail = (_detail:string, _ann?:Entity_Annonce, from?:string) => {
    if(!_detail || !_ann) return "---";
    if(typeof _detail !== "string" || typeof _ann !== "object") return "---";
    switch(_detail) {
        case "type": return (_ann.types ?? []).map(type => type.sous_type).join(", ") ?? "---";
        case "project": return _ann.project.title ?? "---";
        case "gender": return [
            _ann.men ? i18next.t(`pages->${from}->infos->details->src->gender-male`) : null,,
            _ann.women ? i18next.t(`pages->${from}->infos->details->src->gender-female`) : null,
            _ann.animals ? i18next.t(`pages->${from}->infos->details->src->animals`) : null,
            _ann.vehicles ? i18next.t(`pages->${from}->infos->details->src->vehicles`) : null
        ].filter(el => el).join(' | ');
        case "money": return `${_ann.remuneration}€` ?? "---";
        case "age":
            switch(_ann.tranche_age) {
                case "min-max": return i18next.t(`pages->${from}->infos->details->src->age-min-max`, {min: _ann.age_min, max: _ann.age_max});
                case "indistinct": return i18next.t(`pages->${from}->infos->details->src->age-indistinct`);
                case "min": return i18next.t(`pages->${from}->infos->details->src->age-min`, {min: _ann.age_min});
                case "max": return i18next.t(`pages->${from}->infos->details->src->age-max`, {max: _ann.age_max});
                default: return "---";
            }
        case "land": return _ann.lieux && _ann.lieux.length ? _ann.lieux.map(lieu => lieu.pays).join(', ') : "---";
        case "location": return _ann.lieux && _ann.lieux.length ? _ann.lieux.map(lieu => lieu.localite).join(', ') : "---";
        case "calendar": return _ann.dates ? _ann.dates.map(date => date[0]).join(', ') : new Date(_ann.date_limite.date).toLocaleDateString() ?? "---";
        case "statut":
            if(_ann.is_opened) {return i18next.t(`pages->${from}->infos->details->src->statut-opened`) ?? "---";}
            if(_ann.is_reopened) {return i18next.t(`pages->${from}->infos->details->src->statut-reopened`) ?? "---";}
            if(_ann.is_last_minute) {return i18next.t(`pages->${from}->infos->details->src->statut-last_minute`) ?? "---";}
            return "---";
        default: return "---";
    }
    return "---";
}

export const missing_informations = (user:Entity_User, annonce:Entity_Annonce) => {
    if(!user || !annonce) {return [];}
    if(!user.logged) {return [];}
    let missings = [];

    return missings;
}

export const annonce_choose_entity = (user:Entity_User, annonce:Entity_Annonce) => {
    _log("annonce_choose_entity");
    _log(user);
    _log(annonce);
    if(!user || !annonce) {return false;}
    console.log("Il y a un user et une annonce");
    if(!user.logged) {return false;}
    console.log("L'user est loggé");
    if(!annonce.is_opened) {return false;}
    console.log("L'annonce est ouverte");
    if(annonce.is_closed) {return false;}
    console.log("L'annonce n'est pas fermée");

    store.dispatch(setModale("annonce_resolve_entity"));
    store.dispatch(setModaleSpecificEnv({annonce: annonce, user: user}));
}

export const resolve_annonce_apply = (user:Entity_User, annonce:Entity_Annonce) => {
    _log("resolve_annonce_apply");
    _log(user);
    _log(annonce);
    if(selectOne('main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat')?.classList.contains("saving")) {
        _log("Saving");
        return false;
    }
    if(!user || !annonce) {return false;}
    console.log("Il y a un user et une annonce");
    if(!user.logged) {return false;}
    console.log("L'user est loggé");
    if(!annonce.is_opened) {return false;}
    console.log("L'annonce est ouverte");
    if(annonce.is_closed) {return false;}
    console.log("L'annonce n'est pas fermée");

    if(annonce.animals) {annonce_choose_entity(user, annonce);}
    if(annonce.vehicles) {annonce_choose_entity(user, annonce);}
    if(annonce.men || annonce.women) {annonce_apply(user, user, annonce);}

    return true;
};

export const annonce_apply = (user:Entity_User, entity:Entity_Animal|Entity_User|Entity_Vehicule, annonce:Entity_Annonce) => {
    _log("annonce_apply");
    _log(user);
    _log(entity);
    _log(annonce);
    if(!user || !entity || !annonce) {return false;}
    console.log("Il y a un user, une entité et une annonce");
    if(!user.logged) {return false;}
    console.log("L'user est loggé");
    if(!annonce.is_opened) {return false;}
    console.log("L'annonce est ouverte");
    if(annonce.is_closed) {return false;}
    console.log("L'annonce n'est pas fermée");

    const init:RequestInit = {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            db_id: (entity.candidatures ?? []).find(candidature => candidature.annonce === annonce.db_id)?.db_id ?? null,
            annonce: annonce.db_id,
            type: entity.type,
            entity: entity.db_id,
            action: (entity.candidatures ?? []).find(candidature => candidature.annonce === annonce.db_id) ? "delete" : "create"
        })
    };

    selectOne('main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat')?.classList.add("saving");

    fetch(
        `${process.env.BACK_URL}${__backURLs.api_annonce_apply.url}`,
        init
    )
    .then(res => res.json())
    .then((res:APICandidatResponse) => {
        selectOne('main[data-page="annonce"] #annonce-bandeau .annonce-bandeau-candidat')?.classList.remove("saving");
        if(res.status == "success" && res.datas.action == "create") {
            store.dispatch(setUser({...user, candidatures: [...(user.candidatures ?? []), res.datas.saved]}));
        }

        if(res.status == "success" && res.datas.action == "delete") {
            store.dispatch(setUser({...user, candidatures: (user.candidatures ?? []).filter(candidature => candidature.db_id != res.datas.db_id)}));
        }

        if(res.status == "error") {
            return false;
        }
    })
    .catch(err => {
        console.error(err);
        return false;
    });

    return true;
}