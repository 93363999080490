import { hot } from 'react-hot-loader';

import React from "react";

import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../../functions/inits';
import { SelectOption } from '../../interfaces/elements';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const SelectCheck = (__datas:SelectCheck = Object()) => {

    const [selected, setSelected]:[number, React.Dispatch<number>] = React.useState(__datas.defaultSelected || 0)
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {
        setSelected(__datas.defaultSelected || selected || 0)
    }, [i18n.language, __datas]);

    return (
        <Listbox value={selected} onChange={(e) => {
            setSelected(e);
            __datas.onChange instanceof Function && __datas.onChange(__datas.options[e].value);
        }}>
            {({ open }) => (
                <div className={[...__datas.classes || []].join(' ')}>
                    {__datas.label != false && <Listbox.Label className="block text-sm font-medium text-gray-700">{t(typeof __datas.label == "string" ? __datas.label : "components->select->label->default")}</Listbox.Label>}
                    <div className={[...__datas.classes || [], "mt-1 relative"].join(' ')}>
                        <Listbox.Button
                            className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-pink-700 focus:border-pink-700 sm:text-sm">
                            <input readOnly={true} id={__datas.fieldName} className={["sr-only", __datas.formFor ? "form-elem" : ""].join(' ')} name={__datas.fieldName} value={__datas.options[selected].value} required={__datas.required} form={__datas.formFor}/>
                            <span className="block truncate capitalize">{t(__datas.options[selected]?.label || "components->select->option->label->default", {index: __datas.options[selected]?.label == undefined ? selected + 1 : ""})}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                            <span className={`${__datas.fieldName}_status`}></span>
                        </Listbox.Button>

                        <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Listbox.Options
                                className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm thin-scrollbar max-h-40">
                                {__datas.options.map((option:SelectOption, key:number) => (
                                    <Listbox.Option
                                        key={key}
                                        className={({ active }) =>
                                            classNames(active ? 'text-white bg-pink-900' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9'
                                        )}
                                        value={key}
                                    >
                                        {({ selected, active }) => (
                                        <>
                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal' , 'block truncate', 'capitalize')}>
                                                {t(option.label || "components->select->option->label->default", {index: key + 1})}
                                            </span>

                                            {selected ? (
                                                <span className={classNames( active ? 'text-white' : 'text-pink-900'
                                                    , 'absolute inset-y-0 right-0 flex items-center pr-4' )}>
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </div>
            )}
        </Listbox>
    );
};

interface SelectCheck {
    key?: any;
    label?: boolean|string;
    classes?: string|Array<string>;
    fieldName?: string;
    formFor?: string;
    required?: boolean;
    multiple?: boolean;
    withIcons?: boolean;
    defaultSelected?: number;
    options: SelectOption[];
    onChange?: Function;
};

export default hot(module)(SelectCheck);