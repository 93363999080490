import { hot } from 'react-hot-loader';

import React from "react";
import { NavItemInterface } from '../interfaces/elements';
import { ReduxStore } from '../interfaces/redux';
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../functions/inits';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { setPage, setModale, setModaleGlobalEnv, setModaleSpecificEnv } from '../../features/page/pageSlice';
import { urls } from '../const';
import { linkActions } from './functions';

const Link = (__datas:Link) => {

    const loginActivity = useSelector((state:ReduxStore) => state.page.loginActivity);
    const logged = useSelector((state:ReduxStore) => state.user.logged);
    const dispatch = ["_self", undefined].indexOf(__datas.target) >= 0 ? useDispatch() : null;
    const currentPath = ["_self", undefined].indexOf(__datas.target) >= 0 ? useSelector((state:ReduxStore) => state.page.pathname) : null;

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, [loginActivity, logged]);

    React.useEffect(() => {
        
    }, [i18n.language]);

    if(typeof __datas.loggedAlt == "string" && logged == true) {
        __datas = {key: __datas.key, ...urls[__datas.loggedAlt], classes: [...(__datas?.classes || []), ...(urls[__datas.loggedAlt]?.classes || [])]}
    }
    else if(typeof __datas.visitorAlt == "string" && logged == false) {
        __datas = {key: __datas.key, ...urls[__datas.visitorAlt], classes: [...(__datas?.classes || []), ...(urls[__datas.visitorAlt]?.classes || [])]}
    }

    if(!__datas.url) {
        return <></>;
    }
    
    return (
        <a
            id={__datas.id}
            key={__datas.key || undefined}
            className={[__datas.classes?.join(" ") || "", "relative w-fit", (__datas.loggedAlt || __datas.visitorAlt) && loginActivity ? "current-login animate-pulse text-slate-200" : ""].join(" ")}
            style={__datas.style || {}}
            target={__datas?.target || "_self"}
            data-slug={__datas.slug}
            href={__datas.url}
            title={t(__datas.title || __datas.libelle || "")}
            onClick={(e) => {
                if(__datas.action && __datas.action in linkActions) {
                    linkActions[__datas.action](e);
                    return false;
                }
                // EXPLAIN - Si le lien est autre qu'interne
                if(["_self", undefined, "_top", "_parent"].indexOf(__datas.target) < 0) {return false;}
                e.preventDefault();
                if(dispatch == null) {return false;}
                if(typeof __datas.modale == "string") {
                    e.stopPropagation();
                    dispatch(setModaleGlobalEnv(__datas.modaleGlobalDatas));
                    dispatch(setModaleSpecificEnv(__datas.modaleSpecificDatas));
                    dispatch(setModale(__datas.modale));
                    return true;
                }
                if(__datas.url == currentPath) {return false;}
                const __title = `CAS7ING - casting7.com | ${__datas.url == urls.home.url ? t('pages->home->meta->title') : t(__datas.title || __datas.libelle || "")}`;
                if(typeof document != "undefined") {
                    document.title = __title;
                }
                window.history.pushState('', __title, __datas.url);
                dispatch(setPage(__datas.dynamic ? __datas.page ? __datas.page : __datas.url ?? "" : __datas.url ? __datas.url : __datas.page ?? ""));
                return true;
            }}
        >
            {__datas.type == "image" && __datas.img ?
                <img
                    id={__datas.img.id}
                    src={__datas.img.src instanceof URL ? __datas.img.src.href : __datas.img?.src}
                    title={t(__datas.img.title)}
                    alt={t(__datas.img.alt)}
                />
                :
                __datas.empty == true ? "" : __datas?.children || t(__datas.libelle || "")
            }
        </a>
    );
};

interface Link extends NavItemInterface {
    key?: any;
    children?: any;
    style?: Object;
    empty?: boolean;
    modaleGlobalDatas?: any;
    modaleSpecificDatas?: any;
};

export default hot(module)(Link);