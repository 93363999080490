import { hot } from 'react-hot-loader';

import React from "react";

import { initSelectNone } from '../functions/inits';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../interfaces/redux';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function MySpaceTabs(__datas:MySpaceTabs = {}) {

    const loginActivity = useSelector((state:ReduxStore) => state.page.loginActivity);
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, [loginActivity]);

    React.useEffect(() => {

    }, [i18n.language]);

    const tabs= [
        "generals",
        "activities",
        "artist_cv",
        "animals",
        "vehicles",
        "dressing"
    ];

    return (
        <div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-pink-500 focus:ring-pink-500"
                    defaultValue={__datas.tab}
                >
                    {tabs.map((tab) => (
                        <option
                            key={__datas.tab}
                            onClick={(e) => __datas.changeTab && __datas.changeTab(tab)}
                        >{t(`pages->myspace->tabs->${__datas.tab}`)}</option>
                    ))}
                </select>
            </div>
            <div id="tabs" className="hidden sm:block">
                <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                    {tabs.map((tab, tabIdx) => (
                        <div
                            key={tab}
                            className={classNames(
                                tab == __datas.tab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                                'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer whitespace-nowrap'
                            )}
                            title={t(`pages->myspace->tabs->${tab}`)}
                            aria-current={tab == __datas.tab ? 'page' : undefined}
                            onClick={(e) => __datas.changeTab && __datas.changeTab(tab)}
                        >
                            <span
                                className="ellipsis inline-block align-middle"
                                // EXPLAIN - Le span doit prendre la largeur de la box moins son padding total en x et la largeur du badge quand il y en a un
                                // EXPLAIN - La largeur de 20px pour le badge a été calculée en testant avec le nombre '123'
                                // EXPLAIN - Le chiffre le plus large prend environ 7px de large
                                style={{
                                    maxWidth: `calc(100% - (${__datas.count && tab in __datas.count ?(function z(a, b = 0) {while(a > 1) {return z(a/10, ++b)}; return b;})(__datas.count[tab]) * 7 : 0}px + 2rem))`}}>
                                {t(`pages->myspace->tabs->${tab}`)}
                            </span>
                            {__datas.count && tab in __datas.count ? (
                                <span
                                    className={classNames(
                                    tab == __datas.tab ? 'bg-pink-100 text-pink-600' : 'bg-gray-100 text-gray-900',
                                    'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block align-middle'
                                    )}
                                >
                                    {__datas.count[tab]}
                                </span>
                            ) : <></>}
                            <span
                                aria-hidden="true"
                                className={classNames(
                                tab == __datas.tab ? 'bg-pink-500' : 'bg-transparent',
                                'absolute inset-x-0 bottom-0 h-0.5'
                                )}
                            />
                        </div>
                    ))}
                </nav>
            </div>
        </div>
    );
};

interface MySpaceTabs {
    key?: any;
    tab?: string;
    changeTab?: Function;
    count?: Object;
};

export default hot(module)(MySpaceTabs);
