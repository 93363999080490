import { hot } from 'react-hot-loader';

import React from "react";
import { useTranslation } from 'react-i18next';
import { initSelectNone } from '../functions/inits';

import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid';
import Link from '../Link';

const BreadCrumb = (__datas:BreadCrumb = {}) => {

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        initSelectNone();
    }, []);

    React.useEffect(() => {

    }, [i18n.language]);

    // TODO - Remplacer les valeurs statiques par des variables

    return (
        <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
            <li>
                <div>
                <a href="#" className="text-gray-400 hover:text-gray-500">
                    <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Home</span>
                </a>
                </div>
            </li>
            {[{name: "Home", href: "/", current: true}].map((page) => (
                <li key={page.name}>
                    <div className="flex items-center">
                        <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <Link
                            title={page.name}
                            classes={["ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"]}
                            url={page.href}
                        ></Link>
                    </div>
                </li>
            ))}
            </ol>
        </nav>
    );
};

interface BreadCrumb {
    key?: any;
};

export default hot(module)(BreadCrumb);
